package com.perpheads.bans.components.comments

import com.perpheads.bans.ApiClient
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.CommentResponse
import com.perpheads.bans.util.updateElement
import com.perpheads.bans.util.useTranslation
import kotlinx.css.maxWidth
import kotlinx.css.pct
import react.*
import react.dom.div
import react.dom.h3
import react.router.useNavigate
import styled.css
import styled.inlineStyles
import styled.styledDiv

external interface CommentListProps : Props {
    var account: AccountInfoResponse?
    var showEditor: Boolean
    var loadComments: suspend () -> List<CommentResponse>
    var createComment: suspend (String) -> CommentResponse
    var loading: Boolean
}

val CommentListComponent = fc<CommentListProps>("Comments") { props ->
    val t = useTranslation()
    val navigate = useNavigate()
    var editingComment by useState<CommentResponse>()
    var comments by useState(emptyList<CommentResponse>())
    val errorState = useState(false)
    val (loading, setLoading) = useState(props.loading)

    useEffectOnce {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            comments = props.loadComments()
        }
    }

    errorModal(errorState = errorState)
    div("ui segment") {
        loadingSpinner { isLoading = loading || props.loading }
        styledDiv {
            css {
                classes += "ui comments"
            }
            inlineStyles {
                maxWidth = 100.pct
            }
            h3("ui dividing header") {
                +t("comments.header")
            }
            for (c in comments) {
                comment {
                    key = c.commentId.toString()
                    this.account = props.account
                    this.comment = c
                    this.onEditPressed = {
                        editingComment = comment
                    }
                    this.onDeletePressed = {
                        editingComment = null
                        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                            ApiClient.deleteComment(c.commentId)
                            comments = comments.filter { it != comment }
                        }
                    }
                }
            }
        }
        if (props.showEditor) {
            commentEditor {
                this.editingComment = editingComment
                onCancel = {
                    editingComment = null
                }
                onSubmit = { commentText, onSuccess ->
                    editingComment.let { comment ->
                        if (comment != null) {
                            ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                ApiClient.editComment(comment.commentId, commentText)
                                comments = comments.updateElement(comment, comment.copy(commentText = commentText))
                                editingComment = null
                                onSuccess()
                            }
                        } else {
                            ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                val newComment = props.createComment(commentText)
                                comments = comments + newComment
                                onSuccess()
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.commentList(handler: CommentListProps.() -> Unit) = child(CommentListComponent) {
    attrs { handler() }
}