package com.perpheads.bans.components.blacklists

import com.perpheads.bans.responses.BlacklistResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.getDurationString
import com.perpheads.bans.util.useTranslation
import kotlinx.css.Cursor
import kotlinx.css.cursor
import react.Props
import react.RBuilder
import react.dom.*
import react.dom.events.MouseEventHandler
import react.fc
import react.useState
import styled.css
import styled.styledTr

external interface BlacklistHeaderProps : Props {
    var blacklist: BlacklistResponse
    var onClick: MouseEventHandler<*>
}

val BlacklistHeaderComponent = fc<BlacklistHeaderProps>("BlacklistHeader") { props ->
    val t = useTranslation()
    var hovered by useState(false)

    val blacklist = props.blacklist
    val admin = props.blacklist.admin?.name ?: "CONSOLE"
    val durationClass = if (blacklist.isExpired() || blacklist.isUnbanned()) {
        "positive"
    } else if (blacklist.isPermanent()) {
        "error"
    } else {
        "warning"
    }


    styledTr {
        attrs.onClick = props.onClick
        css {
            cursor = Cursor.pointer
            if (props.blacklist.deleted) {
                classes += "negative"
            }
            if (hovered) {
                classes += "active"
            }
        }

        attrs.onMouseEnter = {
            hovered = true
        }
        attrs.onMouseLeave = {
            hovered = false
        }

        td {
            +blacklist.startDate.format()
        }
        td {
            +blacklist.type
        }
        td {
            +blacklist.playerName
        }
        td {
            +admin
        }
        td(durationClass) {
            +blacklist.getDurationString(t)
        }
    }
}

fun RBuilder.blacklistHeader(handler: BlacklistHeaderProps.() -> Unit) = child(BlacklistHeaderComponent) { attrs(handler) }