package com.perpheads.bans.components

import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.TextAlign
import kotlinx.css.textAlign
import org.w3c.dom.HTMLTextAreaElement
import react.Props
import react.RBuilder
import react.dom.label
import react.dom.p
import react.fc
import react.useState
import styled.inlineStyles
import styled.styledP


external interface InputModalComponentProps : Props {
    var title: String
    var description: String
    var open: Boolean
    var inputRows: Int?
    var onInput: (String?) -> Unit
}

val InputModalComponent = fc<InputModalComponentProps>("InputModal") { props ->
    val t = useTranslation()
    var textAreaText by useState("")
    semanticModal {
        attrs {
            open = props.open
            centered = true
            onClose = { props.onInput(null) }
        }
        semanticHeader {
            p {
                +props.title
            }
        }
        semanticModalContent {
            semanticForm {
                label { +props.description }
                semanticTextArea {
                    this.value = textAreaText
                    this.rows = props.inputRows
                    this.onChange = { event ->
                        textAreaText = (event.target as HTMLTextAreaElement).value
                    }
                }
            }
        }
        semanticModalActions {
            semanticButton {
                attrs {
                    onClick = { props.onInput(null) }
                }
                +t("button.cancel")
            }
            semanticButton {
                attrs {
                    color = "green"
                    onClick = { props.onInput(textAreaText) }
                }
                +t("button.ok")
            }
        }
    }
}


fun RBuilder.inputModal(handler: InputModalComponentProps.() -> Unit) =
    child(InputModalComponent) {
        attrs(handler)
    }