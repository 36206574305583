package com.perpheads.bans.components.logs

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ScamPage
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.ServerLogSearchResponse
import react.*
import react.router.useLocation
import react.router.useNavigate
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

external interface LogPageProps : Props {

}

val LogPageComponent = fc<LogPageProps>("LogPage") {
    val location = useLocation()
    val navigate = useNavigate()
    val errorState = useState(false)

    val currentSearchSettings = LogSearchSettings.parseFromLocation(location)
    var loadedLogs by useState(ServerLogSearchResponse(emptyList()))
    val (loading, setLoading) = useState(false)

    useEffect(location) {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            loadedLogs = ApiClient.getLogs(currentSearchSettings.toSearchRequest())
        }
    }
    errorModal(errorState = errorState)

    val resultsPerPage = 100

    requiredUserPage(ScamPage.LOGS, canViewPage = { it.canViewLogs }) { account ->

        loadingSpinner { isLoading = loading }

        logSearchForm(account, currentSearchSettings) { newSettings ->
            newSettings.appendToLocation(navigate)
        }
        LogTableComponent {
            attrs {
                logs = loadedLogs
                filterByPlayer = { log ->
                    currentSearchSettings.copy(
                        steamId = log.steamId,
                        page = 1,
                    ).appendToLocation(navigate)
                }
                showBeforeSelected = { log ->
                    currentSearchSettings.copy(
                        startDate = null,
                        endDate = log.date + 1.seconds,
                        page = 1,
                        orderAscending = false,
                    ).appendToLocation(navigate)
                }
                showAroundSelected = { log ->
                    currentSearchSettings.copy(
                        startDate = log.date.minus(5.minutes),
                        endDate = log.date.plus(5.minutes),
                        page = 1,
                        orderAscending = false,
                    ).appendToLocation(navigate)
                }
                showAfterSelected = { log ->
                    currentSearchSettings.copy(
                        startDate = log.date - 1.seconds,
                        endDate = null,
                        page = 1,
                        orderAscending = true,
                    ).appendToLocation(navigate)
                }
                showInArea = { log ->
                    currentSearchSettings.copy(
                        startX = ((log.playerLocationX ?: 0.0) - 1000).toInt(),
                        startY = ((log.playerLocationY ?: 0.0) - 1000).toInt(),
                        endX = ((log.playerLocationX ?: 0.0) + 1000).toInt(),
                        endY = ((log.playerLocationY ?: 0.0) + 1000).toInt(),
                        page = 1,
                    ).appendToLocation(navigate)
                }
            }
        }

        pagination {
            totalPages = if (loadedLogs.logs.size < resultsPerPage) {
                currentSearchSettings.page
            } else {
                100
            }
            defaultActivePage = currentSearchSettings.page
            activePage = currentSearchSettings.page

            onPageChanged = { newPage ->
                currentSearchSettings.copy(page = newPage).appendToLocation(navigate)
            }
        }
    }
}

fun RBuilder.logPage() = child(LogPageComponent) {
    attrs {

    }
}