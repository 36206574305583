package com.perpheads.bans.components.screenshots

import com.perpheads.bans.CommonRegexes
import com.perpheads.bans.util.useTranslation

import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.em
import kotlinx.css.marginBottom
import org.w3c.dom.HTMLInputElement
import react.Props
import react.RBuilder
import react.dom.*
import react.fc
import react.useState
import styled.css
import styled.inlineStyles
import styled.styledButton

enum class ScreenshotFilter(val identifier: String) {
    STAFF("staff"),
    SELF("self"),
    CONSOLE("console"),
    NONE("all")
}

external interface ScreenshotSearchFormProps : Props {
    var screenshotCount: Int
    var selectedFilter: ScreenshotFilter
    var onFilterChanged: (ScreenshotFilter) -> Unit
    var onScreenshotRequest: (String) -> Unit
}

val ScreenshotSearchFormComponent = fc<ScreenshotSearchFormProps>("ScreenshotPageSearchForm") { props ->
    val t = useTranslation()
    var selectedSteamId by useState("")
    var steamIdError by useState(false)
    semanticGrid {
        attrs.columns = 2
        attrs.stackable = true
        semanticGridColumn {
            semanticSegment {
                h4("ui header") { +t("screenshots.request_screenshot") }
                semanticForm {
                    semanticFormField {
                        attrs.error = steamIdError
                        label { +t("headers.steamid") }
                        semanticInput {
                            value = selectedSteamId
                            onChange = {
                                selectedSteamId = (it.target as HTMLInputElement).value.trim()
                            }
                        }
                    }
                    semanticButton {
                        attrs.submit = ""
                        attrs.onClick = {
                            if (!selectedSteamId.matches(CommonRegexes.steamIdRegex)) {
                                steamIdError = true
                            } else {
                                steamIdError = false
                                selectedSteamId = ""
                                props.onScreenshotRequest(selectedSteamId)
                            }
                        }
                        +t("button.submit")
                    }
                }
            }
        }
        semanticGridColumn {
            semanticSegment {
                h4("ui header") { +t("screenshots.search_filters") }
                styledButton {
                    css {
                        classes += "ui fluid button"
                        if (props.selectedFilter == ScreenshotFilter.STAFF) { classes += "active"}
                    }
                    inlineStyles {
                        marginBottom = 0.5.em
                    }
                    attrs.onClick = {
                        props.onFilterChanged(ScreenshotFilter.STAFF)
                    }
                    +t("screenshots.requested_by_staff")
                }
                styledButton {
                    css {
                        classes += "ui fluid button"
                        if (props.selectedFilter == ScreenshotFilter.SELF) { classes += "active"}
                    }
                    inlineStyles {
                        marginBottom = 0.5.em
                    }
                    attrs.onClick = {
                        props.onFilterChanged(ScreenshotFilter.SELF)
                    }
                    +t("screenshots.requested_by_you")
                }
                styledButton {
                    css {
                        classes += "ui fluid button"
                        if (props.selectedFilter == ScreenshotFilter.CONSOLE) { classes += "active"}
                    }
                    inlineStyles {
                        marginBottom = 0.5.em
                    }
                    attrs.onClick = {
                        props.onFilterChanged(ScreenshotFilter.CONSOLE)
                    }
                    +t("screenshots.requested_by_console")
                }
                styledButton {
                    css {
                        classes += "ui fluid button"
                        if (props.selectedFilter == ScreenshotFilter.NONE) { classes += "active"}
                    }
                    inlineStyles {
                        marginBottom = 0.5.em
                    }
                    attrs.onClick = {
                        props.onFilterChanged(ScreenshotFilter.NONE)
                    }
                    +t("screenshots.no_request_filter")
                }
                div {
                    +t.withKeys("screenshots.total_count", mapOf("count" to props.screenshotCount))
                }
            }
        }
    }
}


fun RBuilder.screenshotSearchForm(handler: ScreenshotSearchFormProps.() -> Unit) = child(ScreenshotSearchFormComponent) {
    attrs(handler)
}