package com.perpheads.bans.responses

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable


@Serializable
data class StatisticsResponse(
    val totalConnections: List<Pair<LocalDateTime, List<Pair<String, Int>>>>,
    val uniqueConnections: List<Pair<LocalDateTime, List<Pair<String, Int>>>>,
    val newPlayers: List<Pair<LocalDateTime, List<Pair<String, Int>>>>,
    val playerCount: List<Pair<LocalDateTime, Int>>
)