package com.perpheads.bans.components.blacklists

import com.perpheads.bans.*
import com.perpheads.bans.components.durationDropdown
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.requests.BlacklistRequest
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.label
import react.router.useLocation
import react.router.useNavigate

external interface EditBlacklistPageComponentProps : Props {
    var blacklistId: Int?
}

val EditBlacklistPageComponent = fc<EditBlacklistPageComponentProps>("EditBlacklistPage") { props ->
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val t = useTranslation()
    val navigate = useNavigate()
    val (loading, setLoading) = useState(false)
    val errorState = useState(false)
    var steamIdError by useState(false)
    var steamIdText by useState(parameters["steamid"] ?: "")
    var nicknameText by useState("")
    var type by useState("")
    var typeError by useState(false)
    var reasonText by useState("")
    var reasonError by useState(false)
    var durationSeconds by useState<Long>()
    var durationSelected by useState(false)
    var durationError by useState(false)



    useEffect(steamIdText) {
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) return@useEffect
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            nicknameText = ApiClient.getNameBySteamId(steamIdText) ?: return@launchWithErrorHandler
        }
    }

    useEffectOnce {
        val blacklistId = props.blacklistId ?: return@useEffectOnce
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            val loadedBlacklist = ApiClient.getBlacklist(blacklistId)
            steamIdText = loadedBlacklist.steamId()
            nicknameText = loadedBlacklist.playerName
            type = loadedBlacklist.type
            reasonText = loadedBlacklist.reason
            durationSeconds = loadedBlacklist.duration?.inWholeSeconds
            durationSelected = true
        }
    }

    fun verifyInput(): Boolean {
        var success = true
        if (reasonText.length > 1500) {
            reasonError = true
            success = false
        } else {
            reasonError = false
        }
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) {
            steamIdError = true
            success = false
        } else {
            steamIdError = false
        }
        if (!durationSelected) {
            durationError = true
            success = false
        } else {
            durationError = false
        }
        if (type.isBlank()) {
            typeError = true
            success = false
        } else {
            typeError = false
        }

        return success
    }

    fun submit() {
        if (!verifyInput()) return
        val request = BlacklistRequest(
            steamIdText,
            type,
            reasonText,
            durationSeconds
        )
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            val blacklistId = props.blacklistId
            val newBlacklistId = if (blacklistId != null) {
                ApiClient.editBlacklist(blacklistId, request)
                blacklistId
            } else {
                ApiClient.addBlacklist(request).blacklistId
            }
            navigate(ClientRoutes.blacklistSearch(newBlacklistId))
        }
    }

    errorModal(errorState = errorState)
    requiredUserPage { user ->
        loadingSpinner { isLoading = loading }
        semanticForm {
            semanticFormField {
                attrs.width = 5
                attrs.error = steamIdError
                semanticInput {
                    label = t("headers.steamid")
                    placeholder = t("headers.steamid")
                    value = steamIdText
                    onChange = {
                        steamIdText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.disabled = true
                semanticInput {
                    label = t("headers.nickname")
                    value = nicknameText
                    placeholder = t("headers.nickname")
                    onChange = {
                        nicknameText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.error = typeError
                label { +t("headers.type") }
                semanticDropdown {
                    attrs.className = "selection"
                    attrs.text = type
                    semanticDropdownMenu {
                        for (bt in BlacklistTypes.values()) {
                            if (!user.canBlacklist && !bt.chatBlacklist) continue
                            semanticDropdownItem {
                                attrs.text = bt.type
                                attrs.selected = bt.type == type
                                attrs.onClick = {
                                    type = bt.type
                                }
                            }
                        }
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.error = reasonError
                label { +t("headers.reason") }
                semanticTextArea {
                    this.rows = 6
                    this.value = reasonText
                    this.onChange = { event ->
                        reasonText = (event.target as HTMLTextAreaElement).value
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.error = durationError
                durationDropdown(durationSeconds, durationSelected) {
                    durationSeconds = it
                    durationSelected = true
                }
            }
            semanticDivider()
            semanticButton {
                attrs {
                    this.onClick = {
                        submit()
                    }
                }
                +t("button.submit")
            }
        }
    }
}

fun RBuilder.editBlacklistPage(blacklistId: Int?) = child(EditBlacklistPageComponent) {
    attrs {
        this.blacklistId = blacklistId
    }
}