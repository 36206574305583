package com.perpheads.bans.components.screenshots

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ScamPage
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.ScreenshotsResponse
import react.*
import react.router.useNavigate

external interface ScreenshotPageProps : Props {

}

data class ScreenshotSearchSettings(
    val page: Int = 1,
    val filter: ScreenshotFilter = ScreenshotFilter.STAFF,
    val query: String? = null
)

val ScreenshotPageComponent = fc<ScreenshotPageProps>("ScreenshotPage") {
    val navigate = useNavigate()
    var searchSettings by useState(ScreenshotSearchSettings())
    val (loading, setLoading) = useState(false)
    val errorState = useState(false)
    var loadedScreenshots by useState(ScreenshotsResponse(0, emptyList()))

    useEffect(searchSettings) {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            loadedScreenshots = ApiClient.getScreenshots(
                searchSettings.page,
                searchSettings.query,
                searchSettings.filter.identifier
            )
        }
    }


    errorModal(errorState = errorState)
    requiredUserPage(ScamPage.SCREENSHOTS, {
        searchSettings = searchSettings.copy(page = 1, query = it)
    }, { it.canViewScamVerboseLogs }) {
        loadingSpinner { isLoading = loading }


        screenshotSearchForm {
            screenshotCount = loadedScreenshots.count
            selectedFilter = searchSettings.filter
            onFilterChanged = {
                searchSettings = searchSettings.copy(page = 1, filter = it)
            }
            onScreenshotRequest = {
                ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                    ApiClient.requestScreenshot(it)
                }
            }
        }
        screenshotTable(searchSettings.page, loadedScreenshots) {
            searchSettings = searchSettings.copy(page = it)
        }
    }
}


fun RBuilder.screenshotPage() = child(ScreenshotPageComponent) {
    attrs {

    }
}