package com.perpheads.bans.responses

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ScreenshotResponse(
    val screenshotId: Int,
    val date: Instant,
    val player: PlayerResponse,
    val admin: PlayerResponse?
)

@Serializable
data class ScreenshotsResponse(
    val count: Int,
    val screenshots: List<ScreenshotResponse>
)