package com.perpheads.bans.components.comments

import com.perpheads.bans.responses.CommentResponse
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.*

external interface CommentEditorProps : Props {
    var editingComment: CommentResponse?
    var onSubmit: (String, () -> Unit) -> Unit
    var onCancel: () -> Unit
}

val CommentEditor = fc<CommentEditorProps>("CommentEditor") { props ->
    val t = useTranslation()
    val editingComment = props.editingComment
    var editorText by useState(editingComment?.commentText ?: "")
    useEffect(editingComment) {
        editorText = editingComment?.commentText ?: ""
    }
    semanticForm {
        attrs.reply = true
        div("field") {
            semanticTextArea {
                value = editorText
                rows = 4
                onChange = { event ->
                    editorText = (event.target as HTMLTextAreaElement).value
                }
            }
        }
        semanticButton {
            attrs {
                this.labelPosition = "left"
                this.icon = true
                this.color = "blue"
                this.onClick = {
                    props.onSubmit(editorText) {
                        editorText = ""
                    }
                }
            }
            semanticIcon("edit")
            val buttonText = if (editingComment != null) {
                t("comments.edit_comment")
            } else t("comments.add_comment")
            +buttonText
        }
        if (editingComment != null) {
            semanticButton {
                attrs {
                    this.onClick = { props.onCancel() }
                }
                +t("button.cancel")
            }
        }
    }
}


fun RBuilder.commentEditor(handler: CommentEditorProps.() -> Unit) = child(CommentEditor) {
    attrs { handler() }
}
