package com.perpheads.bans.components.warnings

import com.perpheads.bans.responses.WarningResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.useTranslation
import kotlinx.css.Cursor
import kotlinx.css.cursor
import react.Props
import react.RBuilder
import react.dom.*
import react.dom.events.MouseEventHandler
import react.fc
import react.useState
import styled.css
import styled.styledTr


external interface WarningHeaderProps : Props {
    var warning: WarningResponse
    var onClick: MouseEventHandler<*>
}

val WarningHeaderComponent = fc<WarningHeaderProps>("WarningHeader") { props ->
    val t = useTranslation()
    var hovered by useState(false)

    val warning = props.warning
    val admin = props.warning.admin?.name ?: "CONSOLE"

    styledTr {
        attrs.onClick = props.onClick
        css {
            cursor = Cursor.pointer
            if (hovered) {
                classes += "active"
            }
        }

        attrs.onMouseEnter = {
            hovered = true
        }
        attrs.onMouseLeave = {
            hovered = false
        }

        td {
            +warning.date.format()
        }
        td {
            +warning.playerName
        }
        td {
            +admin
        }
    }
}

fun RBuilder.warningHeader(handler: WarningHeaderProps.() -> Unit) = child(WarningHeaderComponent) { attrs(handler) }