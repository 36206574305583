package com.perpheads.bans.components.logs

import com.perpheads.bans.MapCoordinates
import com.perpheads.bans.Vector2
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.semanticDropdown
import com.perpheads.bans.wrappers.semantic.semanticDropdownItem
import com.perpheads.bans.wrappers.semantic.semanticDropdownMenu
import react.Props
import react.dom.events.PointerEvent
import react.dom.events.PointerType
import react.fc
import react.useEffect
import react.useState
import web.canvas.CanvasRenderingContext2D
import web.uievents.MouseButton
import kotlin.math.absoluteValue

data class SelectedArea(
    val startX: Double,
    val endX: Double,
    val startY: Double,
    val endY: Double,
) {
    fun area(): Double = (endX - startX).absoluteValue * (endY - startY).absoluteValue

    fun sort(): SelectedArea {
        return SelectedArea(
            startX.coerceAtMost(endX),
            startX.coerceAtLeast(endX),
            startY.coerceAtMost(endY),
            startY.coerceAtLeast(endY)
        )
    }
}

external interface MapAreaCanvasComponentProps : Props {
    var size: Double
    var area: SelectedArea?
    var onAreaChanged: ((SelectedArea?) -> Unit)?
    var width: Double
    var height: Double
}

data class DrawFunContainer(val f: (CanvasRenderingContext2D, ViewportData) -> Unit)

val MapAreaCanvasComponent = fc<MapAreaCanvasComponentProps>("MapAreaCanvas") { props ->
    var isDrawingArea by useState(false)
    var selectedArea by useState(props.area)
    var drawFunction by useState<DrawFunContainer>()
    var initialLocation by useState(Vector2(0.0, 0.0))
    var initialZoom by useState(1.0)
    fun PointerEvent<*>.isAreaDragEvent(): Boolean {
        return pointerType == PointerType.mouse && button == MouseButton.MAIN
    }

    useEffect(props.area?.startX, props.area?.startY, props.area?.endX, props.area?.endY) {
        selectedArea = props.area
        props.area?.let {
            initialLocation = Vector2((it.startX + it.endX) / 2, (it.startY + it.endY) / 2)
            val (ratioX, ratioY) = MapCoordinates.convertToDeltaRatios(
                Vector2(
                    it.endX - it.startX,
                    it.endY - it.startY
                )
            )
            val newZoom = (1 / (ratioX.absoluteValue * 1.3)).coerceAtMost(1 / (ratioY.absoluteValue * 1.3))
            initialZoom = if (newZoom.isNaN()) {
                1.0
            } else {
                newZoom
            }

        }
    }

    useEffect(selectedArea?.startX, selectedArea?.endX, selectedArea?.startY, selectedArea?.endY) {
        drawFunction = DrawFunContainer { renderContext, viewportData ->
            selectedArea?.let { area ->
                val (percentStartX, percentStartY) = MapCoordinates.convertToRatios(Vector2(area.startX, area.startY))
                val (percentEndX, percentEndY) = MapCoordinates.convertToRatios(Vector2(area.endX, area.endY))

                val rectStart = viewportData.convertToViewportCoordinates(percentStartX, percentStartY)
                val rectEnd = viewportData.convertToViewportCoordinates(percentEndX, percentEndY)
                renderContext.strokeStyle = "#64dd17"
                renderContext.lineWidth = 2.0
                renderContext.beginPath()
                renderContext.rect(rectStart.x, rectStart.y, (rectEnd.x - rectStart.x), (rectEnd.y - rectStart.y))
                renderContext.stroke()
            }
        }
    }

    MapCanvasComponent {
        attrs.initialLocation = initialLocation
        attrs.width = props.width
        attrs.height = props.height
        attrs.initialZoomFactor = initialZoom
        attrs.drawFun = drawFunction?.f
        attrs.allowZooming = true
        attrs.allowMoving = true
        attrs.onPointerDown = { event, data ->
            if (event.isAreaDragEvent()) {
                selectedArea = SelectedArea(data.mapX, data.mapX, data.mapY, data.mapY)
                isDrawingArea = true
            }
        }
        attrs.onPointerMove = { _, data ->
            if (isDrawingArea) {
                selectedArea = selectedArea?.copy(endX = data.mapX, endY = data.mapY)
            }
        }
        attrs.onPointerUp = { event, data ->
            if (event.isAreaDragEvent()) {
                val newArea = selectedArea?.copy(endX = data.mapX, endY = data.mapY)?.sort()?.takeIf { it.area() > 100.0 }
                props.onAreaChanged?.invoke(newArea)
                selectedArea = newArea
                isDrawingArea = false
            }
        }
    }
}

external interface MapAreaDropdownProps : Props {
    var area: SelectedArea?
    var onAreaChanged: ((SelectedArea?) -> Unit)?
}

val MapAreaDropdown = fc<MapAreaDropdownProps>("MapAreaDropdown") { props ->
    val t = useTranslation()
    semanticDropdown {
        attrs {
            text = t("logs.map_area")
            button = true
            floating = true
            labeled = true
            icon = if (props.area == null) {
                "map"
            } else {
                "map green"
            }
            className = "icon"
        }
        semanticDropdownMenu {
            semanticDropdownItem {
                MapAreaCanvasComponent {
                    attrs {
                        area = props.area
                        onAreaChanged = props.onAreaChanged
                        width = 512.0
                        height = 512.0
                    }
                }
            }
        }
    }
}