package com.perpheads.bans.components.page

import com.perpheads.bans.wrappers.semantic.*
import kotlinx.browser.window
import org.w3c.dom.*
import org.w3c.dom.ScrollBehavior
import react.Props
import react.RBuilder
import react.fc

external interface PaginationComponentProps : Props {
    var defaultActivePage: Int
    var activePage: Int
    var totalPages: Int
    var onPageChanged: (Int) -> Unit
    var doNotScroll: Boolean?
}


val PaginationComponent = fc<PaginationComponentProps>("Pagination") { props ->
    semanticGrid {
        semanticGridColumn {
            attrs.textAlign = "center"
            semanticPagination {
                defaultActivePage = props.defaultActivePage
                totalPages = props.totalPages
                activePage = props.activePage
                onPageChange = { _, data ->
                    if (props.doNotScroll != true) {
                        window.scrollTo(ScrollToOptions(0.0, 0.0, ScrollBehavior.INSTANT))
                    }
                    props.onPageChanged(data.activePage)
                }
            }
        }
    }
}

fun RBuilder.pagination(handler: PaginationComponentProps.() -> Unit) = child(PaginationComponent) {
    attrs { handler() }
}