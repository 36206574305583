package com.perpheads.bans.components.page

import com.perpheads.bans.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.wrappers.semantic.semanticContainer
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.serialization.decodeFromString
import org.w3c.dom.get
import react.*
import react.dom.a
import react.dom.div
import react.router.Navigate
import react.router.useNavigate
import styled.css
import styled.styledDiv
import styled.styledImg


external interface PageTemplateProps : PropsWithChildren {
    var page: ScamPage?
    var onSearched: ((String) -> Unit)?
    var canViewPage: ((AccountInfoResponse) -> Boolean)?
    var optionalUserChild: ((RBuilder, AccountInfoResponse?) -> Unit)?
    var requiredUserChild: ((RBuilder, AccountInfoResponse) -> Unit)?
}

val PageTemplateComponent = fc<PageTemplateProps>("PageTemplate") { props ->
    val navigate = useNavigate()
    val accountInfoJson = window.localStorage["account"]
    val theme = getCurrentTheme()
    var account by useState(
        accountInfoJson?.let { runCatching { ApiClient.jsonSerializer.decodeFromString<AccountInfoResponse>(it) }.getOrNull() }
    )
    val errorState = useState(false)

    useEffectOnce {
        if (account == null) return@useEffectOnce
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            val newAccount = ApiClient.getAccount()
            if (account == newAccount) {
                return@launchWithErrorHandler
            }
            storeAccount(newAccount)
            account = newAccount
        }
    }

    fun verifyAccount(currentAccount: AccountInfoResponse?): Boolean {
        if (props.requiredUserChild != null && currentAccount == null) {
            removeStoredAccount()
            return false
        }
        val verifyFunc = props.canViewPage
        if (verifyFunc != null && currentAccount != null && !verifyFunc(currentAccount)) {
            return false
        }
        return true
    }

    errorModal(errorState = errorState)
    semanticContainer {
        a("https://perpheads.com") {
            val logoLink = if (theme.dark) {
                getLogoDark()
            } else {
                getLogo()
            }
            styledImg(src = logoLink) {
                css {
                    display = Display.block
                    marginTop = 12.px
                }
            }
        }
        navBar {
            this.onSearched = props.onSearched ?: {
                navigate(ClientRoutes.banSearch(it))
            }
            this.account = account
            this.page = props.page
        }
        styledDiv {
            css {
                classes += "ui segment styled"
                minHeight = 100.px
            }

            val optionalUserChild = props.optionalUserChild
            val requiredUserChild = props.requiredUserChild
            val currentAccount = account
            if (!verifyAccount(currentAccount)) {
                Navigate {
                    attrs.to = ClientRoutes.bans
                }
            }
            div {
                if (currentAccount != null && requiredUserChild != null) {
                    requiredUserChild(this, currentAccount)
                } else if (optionalUserChild != null) {
                    optionalUserChild(this, currentAccount)
                }
            }
        }
    }
}

fun RBuilder.requiredUserPage(
    page: ScamPage? = null,
    onSearched: ((String) -> Unit)? = null,
    canViewPage: (AccountInfoResponse) -> Boolean = { true },
    handler: RBuilder.(AccountInfoResponse) -> Unit
) = PageTemplateComponent {
    attrs {
        this.onSearched = onSearched
        this.page = page
        this.requiredUserChild = handler
        this.canViewPage = canViewPage
    }
}

fun RBuilder.optionalUserPage(
    page: ScamPage? = null,
    onSearched: ((String) -> Unit)? = null,
    handler: RBuilder.(AccountInfoResponse?) -> Unit
) = PageTemplateComponent {
    attrs {
        this.onSearched = onSearched
        this.optionalUserChild = handler
        this.page = page
    }
}