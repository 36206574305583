package com.perpheads.bans.components

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.ScamPage
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.logout
import com.perpheads.bans.util.pushMouseEventHandler
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.Cursor
import kotlinx.css.cursor
import kotlinx.css.marginLeft
import kotlinx.css.px
import react.Props
import react.RBuilder
import react.dom.events.MouseEventHandler
import react.dom.onClick
import react.fc
import react.router.useNavigate
import react.useState
import styled.inlineStyles
import styled.styledA

external interface AccountCardProps : Props {
    var name: String
    var icon: String
    var color: String
    var href: String?
    var onClick: MouseEventHandler<*>?
    var secondIcon: String?
    var secondIconColor: String?
}

val AccountCardComponent = fc<AccountCardProps>("AccountCard") { props ->
    semanticSegment {
        semanticIconGroup {
            attrs.size = "huge"
            semanticIcon(props.icon, color = props.color)
            val secondIcon = props.secondIcon
            val secondIconColor = props.secondIconColor
            if (secondIcon != null && secondIconColor != null) {
                semanticIcon(name = secondIcon, corner = "bottom right", color = secondIconColor)
            }
        }
        styledA {
            inlineStyles {
                cursor = Cursor.pointer
                marginLeft = 6.px
            }
            +props.name
            props.onClick?.let {
                attrs.onClick = it
            }
            props.href?.let {
                attrs.href = it
            }
        }
    }
}

fun RBuilder.accountCard(
    name: String,
    icon: String,
    color: String,
    secondIcon: String? = null,
    secondIconColor: String? = null,
    href: String? = null,
    onClick: MouseEventHandler<*>? = null
) =
    child(AccountCardComponent) {
        attrs {
            attrs.icon = icon
            attrs.name = name
            attrs.onClick = onClick
            attrs.color = color
            attrs.secondIcon = secondIcon
            attrs.secondIconColor = secondIconColor
            attrs.href = href
        }
    }

external interface AccountPageProps : Props {

}

val AccountPageComponent = fc<AccountPageProps>("AccountPage") {
    val t = useTranslation()
    val navigate = useNavigate()
    val errorState = useState(false)
    requiredUserPage(ScamPage.ACCOUNT) { account ->
        errorModal(errorState = errorState)
        val steamId = account.player.steamId()
        semanticGrid {
            attrs.columns = 3
            attrs.stackable = true
            semanticGridColumn {
                accountCard(
                    t("account.button.your_bans"), "ban", "red", href = ClientRoutes.banSearch(steamId),
                    onClick = navigate.pushMouseEventHandler(ClientRoutes.banSearch(steamId))
                )
            }
            semanticGridColumn {
                accountCard(
                    t("account.button.your_warning"),
                    "warning sign",
                    "orange",
                    href = ClientRoutes.warningSearch(steamId),
                    onClick = navigate.pushMouseEventHandler(ClientRoutes.warningSearch(steamId))
                )
            }
            semanticGridColumn {
                accountCard(
                    t("account.button.your_blacklists"),
                    "book",
                    "red",
                    href = ClientRoutes.blacklistSearch(steamId),
                    onClick = navigate.pushMouseEventHandler(ClientRoutes.blacklistSearch(steamId))
                )
            }
            if (account.canBan) {
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.add_ban"),
                        icon = "ban",
                        color = "red",
                        secondIcon = "add",
                        secondIconColor = "green",
                        href = ClientRoutes.addBan,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.addBan)
                    )
                }
            }
            if (account.canWarn) {
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.add_warning"),
                        icon = "warning sign",
                        color = "orange",
                        secondIcon = "add",
                        secondIconColor = "green",
                        href = ClientRoutes.addWarning,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.addWarning)
                    )
                }
            }
            if (account.canBlacklist) {
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.add_blacklist"),
                        icon = "book",
                        color = "red",
                        secondIcon = "add",
                        secondIconColor = "green",
                        href = ClientRoutes.addBlacklist,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.addBlacklist)
                    )
                }
            }
            if (account.canAssignRanks) {
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.ban_logs"),
                        icon = "ban",
                        color = "red",
                        secondIcon = "text file",
                        secondIconColor = "yellow",
                        href = ClientRoutes.banLogs,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.banLogs)
                    )
                }
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.warning_logs"),
                        icon = "warning sign",
                        color = "orange",
                        secondIcon = "text file",
                        secondIconColor = "yellow",
                        href = ClientRoutes.warningLogs,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.warningLogs)
                    )
                }
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.blacklist_logs"),
                        icon = "book",
                        color = "red",
                        secondIcon = "text file",
                        secondIconColor = "yellow",
                        href = ClientRoutes.blacklistLogs,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.blacklistLogs)
                    )
                }
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.player_logs"),
                        icon = "users",
                        color = "blue",
                        secondIcon = "text file",
                        secondIconColor = "yellow",
                        href = ClientRoutes.playerLogs,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.playerLogs)
                    )
                }
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.comment_logs"),
                        icon = "chat",
                        color = "green",
                        secondIcon = "text file",
                        secondIconColor = "yellow",
                        href = ClientRoutes.commentLogs,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.commentLogs)
                    )
                }
            }
            if (account.canAssignRanks) {
                semanticGridColumn {
                    accountCard(
                        name = t("account.button.ranks"),
                        icon = "users",
                        color = "blue",
                        href = ClientRoutes.ranks,
                        onClick = navigate.pushMouseEventHandler(ClientRoutes.ranks)
                    )
                }
            }
            semanticGridColumn {
                accountCard(
                    name = t("account.button.your_profile"),
                    icon = "user",
                    color = "blue",
                    href = ClientRoutes.playerProfile(account.player.communityId),
                    onClick = navigate.pushMouseEventHandler(ClientRoutes.playerProfile(account.player.communityId))
                )
            }
            semanticGridColumn {
                accountCard(t("account.button.logout"), "sign out", "black") {
                    ApiClient.launchWithErrorHandler(navigate, errorState) {
                        ApiClient.logout()
                        logout(navigate)
                    }
                }
            }
        }
    }
}

fun RBuilder.accountPage() = child(AccountPageComponent) {
    attrs {

    }
}