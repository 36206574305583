package com.perpheads.bans.components.profile

import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.profileIdToSteamId
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.ProfileResponse
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.semanticSegment
import react.Props
import react.RBuilder
import react.dom.*
import react.fc

external interface ProfileDetailsProps : Props {
    var loading: Boolean
    var profileResponse: ProfileResponse?
    var account: AccountInfoResponse?
    var banCount: Int
    var warningCount: Int
    var blacklistCount: Int
}


val ProfileDetailsComponent = fc<ProfileDetailsProps>("ProfileDetails") { props ->
    val t = useTranslation()
    semanticSegment {
        val loadingStr = t("loading")
        val profileResponse = props.profileResponse
        val steamId = props.profileResponse?.communityId?.profileIdToSteamId()
        val steamCommunityHref = profileResponse?.communityId?.let { "https://steamcommunity.com/profiles/$it" }
        val shouldShowLinkedAccounts = props.account?.canViewLogs == true
        h2 { +(props.profileResponse?.name ?: t("loading")) }
        loadingSpinner { isLoading = props.loading }
        table("ui celled compact table") {
            tbody {
                tr {
                    th { +t("headers.rank") }
                    td { +(profileResponse?.rank ?: loadingStr) }
                }
                tr {
                    th { +t("headers.community_id") }
                    td {
                        a(href = steamCommunityHref, target = "_blank") {
                            +(profileResponse?.communityId ?: loadingStr)
                        }
                    }
                }
                tr {
                    th { +t("headers.steamid") }
                    td {
                        a(href = steamCommunityHref, target = "_blank") {
                            +(profileResponse?.communityId?.profileIdToSteamId() ?: loadingStr)
                        }
                    }
                }
                if (shouldShowLinkedAccounts) {
                    tr {
                        th { +t("headers.forum") }
                        td {
                            val forumLink = profileResponse?.forumLink
                            val forumName = profileResponse?.forumName
                            if (forumLink != null && forumName != null) {
                                a(forumLink) {
                                    +forumName
                                }
                            } else {
                                +t("none")
                            }
                        }
                    }
                    tr {
                        th { +t("headers.discord_id") }
                        td {
                            val discordId = profileResponse?.discordId
                            if (discordId != null) {
                                a("discord://discordapp.com/users/$discordId") {
                                    +discordId
                                }
                            } else {
                                +t("none")
                            }
                        }
                    }
                }
                tr {
                    th { +t("navigation.bans") }
                    td {
                        a(steamId?.let { ClientRoutes.banSearch(it) }) {
                            +props.banCount.toString()
                        }
                    }
                }
                tr {
                    th { +t("navigation.warnings") }
                    td {
                        a(steamId?.let { ClientRoutes.warningSearch(it) }) {
                            +props.warningCount.toString()
                        }
                    }
                }
                tr {
                    th { +t("navigation.blacklists") }
                    td {
                        a(steamId?.let { ClientRoutes.blacklistSearch(it) }) {
                            +props.blacklistCount.toString()
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.profileDetails(handler: ProfileDetailsProps.() -> Unit) = child(ProfileDetailsComponent) {
    attrs(handler)
}