package com.perpheads.bans.components.demos

import com.perpheads.bans.ServerRoutes
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.responses.DemoRequestStatus
import com.perpheads.bans.responses.DemosResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.playerLink
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.Icon
import com.perpheads.bans.wrappers.semantic.IconProps
import com.perpheads.bans.wrappers.semantic.Popup
import js.objects.jso
import react.Props
import react.RBuilder
import react.createElement
import react.dom.*
import react.fc
import react.router.useNavigate
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt


external interface DemoTableProps : Props {
    var page: Int
    var demos: DemosResponse
    var onPageChanged: (Int) -> Unit
}

val DemoTableComponent = fc<DemoTableProps>("DemoTableComponent") { props ->
    val t = useTranslation()
    val navigate = useNavigate()
    val totalPages =
        max(ceil(props.demos.count / ServerRoutes.entriesPerPage.toDouble()).roundToInt(), 1)

    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.last_updated") }
                th { +t("headers.player") }
                th { +t("headers.admin") }
                th { +t("headers.demo") }
            }
        }
        tbody {
            for (demo in props.demos.demos) {
                tr {
                    attrs.key = demo.demoId.toString()
                    td("collapsing") { +demo.lastUpdated.format() }
                    td("collapsing") {
                        playerLink(
                            "${demo.player.name} (${demo.player.steamId()})",
                            demo.player.communityId,
                            navigate
                        )
                    }
                    td("collapsing") { +(demo.admin?.name ?: "CONSOLE") }
                    td("collapsing") {
                        if (demo.status == DemoRequestStatus.UPLOADED) {
                            val demoLink = ServerRoutes.demoRoute(demo.demoId)
                            Icon {
                                attrs.name = "check"
                                attrs.color = "green"
                            }
                            a(href = demoLink, target = "_blank") {
                                +(" " + demo.name)
                            }
                        } else {
                            Popup {
                                var iconName = ""
                                var popupDescription = ""
                                var iconColor: String? = null
                                when(demo.status) {
                                    DemoRequestStatus.OPEN -> {
                                        iconName = "hourglass"
                                        popupDescription = "Awaiting Response"
                                    }
                                    DemoRequestStatus.REJECTED -> {
                                        iconName = "close"
                                        iconColor = "red"
                                        popupDescription = "User Rejected"
                                    }
                                    DemoRequestStatus.NOT_FOUND -> {
                                        iconName = "file"
                                        iconColor = "yellow"
                                        popupDescription = "Demo Not Found"
                                    }
                                    DemoRequestStatus.UPLOADING -> {
                                        iconName = "cloud upload"
                                        iconColor = "blue"
                                        popupDescription = "Demo Uploading"
                                    }
                                    DemoRequestStatus.FAILED -> {
                                        iconName = "times circle"
                                        iconColor = "red"
                                        popupDescription = "Upload Failed"
                                    }
                                    else -> {}
                                }
                                attrs.content = popupDescription
                                attrs.trigger = createElement(Icon, jso {
                                    name = iconName
                                    iconColor?.let {
                                        color = it
                                    }
                                })
                            }
                            +(" " + demo.name)
                        }


                    }
                }
            }
        }
        tfoot {
            tr {
                th {
                    attrs.colSpan = "4"
                    pagination {
                        this.defaultActivePage = props.page
                        this.totalPages = totalPages
                        this.onPageChanged = props.onPageChanged
                    }
                }
            }
        }
    }
}

fun RBuilder.demoTable(page: Int, response: DemosResponse, onPageChanged: (Int) -> Unit) =
    child(DemoTableComponent) {
        attrs {
            this.demos = response
            this.page = page
            this.onPageChanged = onPageChanged
        }
    }