package com.perpheads.bans.components.logs

import com.perpheads.bans.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.ActionLogsSearchResponse
import com.perpheads.bans.responses.CommentLogResponse
import com.perpheads.bans.responses.CommentType
import com.perpheads.bans.util.playerLink
import com.perpheads.bans.util.pushMouseEventHandler
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*
import react.router.useNavigate
import react.router.useLocation
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt

external interface CommentLogPageProps : Props {

}

val CommentLogPageComponent = fc<CommentLogPageProps>("CommentLogPage") { props ->
    val t = useTranslation()
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val navigate = useNavigate()
    val page = parameters["page"]?.toIntOrNull() ?: 1

    var logs by useState(ActionLogsSearchResponse<CommentLogResponse>(0, emptyList()))
    val totalPages = max(ceil(logs.count / ServerRoutes.entriesPerPage.toDouble()).roundToInt(), 1)
    val (loading, setLoading) = useState(true)
    val errorState = useState(false)

    fun changeUrl(
        newPage: Int,
    ) {
        val params = Parameters.build {
            if (newPage != 1) {
                set("page", newPage.toString())
            }
        }
        navigate(ClientRoutes.commentLogs + "?${params}")
    }

    useEffect(location) {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            logs = ApiClient.getCommentLogs(page)
        }
    }

    errorModal(errorState = errorState)
    requiredUserPage(canViewPage = { it.canAssignRanks }) { account ->
        loadingSpinner { isLoading = loading }

        p { +t.withKeys("logs.total_count", mapOf("count" to logs.count)) }
        actionLogTable<CommentLogResponse> {
            attrs {
                this.account = account
                this.headerString = t("headers.comment")
                this.logs = logs.logs
                this.descriptionBuilder = { builder, props ->
                    val typeString = when (props.log.commentType) {
                        CommentType.BAN -> "Ban"
                        CommentType.WARNING -> "Warning"
                        CommentType.BLACKLIST -> "Blacklist"
                    }
                    val entityHref = when (props.log.commentType) {
                        CommentType.BAN -> ClientRoutes.banSearch(props.log.entityId)
                        CommentType.WARNING -> ClientRoutes.warningSearch(props.log.entityId)
                        CommentType.BLACKLIST -> ClientRoutes.blacklistSearch(props.log.entityId)
                    }
                    builder.td {
                        +"Comment on $typeString "
                        a(href = entityHref) {
                            attrs.onClick = navigate.pushMouseEventHandler(entityHref)
                            +"#${props.log.entityId}"
                        }
                        +" by "
                        playerLink(props.log.admin.name, props.log.admin.communityId, navigate)
                    }
                }
            }

            tfoot {
                tr {
                    th {
                        attrs.colSpan = "4"
                        pagination {
                            this.defaultActivePage = page
                            this.totalPages = totalPages
                            this.onPageChanged = {
                                changeUrl(newPage = it)
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.commentLogPage() = child(CommentLogPageComponent) {
    attrs {

    }
}