package com.perpheads.bans.components.ranks

import com.perpheads.bans.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.RankResponse
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.label
import react.router.useNavigate
import react.router.useLocation

external interface EditRankPageProps : Props {

}

val EditRankPageComponent = fc<EditRankPageProps>("EditRankPage") {
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val t = useTranslation()
    val navigate = useNavigate()
    var steamIdError by useState(false)
    var steamIdText by useState(parameters["steamid"] ?: "")
    var nicknameText by useState("")
    var ranks by useState<List<RankResponse>>(emptyList())
    var selectedRank by useState<RankResponse>()
    var selectedRankError by useState(false)
    val (loading, setLoading) = useState(true)
    val errorState = useState(false)

    useEffect(steamIdText) {
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) return@useEffect
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            nicknameText = ApiClient.getNameBySteamId(steamIdText) ?: return@launchWithErrorHandler
        }
    }
    useEffectOnce {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            ranks = ApiClient.getAllRanks()
        }
    }

    fun verifyInput(): Boolean {
        var success = true
        if (selectedRank == null) {
            selectedRankError = true
            success = false
        } else {
            selectedRankError = false
        }
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) {
            steamIdError = true
            success = false
        } else {
            steamIdError = false
        }
        return success
    }

    fun submit() {
        if (!verifyInput()) return
        val rankToSet = selectedRank ?: return
        val communityId = steamIdText.steamIdToProfileId() ?: return
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            ApiClient.changePlayerRank(communityId, rankToSet.rankId)
            navigate(ClientRoutes.playerProfile(communityId.toString()))
        }
    }

    errorModal(errorState = errorState)
    requiredUserPage(canViewPage = { it.canAssignRanks }) { account ->
        loadingSpinner { isLoading = loading }
        semanticForm {
            semanticFormField {
                attrs.width = 5
                attrs.error = steamIdError
                semanticInput {
                    label = t("headers.steamid")
                    placeholder = t("headers.steamid")
                    value = steamIdText
                    onChange = {
                        steamIdText = (it.target as HTMLInputElement).value
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.disabled = true
                semanticInput {
                    label = t("headers.nickname")
                    value = nicknameText
                    placeholder = t("headers.nickname")
                    onChange = {
                        nicknameText = (it.target as HTMLInputElement).value
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.error = selectedRankError
                label { +t("headers.rank") }
                semanticDropdown {
                    attrs.selection = true
                    attrs.text = selectedRank?.name
                    semanticDropdownMenu {
                        for (rank in ranks) {
                            if (rank.immunity > account.immunity) continue
                            semanticDropdownItem {
                                attrs.text = rank.name
                                attrs.selected = rank == selectedRank
                                attrs.onClick = {
                                    selectedRank = rank
                                }
                            }
                        }
                    }
                }
            }
            semanticDivider()
            semanticButton {
                attrs {
                    this.onClick = {
                        submit()
                    }
                }
                +t("button.submit")
            }
        }
    }

}

fun RBuilder.editRankPage(handler: EditRankPageProps.() -> Unit) = child(EditRankPageComponent) { attrs(handler) }
