package com.perpheads.bans.components.logs

import com.perpheads.bans.linkify
import com.perpheads.bans.responses.ServerLogResponse
import com.perpheads.bans.responses.ServerLogSearchResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.playerLink
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.*
import react.Props
import react.dom.*
import react.fc
import react.router.useNavigate
import styled.css
import styled.styledDiv
import styled.styledSpan
import styled.styledTd


external interface LogTableProps : Props {
    var logs: ServerLogSearchResponse
    var showBeforeSelected: (ServerLogResponse) -> Unit
    var showAroundSelected: (ServerLogResponse) -> Unit
    var showAfterSelected: (ServerLogResponse) -> Unit
    var showInArea: (ServerLogResponse) -> Unit
    var filterByPlayer: (ServerLogResponse) -> Unit
}

external interface LogContextMenuProps : Props {
    var filterByPlayer: () -> Unit
    var showBeforeSelected: () -> Unit
    var showAroundSelected: () -> Unit
    var showAfterSelected: () -> Unit
    var showInArea: () -> Unit
    var hasLocation: Boolean
    var hasPlayer: Boolean
}

val LogContextMenu = fc<LogContextMenuProps> { props ->
    val t = useTranslation()
    semanticDropdown {
        attrs {
            className = "icon"
            icon = "content half"
            button = false
            floating = true
        }
        semanticDropdownMenu {
            if (props.hasPlayer) {
                semanticDropdownItem {
                    attrs {
                        text = t("logs.filter_by_player")
                        onClick = { props.filterByPlayer() }
                    }
                }
            }
            semanticDropdownItem {
                attrs {
                    text = t("logs.show_before_date")
                    onClick = { props.showBeforeSelected() }
                }
            }
            semanticDropdownItem {
                attrs {
                    text = t("logs.show_around_date")
                    onClick = { props.showAroundSelected() }
                }
            }
            semanticDropdownItem {
                attrs {
                    text = t("logs.show_after_date")
                    onClick = { props.showAfterSelected() }
                }
            }
            if (props.hasLocation) {
                semanticDropdownItem {
                    attrs {
                        text = t("logs.show_in_area")
                        onClick = { props.showInArea() }
                    }
                }
            }
        }
    }
}

val LogTableComponent = fc<LogTableProps>("LogTable") { props ->
    val t = useTranslation()
    val navigate = useNavigate()

    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.nickname") }
                th { +t("headers.rpname") }
                th { +t("headers.steamid") }
                th { +t("headers.message") }
                th { +t("headers.date") }
            }
        }
        tbody {
            for (log in props.logs.logs) {
                tr {
                    td {
                        val nickname = log.nickname
                        val communityId = log.communityId
                        val locationX = log.playerLocationX
                        val locationY = log.playerLocationY
                        if (locationX != null && locationY != null) {
                            MapHoverComponent {
                                attrs.locationX = locationX
                                attrs.locationY = locationY
                            }
                            +" "
                        }
                        if (nickname != null && communityId != null) {
                            playerLink(nickname, communityId, navigate)
                        }
                    }
                    td {
                        +(log.rpName ?: "")
                    }
                    td {
                        val communityId = log.communityId
                        if (communityId != null) {
                            playerLink(log.steamId ?: "STEAM_ID_ERROR", communityId, navigate)
                        }
                    }
                    styledTd {
                        css {
                            whiteSpace = WhiteSpace.preWrap
                        }
                        linkify(log.message)
                    }
                    td {
                        styledDiv {
                            css {
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                flexWrap = FlexWrap.nowrap
                            }
                            styledDiv {
                                css {
                                    flexGrow = 1.0
                                }
                                +log.date.format("ll LTS")
                            }
                            styledDiv {
                                css {
                                    flexGrow = 1.0
                                }
                                LogContextMenu {
                                    attrs {
                                        hasLocation = log.playerLocationX != null && log.playerLocationY != null
                                        hasPlayer = log.steamId != null
                                        showBeforeSelected = { props.showBeforeSelected(log) }
                                        showAroundSelected = { props.showAroundSelected(log) }
                                        showAfterSelected = { props.showAfterSelected(log) }
                                        showInArea = { props.showInArea(log) }
                                        filterByPlayer = { props.filterByPlayer(log) }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}