package com.perpheads.bans.components.demos

import com.perpheads.bans.CommonRegexes
import com.perpheads.bans.SCAMPermission
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.util.useTranslation

import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.em
import kotlinx.css.marginBottom
import org.w3c.dom.HTMLInputElement
import react.Props
import react.RBuilder
import react.dom.div
import react.dom.h4
import react.dom.label
import react.dom.onClick
import react.fc
import react.useState
import styled.css
import styled.inlineStyles
import styled.styledButton

enum class DemoFilter(val identifier: String) {
    SELF("self"),
    NONE("all")
}

external interface DemoSearchFormProps : Props {
    var demoCount: Int
    var selectedFilter: DemoFilter
    var onFilterChanged: (DemoFilter) -> Unit
    var onDemoRequested: (String, String, Boolean) -> Unit
    var account: AccountInfoResponse?
}

val DemoSearchFormComponent = fc<DemoSearchFormProps>("DemoPageSearchForm") { props ->
    val t = useTranslation()
    var selectedSteamId by useState("")
    var demoName by useState("")
    var steamIdError by useState(false)
    var demoNameError by useState(false)
    var covert by useState(false)

    semanticGrid {
        attrs.columns = 2
        attrs.stackable = true
        semanticGridColumn {
            semanticSegment {
                h4("ui header") { +t("demos.request_demo") }
                semanticForm {
                    semanticFormField {
                        attrs.error = steamIdError
                        label { +t("headers.steamid") }
                        semanticInput {
                            value = selectedSteamId
                            onChange = {
                                selectedSteamId = (it.target as HTMLInputElement).value.trim()
                            }
                        }
                    }
                    semanticFormField {
                        attrs.error = demoNameError
                        label { +t("headers.demo_name") }
                        semanticInput {
                            value = demoName
                            onChange = {
                                demoName = (it.target as HTMLInputElement).value.trim()
                            }
                        }
                    }

                    if (props.account?.hasPermission(SCAMPermission.RestrictedDebug) == true) {
                        semanticFormField {
                            label { +t("screenshots.request_covertly") }
                            semanticCheckbox {
                                checked = covert
                                onChange = { _, data ->
                                    val checked = data.checked.unsafeCast<Boolean>()
                                    covert = checked
                                }
                            }
                        }
                    }

                    semanticButton {
                        attrs.submit = ""
                        attrs.onClick = {
                            steamIdError = false
                            demoNameError = false
                            if (!selectedSteamId.matches(CommonRegexes.steamIdRegex)) {
                                steamIdError = true
                            } else if (!demoName.matches(CommonRegexes.demoRegex)) {
                                demoNameError = true
                            } else {
                                props.onDemoRequested(selectedSteamId, demoName, covert)
                                selectedSteamId = ""
                                demoName = ""
                            }
                        }
                        +t("button.submit")
                    }
                }
            }
        }
        semanticGridColumn {
            semanticSegment {
                h4("ui header") { +t("screenshots.search_filters") }
                styledButton {
                    css {
                        classes += "ui fluid button"
                        if (props.selectedFilter == DemoFilter.SELF) { classes += "active"}
                    }
                    inlineStyles {
                        marginBottom = 0.5.em
                    }
                    attrs.onClick = {
                        props.onFilterChanged(DemoFilter.SELF)
                    }
                    +t("demos.requested_by_you")
                }
                styledButton {
                    css {
                        classes += "ui fluid button"
                        if (props.selectedFilter == DemoFilter.NONE) { classes += "active"}
                    }
                    inlineStyles {
                        marginBottom = 0.5.em
                    }
                    attrs.onClick = {
                        props.onFilterChanged(DemoFilter.NONE)
                    }
                    +t("demos.no_request_filter")
                }
                div {
                    +t.withKeys("demos.total_count", mapOf("count" to props.demoCount))
                }
            }
        }
    }
}


fun RBuilder.demoSearchForm(handler: DemoSearchFormProps.() -> Unit) = child(DemoSearchFormComponent) {
    attrs(handler)
}