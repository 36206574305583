package com.perpheads.bans.responses

import kotlinx.serialization.Serializable

@Serializable
data class RankOverviewResponse(
    val rankId: Int,
    val name: String,
    val immunity: Int,
    val color: Int,
    val playerCount: Int
) {
    fun colorText(): String {
        val r = color.shr(16).and(255)
        val g = color.shr(8).and(255)
        val b = color.and(255)
        return "RGB($r,$g,$b)"
    }

    fun colorHexString(): String {
        val r = color.shr(16).and(255).toString(16).padStart(2, '0')
        val g = color.shr(8).and(255).toString(16).padStart(2, '0')
        val b = color.and(255).toString(16).padStart(2, '0')
        return "#$r$g$b"
    }
}