package com.perpheads.bans.components.warnings

import com.perpheads.bans.*
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.requests.WarningRequest
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.label
import react.router.useNavigate
import react.router.useLocation

external interface EditWarningPageComponentProps : Props {
    var warningId: Int?
}

val EditWarningPageComponent = fc<EditWarningPageComponentProps>("EditWarningPage") { props ->
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val t = useTranslation()
    val navigate = useNavigate()
    val (loading, setLoading) = useState(props.warningId != null)
    var steamIdError by useState(false)
    var steamIdText by useState(parameters["steamid"] ?: "")
    var nicknameText by useState("")
    var reasonText by useState("")
    var reasonError by useState(false)
    val errorState = useState(false)

    useEffect(steamIdText) {
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) return@useEffect
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            nicknameText = ApiClient.getNameBySteamId(steamIdText) ?: return@launchWithErrorHandler
        }
    }

    useEffectOnce {
        val warningId = props.warningId ?: return@useEffectOnce
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            val loadedWarning = ApiClient.getWarning(warningId)
            steamIdText = loadedWarning.steamId()
            nicknameText = loadedWarning.playerName
            reasonText = loadedWarning.reason
        }
    }

    fun verifyInput(): Boolean {
        var success = true
        if (reasonText.length > 1500) {
            reasonError = true
            success = false
        } else {
            reasonError = false
        }
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) {
            steamIdError = true
            success = false
        } else {
            steamIdError = false
        }
        return success
    }

    fun submit() {
        if (!verifyInput()) return
        val request = WarningRequest(
            steamIdText,
            reasonText
        )
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            val warningId = props.warningId
            val newWarningId = if (warningId != null) {
                ApiClient.editWarning(warningId, request)
                warningId
            } else {
                ApiClient.addWarning(request).warningId
            }
            navigate(ClientRoutes.warningSearch(newWarningId))
        }
    }

    requiredUserPage(canViewPage = { it.canWarn }) {
        loadingSpinner { isLoading = loading }
        semanticForm {
            semanticFormField {
                attrs.width = 5
                attrs.error = steamIdError
                semanticInput {
                    label = t("headers.steamid")
                    placeholder = t("headers.steamid")
                    value = steamIdText
                    onChange = {
                        steamIdText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.disabled = true
                semanticInput {
                    label = t("headers.nickname")
                    value = nicknameText
                    placeholder = t("headers.nickname")
                    onChange = {
                        nicknameText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.error = reasonError
                label { +t("headers.reason") }
                semanticTextArea {
                    this.rows = 6
                    this.value = reasonText
                    this.onChange = { event ->
                        reasonText = (event.target as HTMLTextAreaElement).value
                    }
                }
            }
            semanticDivider()
            semanticButton {
                attrs {
                    this.onClick = {
                        submit()
                    }
                }
                +t("button.submit")
            }
        }
    }
}

fun RBuilder.editWarningPage(warningId: Int?) = child(EditWarningPageComponent) {
    attrs {
        this.warningId = warningId
    }
}