package com.perpheads.bans.components.blacklists

import com.perpheads.bans.*
import com.perpheads.bans.components.*
import com.perpheads.bans.components.comments.commentList
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.BlacklistResponse
import com.perpheads.bans.util.collapsingTableRow
import com.perpheads.bans.util.format
import com.perpheads.bans.util.linkedCollapsingTableRow
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.table
import react.dom.tbody
import react.dom.td
import react.dom.tr
import react.router.useNavigate

external interface BlacklistDetailProps : Props {
    var blacklist: BlacklistResponse
    var expanded: Boolean
    var account: AccountInfoResponse?
    var updateBlacklist: (BlacklistResponse, BlacklistResponse) -> Unit
}


val BlacklistDetailComponent = fc<BlacklistDetailProps>("BlacklistDetails") { props ->
    val t = useTranslation()
    val navigate = useNavigate()

    val blacklist = props.blacklist
    var wasShown by useState(false)
    val errorState = useState(false)
    val (loading, setLoading) = useState(false)

    if (!props.expanded && !wasShown) return@fc
    if (props.expanded && !wasShown) {
        wasShown = true //This is _NOT_ useless
    }

    errorModal(errorState = errorState)
    collapsibleTableRow(expanded = props.expanded, colSpan = "5") {
        table("ui celled compact table") {
            tbody {
                collapsingTableRow(t("headers.type")) {
                    +blacklist.type
                }
                linkedCollapsingTableRow(
                    navigate,
                    t("headers.player"),
                    ClientRoutes.playerProfile(blacklist.communityId)
                ) {
                    +blacklist.playerName
                }
                collapsingTableRow(t("headers.reason")) {
                    linkify(blacklist.reason)
                }
                linkedCollapsingTableRow(
                    navigate,
                    t("headers.steamid"),
                    ClientRoutes.blacklistSearch(blacklist.steamId())
                ) {
                    +blacklist.steamId()
                }
                val admin = blacklist.admin
                if (admin != null) {
                    linkedCollapsingTableRow(
                        navigate,
                        t("headers.admin"),
                        ClientRoutes.blacklistAdmin(admin.steamId())
                    ) {
                        +admin.name
                    }
                }
                val endDate = blacklist.endDate
                if (endDate != null) {
                    collapsingTableRow(t("headers.expiry_date")) { +endDate.format() }
                }
                val unblacklistReason = blacklist.unblacklistReason
                if (unblacklistReason != null) {
                    collapsingTableRow(t("headers.unblacklist_reason")) { +unblacklistReason }
                }
                val unblacklistDate = blacklist.unblacklistDate
                if (unblacklistDate != null) {
                    collapsingTableRow(t("headers.unblacklist_date")) { +unblacklistDate.format() }
                }
                val unblacklistAdmin = blacklist.unblacklistAdmin
                if (unblacklistAdmin != null) {
                    linkedCollapsingTableRow(
                        navigate,
                        t("headers.unblacklist_admin"),
                        ClientRoutes.blacklistAdmin(unblacklistAdmin.steamId())
                    ) { +unblacklistAdmin.name }
                }
                val account = props.account ?: return@tbody
                if (!account.canEditBlacklist(blacklist)) return@tbody
                if (blacklist.deleted && !account.canUndelete) return@tbody
                tr {
                    td("collapsing") { +t("headers.actions") }
                    td {
                        if (blacklist.deleted) {
                            actionButtonWithConfirmation {
                                this.text = t("actions.undelete")
                                this.icon = "undo"
                                this.color = "green"
                                this.modalMessage = t("actions.undelete.blacklist.message")
                                this.modalDescription = t("actions.undelete.blacklist.description")
                                this.modalIcon = "warning sign"
                                this.onConfirmed = {
                                    ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                        ApiClient.undeleteBlacklist(props.blacklist.blacklistId)
                                        props.updateBlacklist(props.blacklist, props.blacklist.copy(deleted = false))
                                    }
                                }
                            }
                            return@td
                        }
                        actionButton(navigate, "Edit", "edit", "blue", ClientRoutes.editBlacklist(props.blacklist.blacklistId))
                        if (blacklist.unblacklistDate == null && !blacklist.isExpired()) {
                            actionButtonWithInput {
                                this.text = t("actions.unblacklist")
                                this.icon = "minus square"
                                this.color = "green"
                                this.modalMessage = t("actions.unblacklist.message")
                                this.modalDescription = t("actions.unblacklist.description")
                                this.inputRows = 6
                                this.onInput = { reason ->
                                    ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                        val newBlacklist = ApiClient.unblacklist(props.blacklist.blacklistId, reason)
                                        props.updateBlacklist(props.blacklist, newBlacklist)
                                    }
                                }
                            }
                        } else if (!blacklist.isExpired()) {
                            actionButtonWithConfirmation {
                                this.text = t("actions.reblacklist")
                                this.icon = "plus square"
                                this.color = "orange"
                                this.modalMessage = t("actions.reblacklist.message")
                                this.modalDescription = t("actions.reblacklist.description")
                                this.modalIcon = "warning sign"
                                this.onConfirmed = {
                                    ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                        val newBlacklist = ApiClient.reblacklist(props.blacklist.blacklistId)
                                        props.updateBlacklist(props.blacklist, newBlacklist)
                                    }
                                }
                            }
                        }
                        actionButtonWithConfirmation {
                            this.text = t("actions.delete")
                            this.icon = "trash"
                            this.color = "red"
                            this.modalMessage = t("actions.delete.blacklist.message")
                            this.modalDescription = t("actions.delete.blacklist.description")
                            this.modalIcon = "warning sign"
                            this.onConfirmed = {
                                ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                    ApiClient.deleteBlacklist(props.blacklist.blacklistId)
                                    props.updateBlacklist(props.blacklist, props.blacklist.copy(deleted = true))
                                }
                            }
                        }
                    }
                }
            }
        }
        val account = props.account ?: return@collapsibleTableRow
        if (!account.canEditBlacklist(blacklist)) return@collapsibleTableRow
        commentList {
            this.account = props.account
            this.showEditor = true
            this.loadComments = {
                ApiClient.getBlacklistComments(blacklist.blacklistId)
            }
            this.createComment = { text ->
                ApiClient.postBlacklistComment(blacklist.blacklistId, text)
            }
        }
    }
}

fun RBuilder.blacklistDetail(handler: BlacklistDetailProps.() -> Unit) = child(BlacklistDetailComponent) {
    attrs { handler() }
}