package com.perpheads.bans.components.ranks

import com.perpheads.bans.responses.RankOverviewResponse
import com.perpheads.bans.util.useTranslation
import kotlinx.css.Color
import kotlinx.css.Cursor
import kotlinx.css.backgroundColor
import kotlinx.css.cursor
import react.Props
import react.RBuilder
import react.dom.*
import react.dom.events.MouseEventHandler
import react.fc
import react.useState
import styled.css
import styled.inlineStyles
import styled.styledTd
import styled.styledTr

external interface RankHeaderProps : Props {
    var rank: RankOverviewResponse
    var onClick: MouseEventHandler<*>
}

val RankHeaderComponent = fc<RankHeaderProps>("RankHeader") { props ->
    var hovered by useState(false)

    val rank = props.rank

    styledTr {
        attrs.onClick = props.onClick
        css {
            cursor = Cursor.pointer
            if (hovered) {
                classes += "active"
            }
        }

        attrs.onMouseEnter = { hovered = true }
        attrs.onMouseLeave = { hovered = false }

        td { +rank.name }
        td { +rank.playerCount.toString() }
        td { +rank.immunity.toString() }
        styledTd {
            inlineStyles {
                backgroundColor = Color(rank.colorHexString())
            }
            +rank.colorText()
        }
    }
}

fun RBuilder.rankHeader(handler: RankHeaderProps.() -> Unit) = child(RankHeaderComponent) { attrs(handler) }