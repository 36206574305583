package com.perpheads.bans

object ClientRoutes {
    //Bans
    const val bans: String = "/"
    fun banSearch(query: String) = "/?search=${encodeURIComponent(query)}"
    fun banSearch(banId: Int) = "/?banId=$banId"
    fun banAdmin(admin: String) = "/?admin=$admin"
    const val editBan: String = "/bans/:banId/edit"
    fun editBan(banId: Int): String = "/bans/$banId/edit"
    const val addBan: String = "/bans/new"
    fun addBan(steamId: String) = "/bans/new?steamid=${encodeURIComponent(steamId)}"

    //Warnings
    const val warnings: String = "/warnings"
    fun warningSearch(query: String) = "/warnings?search=${encodeURIComponent(query)}"
    fun warningSearch(warningId: Int) = "/warnings?warningId=$warningId"
    fun warningAdmin(admin: String) = "/warnings?admin=$admin"
    const val editWarning: String = "/warnings/:warningId/edit"
    fun editWarning(warningId: Int): String = "/warnings/$warningId/edit"
    const val addWarning: String = "/warnings/new"
    fun addWarning(steamId: String) = "/warnings/new?steamid=${encodeURIComponent(steamId)}"

    //Blacklists
    const val blacklists: String = "/blacklists"
    fun blacklistSearch(query: String) = "/blacklists?search=${encodeURIComponent(query)}"
    fun blacklistSearch(blacklistId: Int) = "/blacklists?blacklistId=$blacklistId"
    fun blacklistAdmin(admin: String) = "/blacklists?admin=$admin"
    const val editBlacklist: String = "/blacklists/:blacklistId/edit"
    fun editBlacklist(blacklistId: Int): String = "/blacklists/$blacklistId/edit"
    const val addBlacklist: String = "/blacklists/new"
    fun addBlacklist(steamId: String) = "/blacklists/new?steamid=${encodeURIComponent(steamId)}"


    //Logs
    const val logs: String = "/logs"
    fun logSearch(steamId: String) = "/logs?steamid=${encodeURIComponent(steamId)}"
    const val banLogs: String = "/bans/logs"
    const val warningLogs: String = "/warnings/logs"
    const val blacklistLogs: String = "/blacklists/logs"
    const val playerLogs: String = "/players/logs"
    const val commentLogs: String = "/comments/logs"

    //Reports
    const val reports: String = "/reports"
    const val reportLogs: String = "/report-logs"

    //Screenshots
    const val screenshots: String = "/screenshots"

    //Demos
    const val demos: String = "/demos"

    //Statistics
    const val statistics: String = "/statistics"

    //Player
    const val playerProfile: String = "/player/:communityId"
    fun playerProfile(communityId: String): String = "/player/$communityId"

    const val addAltAccount: String = "/player/:communityId/alt-accounts/new"
    fun addAltAccount(communityId: String) = "/player/$communityId/alt-accounts/new"

    //Ranks
    const val ranks: String = "/ranks"
    const val editRank: String = "/ranks/edit"
    fun editRank(steamId: String) = "/ranks/edit?steamid=${com.perpheads.bans.encodeURIComponent(steamId)}"

    //Account
    const val account: String = "/account"
    const val steamCallback: String = "/steam-callback"
}