package com.perpheads.bans.components.ranks

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.RankOverviewResponse
import react.Props
import react.RBuilder
import react.dom.events.MouseEventHandler
import react.fc

external interface RankProps : Props {
    var account: AccountInfoResponse
    var rank: RankOverviewResponse
    var expanded: Boolean
    var onRankClicked: MouseEventHandler<*>
}

val RankComponent = fc<RankProps>("Rank") { props ->
    rankHeader {
        this.rank = props.rank
        this.onClick = props.onRankClicked
    }
    rankDetails {
        this.account = props.account
        this.rank = props.rank
        this.expanded = props.expanded
    }
}

fun RBuilder.rank(handler: RankProps.() -> Unit) = child(RankComponent) { attrs(handler) }