package com.perpheads.bans.wrappers.semantic

import js.objects.jso
import react.PropsWithChildren
import react.RBuilder
import react.RHandler

fun RBuilder.semanticContainer(handler: RHandler<PropsWithChildren>) {
    Container {
        attrs {

        }

        handler.invoke(this)
    }
}

fun RBuilder.semanticMenu(pointing: Boolean, handler: RHandler<MenuProps>) {
    Menu {
        attrs {
            this.pointing = pointing
        }

        handler.invoke(this)
    }
}

fun RBuilder.semanticMenuItem(
    handler: RHandler<MenuItemProps>
) {
    MenuItem {
        handler.invoke(this)
    }
}

fun RBuilder.semanticMenuMenu(
    position: String,
    handler: RHandler<MenuMenuProps> = {}
) {
    MenuMenu {
        attrs {
            this.position = position
        }
        handler.invoke(this)
    }
}

fun RBuilder.semanticInput(
    handler: (InputProps).() -> Unit
) {
    Input {
        attrs(handler)
    }
}

fun RBuilder.semanticCheckbox(
    handler: (CheckboxProps).() -> Unit
) {
    Checkbox {
        attrs(handler)
    }
}

fun RBuilder.semanticDropdown(
    handler: RHandler<DropdownProps>
) {
    Dropdown {
        handler.invoke(this)
    }
}

fun RBuilder.semanticDropdownMenu(
    scrolling: Boolean? = null,
    handler: RHandler<DropdownMenuProps>
) {
    DropdownMenu {
        attrs {
            this.scrolling = scrolling
        }
        handler.invoke(this)
    }
}

fun RBuilder.semanticDropdownItem(
    text: String? = null,
    icon: String? = null,
    description: String? = null,
    handler: RHandler<DropdownItemProps> = {}
) {
    DropdownItem {
        attrs {
            this.text = text
            this.description = description
            this.icon = icon
        }
        handler.invoke(this)
    }
}

fun RBuilder.semanticModal(handler: RHandler<ModalProps>) {
    Modal {
        handler.invoke(this)
    }
}

fun RBuilder.semanticHeader(handler: RHandler<HeaderProps>) {
    Header {
        handler.invoke(this)
    }
}

fun RBuilder.semanticModalHeader(handler: RHandler<ModalHeaderProps>) {
    ModalHeader {
        handler.invoke(this)
    }
}

fun RBuilder.semanticModalContent(handler: RHandler<ModalContentProps>) {
    ModalContent {
        handler.invoke(this)
    }
}

fun RBuilder.semanticModalActions(handler: RHandler<ModalActionsProps>) {
    ModalActions {
        handler.invoke(this)
    }
}

fun RBuilder.semanticButton(handler: RHandler<ButtonProps>) {
    Button {
        handler.invoke(this)
    }
}

fun RBuilder.semanticIconGroup(handler: RHandler<IconGroupProps>) {
    IconGroup {
        handler.invoke(this)
    }
}

fun RBuilder.semanticIcon(name: String, size: String? = null, color: String? = null, corner: String? = null) {
    Icon {
        attrs.name = name
        attrs.size = size
        attrs.color = color
        attrs.corner = corner
    }
}

fun RBuilder.semanticForm(handler: RHandler<FormProps>) {
    Form {
        handler.invoke(this)
    }
}


fun RBuilder.semanticFormField(handler: RHandler<FormFieldProps>) {
    FormField {
        handler.invoke(this)
    }
}


fun RBuilder.semanticFormGroup(handler: RHandler<FormGroupProps>) {
    FormGroup {
        handler.invoke(this)
    }
}

fun semanticFormError(message: String, pointing: String): FormFieldError {
    return jso {
        this.content = message
        this.pointing = pointing
    }
}

fun RBuilder.semanticTextArea(handler: TextAreaProps.() -> Unit) {
    TextArea {
        attrs(handler)
    }
}

fun RBuilder.semanticDivider(
    handler: DividerProps.() -> Unit = {}
) {
    Divider {
        attrs(handler)
    }
}

fun RBuilder.semanticGrid(handler: RHandler<GridProps>) {
    Grid {
        handler.invoke(this)
    }
}

fun RBuilder.semanticGridColumn(handler: RHandler<GridColumnProps>) {
    GridColumn {
        handler.invoke(this)
    }
}

fun RBuilder.semanticSegment(handler: RHandler<SegmentProps>) {
    Segment {
        handler.invoke(this)
    }
}

fun RBuilder.semanticPagination(handler: PaginationProps.() -> Unit) {
    Pagination {
        attrs(handler)
    }
}

fun RBuilder.semanticSearch(handler: SearchProps.() -> Unit) {
    Search {
        attrs(handler)
    }
}

fun RBuilder.semanticFeed(handler: RHandler<FeedProps>) = Feed { handler.invoke(this) }
fun RBuilder.semanticFeedEvent(handler: RHandler<FeedEventProps>) = FeedEvent { handler.invoke(this) }
fun RBuilder.semanticFeedLabel(handler: RHandler<FeedLabelProps>) = FeedLabel { handler.invoke(this) }
fun RBuilder.semanticFeedDate(handler: RHandler<FeedDateProps>) = FeedDate { handler.invoke(this) }
fun RBuilder.semanticFeedSummary(handler: RHandler<FeedSummaryProps>) = FeedSummary { handler.invoke(this) }
fun RBuilder.semanticFeedExtra(handler: RHandler<FeedExtraProps>) = FeedExtra { handler.invoke(this) }
fun RBuilder.semanticFeedContent(handler: RHandler<FeedContentProps>) = FeedContent { handler.invoke(this) }