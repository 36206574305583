@file:OptIn(ExperimentalTime::class)

package com.perpheads.bans.components.logs

import com.perpheads.bans.*
import com.perpheads.bans.requests.ServerLogSearchRequest
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import js.objects.jso
import kotlinx.datetime.*
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.h4
import react.dom.label
import react.router.NavigateFunction
import remix.run.router.Location
import web.html.InputType
import kotlin.js.Date
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime

data class PositionSetting(val startX: Double, val startY: Double, val endX: Double, val endZ: Double)

data class LogSearchSettings(
    val query: String? = null,
    val steamId: String? = null,
    val startDate: Instant? = null,
    val endDate: Instant? = null,
    val page: Int = 1,
    val orderByRelevance: Boolean = false,
    val orderAscending: Boolean = false,
    val categories: List<LogCategory> = emptyList(),
    val positionFilter: PositionSetting? = null,
    val startX: Int? = null,
    val startY: Int? = null,
    val endX: Int? = null,
    val endY: Int? = null,
) {
    companion object {
        fun parseFromLocation(location: Location<*>): LogSearchSettings {
            val parameters = parseQueryString(location.search.drop(1))

            return LogSearchSettings(
                query = parameters["query"],
                steamId = parameters["steamid"],
                startDate = parameters["start_date"]?.let(Instant::parse),
                endDate = parameters["end_date"]?.let(Instant::parse),
                page = parameters["page"]?.toIntOrNull() ?: 1,
                orderByRelevance = parameters["order_by_relevance"]?.toBooleanStrictOrNull() ?: false,
                orderAscending = parameters["order_ascending"]?.toBooleanStrictOrNull() ?: false,
                startX = parameters["start_x"]?.toIntOrNull(),
                startY = parameters["start_y"]?.toIntOrNull(),
                endX = parameters["end_x"]?.toIntOrNull(),
                endY = parameters["end_y"]?.toIntOrNull(),
                categories = parameters["categories"]?.split(",")
                    ?.mapNotNull { kotlin.runCatching { LogCategory.valueOf(it) }.getOrNull() }
                    ?: emptyList()
            )
        }
    }

    fun appendToLocation(navigate: NavigateFunction) {
        val parameters = Parameters.build {
            if (query?.isNotBlank() == true) set("query", query)
            if (steamId?.isNotBlank() == true) set("steamid", steamId)
            if (startDate != null) set("start_date", startDate.toString())
            if (endDate != null) set("end_date", endDate.toString())
            if (page != 1) set("page", page.toString())
            if (orderByRelevance) set("order_by_relevance", "true")
            if (orderAscending) set("order_ascending", "true")
            if (startX != null) set("start_x", startX.toString())
            if (startY != null) set("start_y", startY.toString())
            if (endX != null) set("end_x", endX.toString())
            if (endY != null) set("end_y", endY.toString())
            if (categories.isNotEmpty()) set("categories", categories.joinToString(",") { it.name })
        }
        if (parameters.isNotBlank()) {
            navigate(ClientRoutes.logs + "?$parameters")
        } else {
            navigate(ClientRoutes.logs)
        }
    }


    fun toSearchRequest(): ServerLogSearchRequest {
        return ServerLogSearchRequest(
            query = query?.trim() ?: "",
            steamId = steamId ?: "",
            start = startDate,
            end = endDate,
            page = page,
            categories = categories.map { it.categoryNum },
            orderByRelevance = orderByRelevance,
            orderAscending = orderAscending,
            startX = startX,
            startY = startY,
            endX = endX,
            endY = endY,
        )
    }
}

external interface LogSearchFormProps : Props {
    var account: AccountInfoResponse
    var currentSearchSettings: LogSearchSettings
    var onSearched: (LogSearchSettings) -> Unit
}

private fun Instant.toInputString(): String {
    val jsDate = this.toJSDate()
    return Date(jsDate.getTime() - jsDate.getTimezoneOffset() * 60000).toISOString().substring(0 until 19)
}

private fun constructInstant(dateTimeStr: String?): Instant? {
    val dateTime = dateTimeStr?.let { kotlin.runCatching { LocalDateTime.parse(dateTimeStr) }.getOrNull() }
        ?: return null

    val timeAtStartOfDay = dateTime.date.atStartOfDayIn(TimeZone.currentSystemDefault())
    val durationInDay = (dateTime.hour * 3600 + dateTime.minute * 60 + dateTime.second).seconds

    return timeAtStartOfDay + durationInDay
}


val LogSearchFormComponent = fc<LogSearchFormProps>("LogSearchForm") { props ->
    val t = useTranslation()
    var settings by useState(props.currentSearchSettings)
    var selectedArea by useState<SelectedArea>()
    useEffect(props.currentSearchSettings) {
        settings = props.currentSearchSettings
        val startX = props.currentSearchSettings.startX
        val startY = props.currentSearchSettings.startY
        val endX = props.currentSearchSettings.endX
        val endY = props.currentSearchSettings.endY
        if (startX != null && startY != null && endX != null && endY != null) {
            selectedArea = SelectedArea(startX.toDouble(), endX.toDouble(), startY.toDouble(), endY.toDouble())
        }
    }

    val logOptions = props.account.availableLogCategories().map {
        jso<DropdownOptions> {
            this.key = it.categoryNum.toString()
            this.text = t(it.translationKey)
            this.value = it.ordinal
        }
    }.toTypedArray()

    h4 { +t("logs.title") }
    semanticDivider { }
    semanticForm {
        semanticFormGroup {
            attrs.widths = "equal"
            semanticFormField {
                label { +t("logs.query") }
                semanticInput {
                    placeholder = t("logs.query.placeholder")
                    value = settings.query ?: ""
                    onChange = {
                        val newQuery = (it.target as HTMLInputElement).value.trimStart()
                        settings = settings.copy(query = newQuery, page = 1)
                    }
                }
            }
            semanticFormField {
                label { +t("logs.steamid") }
                semanticInput {
                    placeholder = t("logs.steamid.placeholder")
                    value = settings.steamId ?: ""
                    onChange = {
                        val newSteamId = (it.target as HTMLInputElement).value.trim()
                        settings = settings.copy(steamId = newSteamId, page = 1)
                    }
                }
            }
        }
        semanticFormGroup {
            attrs.widths = "equal"
            semanticFormField {
                label { +t("logs.start_date") }
                semanticInput {
                    value = settings.startDate?.toInputString() ?: ""
                    type = InputType.datetimeLocal
                    step = "300"
                    onChange = {
                        val newStartDate = (it.target as HTMLInputElement).value
                        settings = settings.copy(startDate = constructInstant(newStartDate))
                    }
                }
            }
            semanticFormField {
                label { +t("logs.end_date") }
                semanticInput {
                    value = settings.endDate?.toInputString() ?: ""
                    type = InputType.datetimeLocal
                    step = "300"
                    onChange = {
                        val newEndDate = (it.target as HTMLInputElement).value
                        settings = settings.copy(endDate = constructInstant(newEndDate))
                    }
                }
            }
        }
        semanticFormGroup {
            attrs.widths = "equal"
            semanticFormField {
                label { +t("logs.page") }
                semanticInput {
                    value = settings.page.toString()
                    type = InputType.number
                    onChange = {
                        val newPage = (it.target as HTMLInputElement).value.toIntOrNull() ?: 1
                        settings = settings.copy(page = newPage.coerceIn(1..100))
                    }
                }
            }
            semanticFormField {
                label { +t("logs.categories") }
                semanticDropdown {
                    attrs.value = settings.categories.map { it.ordinal }.toTypedArray()
                    attrs.placeholder = t("logs.categories.placeholder")
                    attrs.selection = true
                    attrs.multiple = true
                    attrs.search = true
                    attrs.clearable = true
                    attrs.options = logOptions
                    attrs.onChange = { _, values ->
                        val categoryOrdinals = values.value.unsafeCast<Array<Int>>()
                        val categories = categoryOrdinals.map { LogCategory.values()[it] }
                        settings = settings.copy(categories = categories.toList(), page = 1)
                    }
                }
            }
        }
        semanticFormGroup {
            attrs.widths = "equal"
            semanticFormField {
                label { +t("logs.order_ascending") }
                semanticCheckbox {
                    checked = settings.orderAscending
                    onChange = { _, data ->
                        val checked = data.checked.unsafeCast<Boolean>()
                        settings = settings.copy(orderAscending = checked, page = 1)
                    }
                }
            }
            semanticFormField {
                label { +t("logs.order_by_relevance") }
                semanticCheckbox {
                    checked = settings.orderByRelevance
                    onChange = { _, data ->
                        val checked = data.checked.unsafeCast<Boolean>()
                        settings = settings.copy(orderByRelevance = checked)
                    }
                }
            }
            semanticFormField {
                label { +t("logs.map_area")  }
                MapAreaDropdown {
                    attrs.onAreaChanged = { newArea ->
                        selectedArea = newArea
                        settings = settings.copy(
                            startX = newArea?.startX?.roundToInt(),
                            endX = newArea?.endX?.roundToInt(),
                            startY = newArea?.startY?.roundToInt(),
                            endY = newArea?.endY?.roundToInt(),
                        )
                    }
                    attrs.area = selectedArea
                }
            }
        }
        semanticButton {
            attrs.onClick = {
                props.onSearched(settings)
            }

            +t("button.search")
        }
    }
}

fun RBuilder.logSearchForm(
    account: AccountInfoResponse,
    currentSearchSettings: LogSearchSettings,
    onSearched: (LogSearchSettings) -> Unit
) =
    child(LogSearchFormComponent) {
        attrs {
            this.account = account
            this.currentSearchSettings = currentSearchSettings
            this.onSearched = onSearched
        }
    }