package com.perpheads.bans

enum class SCAMPermission(val permission: String) {
    Ban("Ban"),
    Warn("Warn"),
    Logs("Logs"),
    Blacklist("Blacklist"),
    Kick("Kick"),
    ChatAdmin("Chatadmin"),
    Rank("Rank"),
    EditRank("EditRank"),
    RestrictedDebug("RestrictedDebug"),
    ScamLogs("SCAMLogs"),
    IdentityLogs("IdentityLogs"),
}