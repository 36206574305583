package com.perpheads.bans.components

import com.perpheads.bans.getCurrentTheme
import react.Props
import react.RBuilder
import react.dom.div
import react.fc
import styled.css
import styled.styledDiv

external interface LoadingSpinnerProps : Props {
    var isLoading: Boolean
    var translate: Boolean
}

val LoadingSpinnerComponent = fc<LoadingSpinnerProps>("LoadingSpinner") { props ->
    val theme = getCurrentTheme()
    styledDiv {
        css {
            classes += "ui dimmer"
            if (!theme.dark) {
                classes += "inverted"
            }
            if (props.isLoading) classes += "active"
        }
        div("ui text loader") {
            +"loading"
        }
    }
}

fun RBuilder.loadingSpinner(handler: LoadingSpinnerProps.() -> Unit) = child(LoadingSpinnerComponent) {
    attrs.translate = true
    attrs(handler)
}