package com.perpheads.bans.components.warnings

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.WarningResponse
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*

external interface WarningTableProps : PropsWithChildren {
    var account: AccountInfoResponse?
    var warnings: List<WarningResponse>
    var updateWarning: (WarningResponse, WarningResponse) -> Unit
}

val WarningTableComponent = fc<WarningTableProps>("WarningTable") { props ->
    var expandedWarning by useState<Int>()
    val t = useTranslation()

    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.date") }
                th { +t("headers.player") }
                th { +t("headers.admin") }
            }
        }
        tbody {
            for (w in props.warnings) {
                warning {
                    this.account = props.account
                    this.key = w.warningId.toString()
                    this.warning = w
                    this.expanded = expandedWarning == w.warningId
                    this.onWarningClicked = {
                        expandedWarning = if (expanded) {
                            null
                        } else {
                            w.warningId
                        }
                    }
                    this.updateWarning = props.updateWarning
                }
            }
        }
        props.children()
    }
}

fun RBuilder.warningTable(handler: RElementBuilder<WarningTableProps>.() -> Unit) = WarningTableComponent {
    handler.invoke(this)
}