package com.perpheads.bans.responses

import com.perpheads.bans.profileIdToSteamId
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable


@Serializable
data class WarningResponse(
    val warningId: Int,
    val playerName: String,
    val communityId: String,
    val reason: String,
    val deleted: Boolean,
    val date: Instant,
    val admin: PlayerResponse? = null
) {
    fun steamId(): String = communityId.profileIdToSteamId() ?: "STEAM_ID_ERROR"
}

@Serializable
data class WarningSearchResponse(
    val count: Int,
    val warnings: List<WarningResponse>
)