package com.perpheads.bans.components.bans

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.BanResponse
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*

external interface BanTableProps : PropsWithChildren {
    var account: AccountInfoResponse?
    var bans: List<BanResponse>
    var updateBan: (BanResponse, BanResponse) -> Unit
}

val BanTableComponent = fc<BanTableProps>("BanTable") { props ->
    var expandedBan by useState<Int>()
    val t = useTranslation()

    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.date") }
                th { +t("headers.player") }
                th { +t("headers.admin") }
                th { +t("headers.duration") }
            }
        }
        tbody {
            for (b in props.bans) {
                ban {
                    this.account = props.account
                    this.key = b.banId.toString()
                    this.ban = b
                    this.expanded = expandedBan == b.banId
                    this.onBanClicked = {
                        expandedBan = if (expanded) {
                            null
                        } else {
                            b.banId
                        }
                    }
                    this.updateBan = props.updateBan
                }
            }
        }
        props.children()
    }
}

fun RBuilder.banTable(handler: RElementBuilder<BanTableProps>.() -> Unit) = BanTableComponent {
    handler.invoke(this)
}