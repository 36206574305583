package com.perpheads.bans.responses

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class DemoResponse(
    val demoId: Int,
    val date: Instant,
    val lastUpdated: Instant,
    val player: PlayerResponse,
    val admin: PlayerResponse?,
    val status: DemoRequestStatus,
    val name: String
)

@Serializable
data class DemosResponse(
    val count: Int,
    val demos: List<DemoResponse>
)