package com.perpheads.bans.requests

import kotlinx.serialization.Serializable

@Serializable
class BlacklistRequest(
    val steamId: String,
    val type: String,
    val reason: String,
    val duration: Long?
)