package com.perpheads.bans.components.ranks

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.RankOverviewResponse
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*

external interface RankTableProps : Props {
    var account: AccountInfoResponse
    var ranks: List<RankOverviewResponse>
}

val RankTableComponent = fc<RankTableProps>("RankTable") { props ->
    var expandedRank by useState<Int>()
    val t = useTranslation()

    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.rank") }
                th { +t("headers.players") }
                th { +t("headers.immunity") }
                th { +t("headers.color") }
            }
        }
        tbody {
            for (r in props.ranks) {
                rank {
                    this.account = props.account
                    this.key = r.rankId.toString()
                    this.rank = r
                    this.expanded = expandedRank == r.rankId
                    this.onRankClicked = {
                        expandedRank = if (expanded) {
                            null
                        } else {
                            r.rankId
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.rankTable(handler: RankTableProps.() -> Unit) = child(RankTableComponent) { attrs(handler) }