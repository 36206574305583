package com.perpheads.bans.components.profile

import com.perpheads.bans.*
import com.perpheads.bans.components.bans.EditBanPageComponent
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.label
import react.router.useLocation
import react.router.useNavigate

external interface AddAltAccountComponentProps : Props {
    var communityId: String
}


val AddAltAccountComponent = fc<AddAltAccountComponentProps>("AddAltAccountPage") { props ->
    val location = useLocation()
    val navigate = useNavigate()
    val t = useTranslation()
    val (loading, setLoading) = useState(false)
    val errorState = useState(false)
    var altSteamIdError by useState(false)
    var altSteamIdText by useState("")
    var nicknameText by useState("")
    var altNicknameText by useState("")
    var allowed by useState(false)
    val steamId = props.communityId.profileIdToSteamId() ?: "STEAM_ID_ERROR"

    useEffect(steamId) {
        if (!steamId.matches(CommonRegexes.steamIdRegex)) {
            navigate("/")
            return@useEffect
        }
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            nicknameText = ApiClient.getNameBySteamId(steamId) ?: return@launchWithErrorHandler
        }
    }

    useEffect(altSteamIdText) {
        if (!altSteamIdText.matches(CommonRegexes.steamIdRegex)) return@useEffect
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            altNicknameText = ApiClient.getNameBySteamId(altSteamIdText) ?: return@launchWithErrorHandler
        }
    }

    fun submit() {
        val altCommunityId = altSteamIdText.steamIdToProfileId()?.toString()
        if (!altSteamIdText.matches(CommonRegexes.steamIdRegex) || altCommunityId == null) {
            altSteamIdError = true
            return
        }
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            ApiClient.addAlt(props.communityId, altCommunityId, allowed)
            navigate(ClientRoutes.playerProfile(props.communityId))
        }
    }
    errorModal(errorState = errorState)
    requiredUserPage {
        loadingSpinner { isLoading = loading }

        semanticForm {
            semanticFormField {
                attrs.width = 5
                attrs.disabled = true
                semanticInput {
                    label = t("headers.steamid")
                    value = steamId
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.disabled = true
                semanticInput {
                    label = t("headers.nickname")
                    value = nicknameText
                    placeholder = t("headers.nickname")
                    onChange = {
                        nicknameText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.error = altSteamIdError
                semanticInput {
                    label = t("headers.alt_steamid")
                    placeholder = t("headers.alt_steamid")
                    value = altSteamIdText
                    onChange = {
                        altSteamIdText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.disabled = true
                semanticInput {
                    label = t("headers.alt_nickname")
                    value = altNicknameText
                    placeholder = t("headers.alt_nickname")
                    onChange = {
                        altNicknameText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                label { +t("headers.allowed") }
                semanticCheckbox {
                    checked = allowed
                    onChange = { _, data ->
                        val checked = data.checked.unsafeCast<Boolean>()
                        allowed = checked
                    }
                }
            }
            semanticDivider()
            semanticButton {
                attrs {
                    this.onClick = {
                        submit()
                    }
                }
                +t("button.submit")
            }
        }
    }
}


fun RBuilder.addAltAccountPage(communityId: String) = child(AddAltAccountComponent) {
    attrs {
        this.communityId = communityId
    }
}