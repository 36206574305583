package com.perpheads.bans.components.warnings

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.components.*
import com.perpheads.bans.components.comments.commentList
import com.perpheads.bans.linkify
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.WarningResponse
import com.perpheads.bans.util.collapsingTableRow
import com.perpheads.bans.util.linkedCollapsingTableRow
import com.perpheads.bans.util.useTranslation
import react.Props
import react.RBuilder
import react.dom.table
import react.dom.tbody
import react.dom.td
import react.dom.tr
import react.fc
import react.router.useNavigate
import react.useState


external interface WarningDetailProps : Props {
    var warning: WarningResponse
    var expanded: Boolean
    var account: AccountInfoResponse?
    var updateWarning: (WarningResponse, WarningResponse) -> Unit
}

val WarningDetailComponent = fc<WarningDetailProps>("WarningDetails") { props ->
    val t = useTranslation()
    val navigate = useNavigate()

    val warning = props.warning
    var wasShown by useState(false)
    val errorState = useState(false)
    val (loading, setLoading) = useState(false)

    if (!props.expanded && !wasShown) return@fc
    if (props.expanded && !wasShown) {
        wasShown = true //This is _NOT_ useless
    }

    errorModal(errorState = errorState)
    collapsibleTableRow(expanded = props.expanded, colSpan = "3") {
        loadingSpinner { isLoading = loading }
        table("ui celled compact table") {
            tbody {
                linkedCollapsingTableRow(
                    navigate,
                    t("headers.player"),
                    ClientRoutes.playerProfile(warning.communityId)
                ) {
                    +warning.playerName
                }
                collapsingTableRow(t("headers.reason")) { linkify(warning.reason) }
                linkedCollapsingTableRow(navigate, t("headers.steamid"), ClientRoutes.warningSearch(warning.steamId())) {
                    +warning.steamId()
                }
                val admin = warning.admin
                if (admin != null) {
                    linkedCollapsingTableRow(
                        navigate,
                        t("headers.admin"),
                        ClientRoutes.warningAdmin(admin.steamId())
                    ) {
                        +admin.name
                    }
                }
                val account = props.account ?: return@tbody
                if (!account.canBan) return@tbody
                tr {
                    td("collapsing") { +t("headers.actions") }
                    td {
                        if (warning.deleted) {
                            actionButtonWithConfirmation {
                                this.text = t("actions.undelete")
                                this.icon = "undo"
                                this.color = "green"
                                this.modalMessage = t("actions.undelete.warning.message")
                                this.modalDescription = t("actions.undelete.warning.description")
                                this.modalIcon = "warning sign"
                                this.onConfirmed = {
                                    ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                        ApiClient.undeleteWarning(props.warning.warningId)
                                        props.updateWarning(props.warning, props.warning.copy(deleted = false))
                                    }
                                }
                            }
                            return@td
                        }
                        actionButton(navigate, "Edit", "edit", "blue", ClientRoutes.editWarning(props.warning.warningId))
                        actionButtonWithConfirmation {
                            this.text = t("actions.delete")
                            this.icon = "trash"
                            this.color = "red"
                            this.modalMessage = t("actions.delete.warning.message")
                            this.modalDescription = t("actions.delete.warning.description")
                            this.modalIcon = "warning sign"
                            this.onConfirmed = {
                                ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                    ApiClient.deleteWarning(props.warning.warningId)
                                    props.updateWarning(props.warning, props.warning.copy(deleted = true))
                                }
                            }
                        }
                    }
                }
            }
        }
        if (props.account?.canWarn != true) return@collapsibleTableRow
        commentList {
            this.account = props.account
            this.showEditor = true
            this.loadComments = {
                ApiClient.getWarningComments(warning.warningId)
            }
            this.createComment = { text ->
                ApiClient.postWarningComment(warning.warningId, text)
            }
        }
    }
}

fun RBuilder.warningDetails(handler: WarningDetailProps.() -> Unit) = child(WarningDetailComponent) {
    attrs { handler() }
}