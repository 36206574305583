package com.perpheads.bans.responses

import com.perpheads.bans.profileIdToSteamId
import kotlinx.serialization.Serializable

@Serializable
data class PlayerResponse(
    val id: Int,
    val name: String,
    val communityId: String
) {
    fun steamId(): String = communityId.profileIdToSteamId() ?: "STEAM_ID_ERROR"
}