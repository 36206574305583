package com.perpheads.bans.components.logs

import com.perpheads.bans.linkify
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.ActionLogResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.playerLink
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*
import react.router.useNavigate

external interface ActionLogTableEntryProps<T: ActionLogResponse> : Props {
    var log: T
    var descriptionBuilder: (RBuilder, ActionLogTableEntryProps<T>) -> Unit
}

fun <T : ActionLogResponse> ActionLogTableEntry() =
    fc<ActionLogTableEntryProps<T>>("ActionLogTableEntry") { props ->
        val navigate = useNavigate()

        tr {
            td {
                playerLink(props.log.admin.name, props.log.admin.communityId, navigate)
            }
            //TODO: Translate
            td { linkify(props.log.message) }
            props.descriptionBuilder(this, props)
            td { +props.log.logDate.format() }
        }
    }

fun <T : ActionLogResponse> RBuilder.actionLogTableEntry(handler: ActionLogTableEntryProps<T>.() -> Unit) =
    ActionLogTableEntry<T>().invoke {
        attrs(handler)
    }

external interface ActionLogTableProps<T : ActionLogResponse> : PropsWithChildren {
    var headerString: String
    var account: AccountInfoResponse?
    var logs: List<T>
    var descriptionBuilder: (RBuilder, ActionLogTableEntryProps<T>) -> Unit
}

fun <T : ActionLogResponse> ActionLogTable() = fc<ActionLogTableProps<T>>("ActionLogTable") { props ->
    val t = useTranslation()
    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.admin") }
                th { +t("headers.message") }
                th { +props.headerString }
                th { +t("headers.date") }
            }
        }
        tbody {
            for (log in props.logs) {
                actionLogTableEntry<T> {
                    this.log = log
                    this.key = log.logId.toString()
                    this.descriptionBuilder = props.descriptionBuilder
                }
            }
        }
        props.children()
    }
}

fun <T : ActionLogResponse> RBuilder.actionLogTable(handler: RElementBuilder<ActionLogTableProps<T>>.() -> Unit) =
    ActionLogTable<T>().invoke {
        handler.invoke(this)
    }