package com.perpheads.bans.components.statistics

import com.perpheads.bans.wrappers.Plotly
import com.perpheads.bans.wrappers.PlotlyLayout
import com.perpheads.bans.wrappers.PlotlyPlotSettings
import js.objects.jso
import kotlinx.html.id
import react.Props
import react.RBuilder
import react.dom.div
import react.fc
import react.useEffect

external interface PlotProps : Props {
    var id: String
    var data: Array<PlotlyPlotSettings>
}

val PlotComponent = fc<PlotProps>("Plot") { props ->
    val layout = jso<PlotlyLayout> {
        hovermode = "x"
    }

    useEffect {
        Plotly.react(props.id, props.data, layout)
    }
    div {
        attrs.id = props.id
    }
}

fun RBuilder.plot(handler: PlotProps.() -> Unit) = child(PlotComponent) { attrs(handler) }