package com.perpheads.bans.components.profile

import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.profileIdToSteamId
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.ProfileResponse
import com.perpheads.bans.util.pushMouseEventHandler
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.semanticIcon
import com.perpheads.bans.wrappers.semantic.semanticSegment
import kotlinx.css.em
import kotlinx.css.marginBottom
import react.Props
import react.RBuilder
import react.dom.h4
import react.dom.onClick
import react.fc
import react.router.NavigateFunction
import react.router.useNavigate
import styled.css
import styled.inlineStyles
import styled.styledA


external interface ProfileActionsProps : Props {
    var account: AccountInfoResponse
    var profileResponse: ProfileResponse?
}


private fun RBuilder.actionButton(
    shouldShow: Boolean,
    navigate: NavigateFunction,
    label: String,
    icon: String,
    iconColor: String,
    href: String
) {
    if (!shouldShow) return
    styledA {
        css { classes += "ui fluid button" }
        inlineStyles { marginBottom = 0.5.em }
        attrs.href = href
        attrs.onClick = navigate.pushMouseEventHandler(href)
        semanticIcon(name = icon, color = iconColor)
        +label
    }
}

val ProfileActionsComponent = fc<ProfileActionsProps>("ProfileActions") { props ->
    val t = useTranslation()
    val navigate = useNavigate()
    val steamIdStr = props.profileResponse?.communityId?.profileIdToSteamId() ?: ""
    semanticSegment {
        h4("ui header") { +t("actions") }
        actionButton(
            shouldShow = props.account.canBan,
            navigate = navigate,
            label = t("actions.add_ban"),
            icon = "ban",
            iconColor = "red",
            href = ClientRoutes.addBan(steamIdStr)
        )
        actionButton(
            shouldShow = props.account.canWarn,
            navigate = navigate,
            label = t("actions.add_warning"),
            icon = "warning sign",
            iconColor = "orange",
            href = ClientRoutes.addWarning(steamIdStr)
        )
        actionButton(
            shouldShow = props.account.canBlacklist,
            navigate = navigate,
            label = t("actions.add_blacklist"),
            icon = "book",
            iconColor = "red",
            href = ClientRoutes.addBlacklist(steamIdStr)
        )
        actionButton(
            shouldShow = props.account.canViewLogs,
            navigate = navigate,
            label = t("actions.player_logs"),
            icon = "search",
            iconColor = "blue",
            href = ClientRoutes.logSearch(steamIdStr)
        )
        actionButton(
            shouldShow = props.account.canAssignRanks,
            navigate = navigate,
            label = t("actions.change_rank"),
            icon = "user",
            iconColor = "blue",
            href = ClientRoutes.editRank(steamIdStr)
        )
        actionButton(
            shouldShow = props.account.canAssignRanks,
            navigate = navigate,
            label = t("actions.add_alt"),
            icon = "user",
            iconColor = "red",
            href = ClientRoutes.addAltAccount(props.profileResponse?.communityId ?: "")
        )
    }
}

fun RBuilder.profileActions(handler: ProfileActionsProps.() -> Unit) = child(ProfileActionsComponent) {
    attrs(handler)
}