package com.perpheads.bans.components.profile

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.components.bans.banTable
import com.perpheads.bans.components.blacklists.blacklistTable
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.optionalUserPage
import com.perpheads.bans.components.warnings.warningTable
import com.perpheads.bans.replace
import com.perpheads.bans.responses.*
import com.perpheads.bans.util.updateElement
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.semanticGrid
import com.perpheads.bans.wrappers.semantic.semanticGridColumn
import com.perpheads.bans.wrappers.semantic.semanticSegment
import react.*
import react.dom.h2
import react.router.useNavigate

external interface ProfilePageProps : Props {
    var communityId: Long
}

val ProfilePageComponent = fc<ProfilePageProps>("ProfilePage") { props ->
    val t = useTranslation()
    val navigate = useNavigate()
    optionalUserPage { account ->
        val shouldShowActions = account?.canWarn == true
        var profile by useState<ProfileResponse>()
        val (profileLoading, setProfileLoading) = useState(false)
        var bans by useState(BanSearchResponse(0, emptyList()))
        val (bansLoading, setBansLoading) = useState(false)
        var relatedBans by useState(BanSearchResponse(0, emptyList()))
        val (relatedBansLoading, setRelatedBansLoading) = useState(false)
        var warnings by useState(WarningSearchResponse(0, emptyList()))
        val (warningsLoading, setWarningsLoading) = useState(false)
        var blacklists by useState(BlacklistSearchResponse(0, emptyList()))
        val (blacklistsLoading, setBlacklistsLoading) = useState(false)
        val errorState = useState(false)
        val (altAccountsLoading, setAltAccountsLoading) = useState(false)
        var altAccounts by useState(emptyList<AltAccountResponse>())
        val shouldShowAlts = account?.canBan == true

        useEffect(props.communityId) {
            profile = null

            ApiClient.launchWithErrorHandler(navigate, errorState, setProfileLoading) {
                try {
                    profile = ApiClient.getProfile(props.communityId)
                } catch (e: Exception) {
                    navigate.replace(ClientRoutes.bans)
                }
            }
            ApiClient.launchWithErrorHandler(navigate, errorState, setBansLoading) {
                bans = ApiClient.getProfileBans(props.communityId)
            }
            ApiClient.launchWithErrorHandler(navigate, errorState, setRelatedBansLoading) {
                relatedBans = ApiClient.getProfileRelatedBans(props.communityId)
            }
            ApiClient.launchWithErrorHandler(navigate, errorState, setWarningsLoading) {
                warnings = ApiClient.getProfileWarnings(props.communityId)
            }
            ApiClient.launchWithErrorHandler(navigate, errorState, setBlacklistsLoading) {
                blacklists = ApiClient.getProfileBlacklists(props.communityId)
            }
            if (shouldShowAlts) {
                ApiClient.launchWithErrorHandler(navigate, errorState, setAltAccountsLoading) {
                    altAccounts = ApiClient.getAltAccounts(props.communityId)
                }
            }
        }

        errorModal(errorState = errorState)
        semanticGrid {
            attrs.columns = if (shouldShowActions) 2 else 1
            attrs.stackable = true
            semanticGridColumn {
                profileDetails {
                    this.loading = profileLoading
                    this.profileResponse = profile
                    this.banCount = bans.count
                    this.warningCount = warnings.count
                    this.blacklistCount = blacklists.count
                    this.account = account
                }
            }
            if (shouldShowActions && account != null) {
                semanticGridColumn {
                    profileActions {
                        this.account = account
                        this.profileResponse = profile
                    }
                }
            }
        }
        semanticSegment {
            h2 { +t("navigation.bans") }
            loadingSpinner { isLoading = bansLoading }
            banTable {
                attrs {
                    this.account = account
                    this.bans = bans.bans
                    this.updateBan = { oldBan, newBan ->
                        val newBans = bans.bans.updateElement(oldBan, newBan)
                        bans = bans.copy(bans = newBans)
                    }
                }
            }
        }
        semanticSegment {
            h2 { +t("navigation.related_bans") }
            loadingSpinner { isLoading = relatedBansLoading }
            banTable {
                attrs {
                    this.account = account
                    this.bans = relatedBans.bans
                    this.updateBan = { oldBan, newBan ->
                        val newBans = relatedBans.bans.updateElement(oldBan, newBan)
                        relatedBans = relatedBans.copy(bans = newBans)
                    }
                }
            }
        }
        semanticSegment {
            h2 { +t("navigation.warnings") }
            loadingSpinner { isLoading = warningsLoading }
            warningTable {
                attrs {
                    this.account = account
                    this.warnings = warnings.warnings
                    this.updateWarning = { oldWarning, newWarning ->
                        val newWarnings = warnings.warnings.updateElement(oldWarning, newWarning)
                        warnings = warnings.copy(warnings = newWarnings)
                    }
                }
            }
        }
        semanticSegment {
            h2 { +t("navigation.blacklists") }
            loadingSpinner { isLoading = blacklistsLoading }
            blacklistTable {
                attrs {
                    this.account = account
                    this.blacklists = blacklists.blacklists
                    this.updateBlacklist = { oldBlacklist, newBlacklist ->
                        val newBlacklists = blacklists.blacklists.updateElement(oldBlacklist, newBlacklist)
                        blacklists = blacklists.copy(blacklists = newBlacklists)
                    }
                }
            }
        }
        if (shouldShowAlts) {
            semanticSegment {
                h2 { +t("navigation.alt_accounts") }
                loadingSpinner { isLoading = altAccountsLoading }
                altAccountsTable {
                    this.communityId = props.communityId
                    canDelete = account?.canAssignRanks == true
                    this.altAccounts = altAccounts
                    onAccountDeleted = { response ->
                        altAccounts = altAccounts.filter { it != response }
                    }
                }
            }
        }
    }
}

fun RBuilder.profilePage(handler: ProfilePageProps.() -> Unit) = child(ProfilePageComponent) {
    attrs(handler)
}

