package com.perpheads.bans.components.ranks

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.components.actionButton
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.RankOverviewResponse
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.semanticDivider
import react.*
import react.dom.div
import react.router.useNavigate

external interface RankPageProps : Props {

}

val RankPageComponent = fc<RankPageProps>("RankPage") {
    val t = useTranslation()
    val navigate = useNavigate()
    var ranks by useState<List<RankOverviewResponse>>(emptyList())
    val (loading, setLoading) = useState(true)
    val errorState = useState(false)

    useEffectOnce {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            ranks = ApiClient.getRankOverview()
        }
    }

    errorModal(errorState = errorState)
    requiredUserPage(canViewPage = { it.canAssignRanks }) { account ->
        loadingSpinner { isLoading = loading }
        div("ui right aligned container") {
            actionButton(
                navigate,
                t("actions.add_player"),
                "user plus",
                "green",
                ClientRoutes.editRank
            )
        }
        semanticDivider()
        rankTable {
            this.account = account
            this.ranks = ranks
        }
    }
}


fun RBuilder.rankPage() = child(RankPageComponent) {
    attrs {

    }
}