package com.perpheads.bans.components.logs

import com.perpheads.bans.MapCoordinates
import com.perpheads.bans.Vector2
import com.perpheads.bans.wrappers.semantic.Icon
import com.perpheads.bans.wrappers.semantic.Popup
import com.perpheads.bans.wrappers.semantic.PopupContent
import com.perpheads.bans.wrappers.semantic.PopupHeader
import js.objects.jso
import web.html.HTMLCanvasElement
import react.dom.html.ReactHTML.canvas
import react.*
import react.dom.events.MouseEvent
import react.dom.events.PointerEvent
import react.dom.events.PointerType
import react.dom.events.WheelEvent
import web.canvas.CanvasRenderingContext2D
import web.events.EventHandler
import web.events.EventType
import web.events.addEventListener
import web.events.removeEventListener
import web.html.HTMLElement
import web.html.Image
import web.uievents.MouseButton
import kotlin.math.PI
import kotlin.math.sign


external interface MapHoverComponentProps : Props {
    var locationX: Double
    var locationY: Double
}

data class MapClickData(
    val mapX: Double,
    val mapY: Double
)

external interface MapCanvasComponentProps : Props {
    var initialZoomFactor: Double
    var allowMoving: Boolean
    var allowZooming: Boolean
    var initialLocation: Vector2
    var width: Double
    var height: Double
    var drawFun: ((CanvasRenderingContext2D, ViewportData) -> Unit)?
    var onPointerDown: ((PointerEvent<*>, MapClickData) -> Unit)?
    var onPointerUp: ((PointerEvent<*>, MapClickData) -> Unit)?
    var onPointerMove: ((PointerEvent<*>, MapClickData) -> Unit)?
    var onWheel: ((WheelEvent<*>, MapClickData) -> Unit)?
}

data class ViewportData(
    val viewportX: Double,
    val viewportY: Double,
    val width: Double,
    val height: Double,
    val viewportImageWidth: Double,
    val viewportImageHeight: Double,
    val imageWidth: Double,
    val imageHeight: Double,
    val zoomFactor: Double,
) {
    fun adjustToFit(): ViewportData {
        var adjustmentX = 0.0
        var adjustmentY = 0.0

        if (viewportX < 0) {
            adjustmentX = -viewportX
        }
        if (viewportX + viewportImageWidth > imageWidth) {
            adjustmentX = imageWidth - (viewportX + viewportImageWidth)
        }
        if (viewportY < 0) {
            adjustmentY = -viewportY
        }
        if (viewportY + viewportImageHeight > imageHeight) {
            adjustmentY = imageHeight - (viewportY + viewportImageHeight)
        }
        return copy(viewportX + adjustmentX, viewportY + adjustmentY)
    }

    fun convertToViewportCoordinates(percentageX: Double, percentageY: Double): Vector2 {
        val xOffset = percentageX * imageWidth - viewportX
        val yOffset = percentageY * imageHeight - viewportY
        return Vector2((xOffset / viewportImageWidth) * width,  (yOffset / viewportImageHeight) * height)
    }

    fun getCenterMapCoordinate(): Vector2 {
        val percentageX = (0.5 * viewportImageWidth + viewportX) / imageWidth
        val percentageY = (0.5 * viewportImageHeight + viewportY) / imageHeight
        return MapCoordinates.convertToCoordinates(Vector2(percentageX, percentageY))
    }

    fun convertViewportToMap(pos: Vector2): Vector2 {
        val percentageX = ((pos.x / width) * viewportImageWidth) / imageWidth
        val percentageY = ((pos.y / height) * viewportImageHeight) / imageHeight

        return MapCoordinates.convertToDeltaCoordinates(Vector2(percentageX, percentageY))
    }
}

private val zoomRange = 1.0..8.0

val MapCanvasComponent = fc<MapCanvasComponentProps>("MapCanvas") { props ->
    var loadedImage by useState<Image>()
    val canvasRef = useRef<HTMLCanvasElement>()
    var currentLocation by useState(props.initialLocation)
    var zoomFactor by useState(props.initialZoomFactor)
    var dragStart by useState<Vector2>()
    val (percentX, percentY) = MapCoordinates.convertToRatios(currentLocation)

    fun getViewportData(): ViewportData? {
        val image = loadedImage ?: return null
        val viewportWidth = image.width / zoomFactor
        val viewportHeight = image.height / zoomFactor

        val viewportX = percentX * image.width - viewportWidth / 2
        val viewportY = percentY * image.height - viewportHeight / 2

        return ViewportData(
            viewportX = viewportX,
            viewportY = viewportY,
            width = props.width,
            height = props.height,
            viewportImageWidth = viewportWidth,
            viewportImageHeight = viewportHeight,
            imageWidth = image.width.toDouble(),
            imageHeight = image.height.toDouble(),
            zoomFactor = zoomFactor
        ).adjustToFit()
    }

    useEffect(props.initialZoomFactor) {
        zoomFactor = props.initialZoomFactor.coerceIn(zoomRange)
    }
    useEffect(props.initialLocation.x, props.initialLocation.y) {
        currentLocation = props.initialLocation
    }
    useEffect(currentLocation.x, currentLocation.y, percentX, percentY, props.width, props.height, zoomFactor, loadedImage) {
        val viewport = getViewportData() ?: return@useEffect

        currentLocation = viewport.getCenterMapCoordinate()
    }
    fun PointerEvent<*>.isMoveEvent(): Boolean {
        return pointerType == PointerType.mouse && button == MouseButton.SECONDARY
    }

    fun MouseEvent<*, *>.getOffset(): Vector2 {
        val rect = (target as HTMLElement).getBoundingClientRect()
        val offsetX = clientX - rect.left
        val offsetY = clientY - rect.top
        return Vector2(offsetX, offsetY)
    }

    fun MouseEvent<*, *>.toMapClickData(): MapClickData? {
        val viewportData = getViewportData() ?: return null
        val (offsetX, offsetY) = getOffset()

        val percentageX = ((offsetX / viewportData.width) * viewportData.viewportImageWidth + viewportData.viewportX) / viewportData.imageWidth
        val percentageY = ((offsetY / viewportData.height) * viewportData.viewportImageHeight + viewportData.viewportY) / viewportData.imageHeight
        val coordinates = MapCoordinates.convertToCoordinates(Vector2(percentageX, percentageY))
        return MapClickData(coordinates.x, coordinates.y)
    }

    fun onWheel(event: WheelEvent<*>) {
        val mapClickData = event.toMapClickData() ?: return
        val viewportData = getViewportData() ?: return
        props.onWheel?.invoke(event, mapClickData)
        if (props.allowZooming) {
            event.preventDefault()
            event.stopPropagation()
            val newZoomFactor = if (event.deltaY.sign > 0) {
                (zoomFactor / 1.5).coerceIn(zoomRange)
            } else {
                (zoomFactor * 1.5).coerceIn(zoomRange)
            }
            val (offsetX, offsetY) = event.getOffset()
            val newViewportImageWidth = viewportData.imageWidth / newZoomFactor
            val newViewportImageHeight = viewportData.imageHeight / newZoomFactor
            val cursorPercentageX = offsetX / viewportData.width
            val cursorPercentageY = offsetY / viewportData.height

            val (mapPercentageX, mapPercentageY) = MapCoordinates.convertToRatios(Vector2(mapClickData.mapX, mapClickData.mapY))
            val topLeftPercentageX = mapPercentageX - ((cursorPercentageX * newViewportImageWidth) / viewportData.imageWidth)
            val topLeftPercentageY = mapPercentageY - ((cursorPercentageY * newViewportImageHeight) / viewportData.imageHeight)

            val newViewportX = topLeftPercentageX * viewportData.imageWidth
            val newViewportY = topLeftPercentageY * viewportData.imageHeight

            val newViewPort = viewportData.copy(
                viewportX = newViewportX,
                viewportY = newViewportY,
                viewportImageWidth = newViewportImageWidth,
                viewportImageHeight = newViewportImageHeight,
                zoomFactor = newZoomFactor
            ).adjustToFit()

            zoomFactor = newZoomFactor
            currentLocation = newViewPort.getCenterMapCoordinate()
        }
    }

    useEffect(loadedImage, zoomFactor, props.allowZooming, currentLocation.x, currentLocation.y) {
        canvasRef.current?.addEventListener(EventType("wheel"), ::onWheel, jso { passive = false })

        cleanup {
            canvasRef.current?.removeEventListener(EventType("wheel"), ::onWheel)
        }
    }

    useEffectOnce {
        val newImage = Image()
        newImage.src = "/assets/paralakemap_v5.jpg"
        newImage.onload = EventHandler {
            loadedImage = newImage
        }
    }

    // Re-renders the canvas
    useEffect(loadedImage, zoomFactor, props.drawFun, currentLocation.x, currentLocation.y, props.width, props.height) {
        val image = loadedImage ?: return@useEffect
        val node = canvasRef.current ?: return@useEffect
        val renderContext = node.getContext(CanvasRenderingContext2D.ID) ?: return@useEffect
        val viewportData = getViewportData() ?: return@useEffect
        renderContext.clearRect(0.0, 0.0, node.width.toDouble(), node.height.toDouble())
        renderContext.drawImage(
            image = image,
            sx = viewportData.viewportX,
            sy = viewportData.viewportY,
            sw = viewportData.viewportImageWidth,
            sh = viewportData.viewportImageHeight,
            dx = 0.0,
            dy = 0.0,
            dw = node.width.toDouble(),
            dh = node.height.toDouble()
        )
        props.drawFun?.invoke(renderContext, viewportData)
    }
    canvas {
        attrs.ref = canvasRef
        attrs.id = "mapCanvas"
        attrs.width = props.width
        attrs.height = props.height
        attrs.onClick = { event ->
            event.preventDefault()
            event.stopPropagation()
        }
        attrs.onPointerDown = { event ->
            if (event.isMoveEvent()) {
                dragStart = Vector2(event.clientX, event.clientY)
                event.preventDefault()
                event.stopPropagation()
            }
            event.toMapClickData()?.let {
                props.onPointerDown?.invoke(event, it)
            }
        }
        attrs.onContextMenu = { event ->
            if (props.allowMoving) {
                event.preventDefault()
            }
        }
        attrs.onPointerUp = { event ->
            if (event.isMoveEvent()) {
                dragStart = null
                event.preventDefault()
                event.stopPropagation()
            }
            event.toMapClickData()?.let {
                props.onPointerUp?.invoke(event, it)
            }
        }
        attrs.onPointerMove = { event ->
            val mapClickData = event.toMapClickData()
            val viewportData = getViewportData()
            if (mapClickData != null && viewportData != null) {
                dragStart?.let {
                    if (props.allowMoving) {
                        val moveVector = Vector2(it.x - event.clientX, it.y - event.clientY)
                        val mapMovement = viewportData.convertViewportToMap(moveVector)
                        currentLocation += mapMovement
                    }
                    dragStart = Vector2(event.clientX, event.clientY)
                }
                props.onPointerMove?.invoke(event, mapClickData)
            }
        }
    }
}

val MapHoverComponent = fc<MapHoverComponentProps>("MapHover") { props ->
    Popup {
        attrs.trigger = buildElement {
            Icon {
                attrs.name = "map"
                attrs.link = true
            }
        }

        PopupHeader {
            +"Map"
        }
        PopupContent {
            MapCanvasComponent {
                attrs.initialLocation = Vector2(props.locationX, props.locationY)
                attrs.width = 256.0
                attrs.height = 256.0
                attrs.initialZoomFactor = 6.0
                attrs.drawFun = { renderContext, viewportData ->
                    renderContext.beginPath()

                    val middle = MapCoordinates.convertToRatios(Vector2(props.locationX, props.locationY))
                    val viewportMiddle = viewportData.convertToViewportCoordinates(middle.x, middle.y)
                    renderContext.arc(
                        x = viewportMiddle.x,
                        y = viewportMiddle.y,
                        radius = 6.0,
                        startAngle = 0.0,
                        endAngle = 2 * PI
                    )
                    renderContext.fillStyle = "#64dd17"
                    renderContext.fill()
                    renderContext.lineWidth = 1.0
                    renderContext.strokeStyle = "#000000"
                    renderContext.stroke()
                }
            }
        }
    }
}