package com.perpheads.bans.components.bans

import com.perpheads.bans.*
import com.perpheads.bans.components.*
import com.perpheads.bans.components.comments.commentList
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.BanResponse
import com.perpheads.bans.util.collapsingTableRow
import com.perpheads.bans.util.format
import com.perpheads.bans.util.linkedCollapsingTableRow
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*
import react.router.useNavigate

external interface BanDetailProps : Props {
    var ban: BanResponse
    var expanded: Boolean
    var account: AccountInfoResponse?
    var updateBan: (BanResponse, BanResponse) -> Unit
}

val BanDetailComponent = fc<BanDetailProps>("BanDetails") { props ->
    val t = useTranslation()
    val navigate = useNavigate()

    val ban = props.ban
    var wasShown by useState(false)
    val errorState = useState(false)
    val (loading, setLoading) = useState(false)

    if (!props.expanded && !wasShown) return@fc
    if (props.expanded && !wasShown) {
        wasShown = true //This is _NOT_ useless
    }

    errorModal(errorState = errorState)
    collapsibleTableRow(expanded = props.expanded, colSpan = "4") {
        loadingSpinner { isLoading = loading }
        table("ui celled compact table") {
            tbody {
                linkedCollapsingTableRow(navigate, t("headers.player"), ClientRoutes.playerProfile(ban.communityId)) {
                    +ban.playerName
                }
                collapsingTableRow(t("headers.reason")) {
                    linkify(ban.reason)
                }
                linkedCollapsingTableRow(navigate, t("headers.steamid"), ClientRoutes.banSearch(ban.steamId())) {
                    +ban.steamId()
                }
                val banAdmin = ban.admin
                if (banAdmin != null) {
                    linkedCollapsingTableRow(navigate, t("headers.admin"), ClientRoutes.banAdmin(banAdmin.steamId())) {
                        +banAdmin.name
                    }
                }
                val endDate = ban.endDate
                if (endDate != null) {
                    collapsingTableRow(t("headers.expiry_date")) { +endDate.format() }
                }
                val unbanReason = ban.unbanReason
                if (unbanReason != null) {
                    collapsingTableRow(t("headers.unban_reason")) { +unbanReason }
                }
                val unbanDate = ban.unbanDate
                if (unbanDate != null) {
                    collapsingTableRow(t("headers.unban_date")) { +unbanDate.format() }
                }
                val unbanAdmin = ban.unbanAdmin
                if (unbanAdmin != null) {
                    linkedCollapsingTableRow(
                        navigate,
                        t("headers.unban_admin"),
                        ClientRoutes.banAdmin(unbanAdmin.steamId())
                    ) { +unbanAdmin.name }
                }
                val account = props.account ?: return@tbody
                if (!account.canBan) return@tbody
                tr {
                    td("collapsing") { +t("headers.actions") }
                    td {
                        if (ban.deleted) {
                            actionButtonWithConfirmation {
                                this.text = t("actions.undelete")
                                this.icon = "undo"
                                this.color = "green"
                                this.modalMessage = t("actions.undelete.ban.message")
                                this.modalDescription = t("actions.undelete.ban.description")
                                this.modalIcon = "warning sign"
                                this.onConfirmed = {
                                    ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                        ApiClient.undeleteBan(props.ban.banId)
                                        props.updateBan(props.ban, props.ban.copy(deleted = false))
                                    }
                                }
                            }
                            return@td
                        }
                        actionButton(navigate, "Edit", "edit", "blue", ClientRoutes.editBan(props.ban.banId))
                        if (ban.unbanDate == null && !ban.isExpired()) {
                            actionButtonWithInput {
                                this.text = t("actions.unban")
                                this.icon = "minus square"
                                this.color = "green"
                                this.modalMessage = t("actions.unban.message")
                                this.modalDescription = t("actions.unban.description")
                                this.inputRows = 6
                                this.onInput = { reason ->
                                    ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                        val newBan = ApiClient.unban(props.ban.banId, reason)
                                        props.updateBan(props.ban, newBan)
                                    }
                                }
                            }
                        } else if (!ban.isExpired()) {
                            actionButtonWithConfirmation {
                                this.text = t("actions.reban")
                                this.icon = "plus square"
                                this.color = "orange"
                                this.modalMessage = t("actions.reban.message")
                                this.modalDescription = t("actions.reban.description")
                                this.modalIcon = "warning sign"
                                this.onConfirmed = {
                                    ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                        val newBan = ApiClient.reban(props.ban.banId)
                                        props.updateBan(props.ban, newBan)
                                    }
                                }
                            }
                        }
                        actionButtonWithConfirmation {
                            this.text = t("actions.delete")
                            this.icon = "trash"
                            this.color = "red"
                            this.modalMessage = t("actions.delete.ban.message")
                            this.modalDescription = t("actions.delete.ban.description")
                            this.modalIcon = "warning sign"
                            this.onConfirmed = {
                                ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                                    ApiClient.deleteBan(props.ban.banId)
                                    props.updateBan(props.ban, props.ban.copy(deleted = true))
                                }
                            }
                        }
                    }
                }
            }
        }
        if (props.account?.canBan != true) return@collapsibleTableRow
        commentList {
            this.account = props.account
            this.showEditor = true
            this.loadComments = {
                ApiClient.getBanComments(ban.banId)
            }
            this.createComment = { text ->
                ApiClient.postBanComment(ban.banId, text)
            }
        }
    }
}

fun RBuilder.banDetails(handler: BanDetailProps.() -> Unit) = child(BanDetailComponent) {
    attrs { handler() }
}