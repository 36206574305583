package com.perpheads.bans.components.screenshots

import com.perpheads.bans.ServerRoutes
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.responses.ScreenshotsResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.playerLink
import com.perpheads.bans.util.useTranslation
import react.Props
import react.RBuilder
import react.dom.*
import react.fc
import react.router.useNavigate
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt

external interface ScreenshotTableProps : Props {
    var page: Int
    var screenshots: ScreenshotsResponse
    var onPageChanged: (Int) -> Unit
}

val ScreenshotTableComponent = fc<ScreenshotTableProps>("ScreenshotTableComponent") { props ->
    val t = useTranslation()
    val navigate = useNavigate()
    val totalPages =
        max(ceil(props.screenshots.count / ServerRoutes.screenshotEntriesPerPage.toDouble()).roundToInt(), 1)

    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.date") }
                th { +t("headers.player") }
                th { +t("headers.admin") }
                th { +t("headers.screenshot") }
            }
        }
        tbody {
            for (screenshot in props.screenshots.screenshots) {
                tr {
                    attrs.key = screenshot.screenshotId.toString()
                    td("collapsing") { +screenshot.date.format() }
                    td("collapsing") {
                        playerLink(
                            "${screenshot.player.name} (${screenshot.player.steamId()})",
                            screenshot.player.communityId,
                            navigate
                        )
                    }
                    td("collapsing") { +(screenshot.admin?.name ?: "CONSOLE") }
                    td("collapsing") {
                        val screenshotLink = ServerRoutes.screenshotRoute(screenshot.screenshotId)
                        a(href = screenshotLink, target = "_blank") {
                            img(classes = "ui large image", src = screenshotLink) {

                            }
                        }
                    }
                }
            }
        }
        tfoot {
            tr {
                th {
                    attrs.colSpan = "4"
                    pagination {
                        this.defaultActivePage = props.page
                        this.totalPages = totalPages
                        this.onPageChanged = props.onPageChanged
                    }
                }
            }
        }
    }
}

fun RBuilder.screenshotTable(page: Int, response: ScreenshotsResponse, onPageChanged: (Int) -> Unit) =
    child(ScreenshotTableComponent) {
        attrs {
            this.screenshots = response
            this.page = page
            this.onPageChanged = onPageChanged
        }
    }