package com.perpheads.bans.components.bans

import com.perpheads.bans.util.format
import com.perpheads.bans.util.getDurationString
import com.perpheads.bans.responses.BanResponse
import com.perpheads.bans.util.useTranslation
import kotlinx.css.Cursor
import kotlinx.css.cursor
import react.Props
import react.RBuilder
import react.dom.*
import react.dom.events.MouseEventHandler
import react.fc
import react.useState
import styled.css
import styled.styledTr


external interface BanHeaderProps : Props {
    var ban: BanResponse
    var onClick: MouseEventHandler<*>
}

val BanHeaderComponent = fc<BanHeaderProps>("BanHeader") { props ->
    val t = useTranslation()
    var hovered by useState(false)

    val ban = props.ban
    val admin = props.ban.admin?.name ?: "CONSOLE"
    val durationClass = if (ban.isExpired() || ban.isUnbanned()) {
        "positive"
    } else if (ban.isPermanent()) {
        "error"
    } else {
        "warning"
    }


    styledTr {
        attrs.onClick = props.onClick
        css {
            cursor = Cursor.pointer
            if (props.ban.deleted) {
                classes += "negative"
            }
            if (hovered) {
                classes += "active"
            }
        }

        attrs.onMouseEnter = {
            hovered = true
        }
        attrs.onMouseLeave = {
            hovered = false
        }

        td {
            +ban.startDate.format()
        }
        td {
            +ban.playerName
        }
        td {
            +admin
        }
        td(durationClass) {
            +ban.getDurationString(t)
        }
    }
}

fun RBuilder.banHeader(handler: BanHeaderProps.() -> Unit) = child(BanHeaderComponent) { attrs(handler) }