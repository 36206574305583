@file:OptIn(ExperimentalTime::class)

package com.perpheads.bans.util

import com.perpheads.bans.responses.BanResponse
import com.perpheads.bans.responses.BlacklistResponse
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime


data class ChronoUnitCount(val unit: TimeUnit, val count: Long) {
    fun translate(t: TranslationFunction): String {
        return t.pluralize(unit.translationKey, count.toInt())
    }
}

enum class TimeUnit(val duration: Duration, val translationKey: String) {
    SECONDS(1.seconds, "duration.second"),
    MINUTES(1.minutes, "duration.minute"),
    HOURS(1.hours, "duration.hour"),
    DAYS(1.days, "duration.day"),
    WEEKS(7.days, "duration.week"),
    MONTHS(30.days, "duration.month"),
    YEARS(365.days, "duration.year");

    fun amountInDuration(duration: Duration): ChronoUnitCount {
        return ChronoUnitCount(this, (duration / this.duration).toLong())
    }
}

fun Duration.coarsestUnit(): ChronoUnitCount {
    return when {
        this >= TimeUnit.YEARS.duration -> TimeUnit.YEARS.amountInDuration(this)
        this >= TimeUnit.MONTHS.duration -> TimeUnit.MONTHS.amountInDuration(this)
        this >= TimeUnit.WEEKS.duration -> TimeUnit.WEEKS.amountInDuration(this)
        this >= TimeUnit.DAYS.duration -> TimeUnit.DAYS.amountInDuration(this)
        this >= TimeUnit.HOURS.duration -> TimeUnit.HOURS.amountInDuration(this)
        this >= TimeUnit.MINUTES.duration -> TimeUnit.MINUTES.amountInDuration(this)
        else -> TimeUnit.SECONDS.amountInDuration(this)
    }
}


fun Duration?.toDurationString(t: TranslationFunction): String {
    if (this == null) {
        return t("duration.permanent").toCapitalized()
    }
    val coarsestUnit = coarsestUnit()
    return "${coarsestUnit.count} ${coarsestUnit.translate(t)}"
}

fun BlacklistResponse.getDurationString(t: TranslationFunction): String {
    val durationStr = duration.toDurationString(t)
    return if (unblacklistReason != null) {
        t.withKeys("blacklists.duration_unblacklisted", mapOf("duration" to durationStr))
    } else if (isExpired()) {
        t.withKeys("blacklists.duration_expired", mapOf("duration" to durationStr))
    } else durationStr
}

fun BanResponse.getDurationString(t: TranslationFunction): String {
    val durationStr = duration.toDurationString(t)
    return if (unbanReason != null) {
        t.withKeys("bans.duration_unbanned", mapOf("duration" to durationStr))
    } else if (isExpired()) {
        t.withKeys("bans.duration_expired", mapOf("duration" to durationStr))
    } else durationStr
}