package com.perpheads.bans

enum class SemanticTheme(val stylesheet: String, val dark: Boolean) {
    Yeti("semantic.yeti.css", false),
    Darkly("semantic.darkly.css", true),
    Sandstone("semantic.sandstone.css", false),
    Simplex("semantic.simplex.css", false),
    Slate("semantic.slate.css", true),
    Solar("semantic.solar.css", true),
    Superhero("semantic.superhero.css", true),
    United("semantic.united.css", false),
    Semantic("semantic.css", false);

    companion object {
        fun getByName(name: String): SemanticTheme {
            return kotlin.runCatching { valueOf(name) }.getOrNull() ?: Yeti
        }
    }
}