package com.perpheads.bans.components.profile

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.ServerRoutes
import com.perpheads.bans.components.actionButtonWithConfirmation
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.profileIdToSteamId
import com.perpheads.bans.responses.AltAccountResponse
import com.perpheads.bans.responses.PlayerResponse
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.semanticIcon
import react.Props
import react.RBuilder
import react.dom.*
import react.fc
import react.router.useNavigate
import react.useState

external interface AltAccountsTableProps: Props {
    var communityId: Long
    var altAccounts: List<AltAccountResponse>
    var canDelete: Boolean
    var onAccountDeleted: (AltAccountResponse) -> Unit
}

val AltAccountsTable = fc<AltAccountsTableProps>("AltAccountsTable") { props ->
    val t = useTranslation()
    val navigate = useNavigate()
    val errorState = useState(false)

    errorModal(errorState = errorState)
    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.player") }
                th { +t("headers.steamid") }
                th { +t("headers.allowed") }
                if (props.canDelete) {
                    th { +t("headers.actions") }
                }
            }
        }
        tbody {
            for (altAccount in props.altAccounts) {
                val steamCommunityHref = "https://steamcommunity.com/profiles/${altAccount.communityId}"
                tr {
                    td {
                        a(href = ClientRoutes.playerProfile(altAccount.communityId), target = "_blank") {
                            +(altAccount.name ?: "")
                        }
                    }
                    td {
                        a(href = steamCommunityHref, target = "_blank") {
                            +(altAccount.communityId.profileIdToSteamId() ?: "")
                        }
                    }
                    td {
                        if (altAccount.allowed) {
                            semanticIcon("check", color = "green")
                        } else {
                            semanticIcon("ban", color = "red")
                        }
                    }
                    if (props.canDelete) {
                        td {
                            actionButtonWithConfirmation {
                                this.text = t("actions.delete")
                                this.icon = "trash"
                                this.color = "red"
                                this.modalMessage = t("actions.delete.alt.message")
                                this.modalDescription = t("actions.delete.alt.description")
                                this.modalIcon = "warning sign"
                                this.onConfirmed = {
                                    ApiClient.launchWithErrorHandler(navigate, errorState) {
                                        ApiClient.deleteAlt(props.communityId.toString(), altAccount.communityId)
                                        props.onAccountDeleted(altAccount)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.altAccountsTable(handler: AltAccountsTableProps.() -> Unit) = child(AltAccountsTable) {
    attrs(handler)
}

