package com.perpheads.bans.util

import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.getFallbackLanguage
import com.perpheads.bans.getLanguage
import com.perpheads.bans.getLanguageJsonPath
import com.perpheads.bans.wrappers.axiosGet
import js.promise.Promise
import js.promise.promise
import kotlinx.coroutines.*
import react.*

class TranslationLanguage(
    val translations: Map<String, String>
) {
    fun translate(t: String): String? = translations[t]
}

class TranslationData(
    private val primary: TranslationLanguage,
    private val fallback: TranslationLanguage?
) {
    fun translate(str: String): String? = primary.translate(str) ?: fallback?.translate(str)
}

@OptIn(DelicateCoroutinesApi::class)
private fun translationDataPromise(): Deferred<TranslationData> = GlobalScope.async {
    val primaryLanguagePath = getLanguageJsonPath()
    val fallbackLanguage = getFallbackLanguage()

    val primaryTranslations = axiosGet<Map<String, String>>(primaryLanguagePath)
    val secondaryTranslations = fallbackLanguage?.let {
        axiosGet<Map<String, String>>("/assets/locales/$fallbackLanguage/translation.json")
    }
    return@async TranslationData(
        TranslationLanguage(primaryTranslations),
        secondaryTranslations?.let(::TranslationLanguage)
    )
}

val TranslationContext = createContext<TranslationData>()

class TranslationFunction(private val translationData: TranslationData?) {
    companion object {
        val EMPTY = TranslationFunction(TranslationData(TranslationLanguage(emptyMap()), null))
    }

    operator fun invoke(translation: String): String {
        return translationData?.translate(translation) ?: translation
    }

    fun withKeys(translation: String, keys: Map<String, Any>): String {
        var returnedString = translationData?.translate(translation) ?: return translation
        for ((entry, value) in keys) {
            returnedString = returnedString.replace("{{$entry}}", value.toString())
        }
        return returnedString
    }

    fun pluralize(translation: String, count: Int = 2): String {
        if (count == 1) return invoke(translation)
        return translationData?.translate("${translation}_plural") ?: invoke(translation)
    }
}


val TranslationSuspense = fc<PropsWithChildren>("TranslationSuspense") { props ->
    var loadedTranslations by useState<TranslationData>()
    useEffectOnce {
        val deferred = translationDataPromise()
        deferred.invokeOnCompletion {
            @OptIn(ExperimentalCoroutinesApi::class)
            loadedTranslations = deferred.getCompleted()
        }
        cleanup {
            deferred.cancel()
        }
    }

    if (loadedTranslations != null) {
        TranslationContext.Provider {
            attrs { this.value = loadedTranslations }
            props.children()
        }
    } else {
        loadingSpinner {
            translate = false
            isLoading = true
        }
    }
}

fun useTranslation(): TranslationFunction {
    val translationData = useContext(TranslationContext) ?: return TranslationFunction.EMPTY
    return TranslationFunction(translationData)
}

fun String.toCapitalized(): String {
    return replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
}