package com.perpheads.bans.components.blacklists

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.BlacklistResponse
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*

external interface BlacklistTableProps : PropsWithChildren {
    var account: AccountInfoResponse?
    var blacklists: List<BlacklistResponse>
    var updateBlacklist: (BlacklistResponse, BlacklistResponse) -> Unit
}

val BlacklistTableComponent = fc<BlacklistTableProps>("BlacklistTable") { props ->
    var expandedBlacklist by useState<Int>()
    val t = useTranslation()

    table("ui celled table unstackable") {
        thead {
            tr {
                th { +t("headers.date") }
                th { +t("headers.type") }
                th { +t("headers.player") }
                th { +t("headers.admin") }
                th { +t("headers.duration") }
            }
        }
        tbody {
            for (b in props.blacklists) {
                blacklist {
                    this.account = props.account
                    this.key = b.blacklistId.toString()
                    this.blacklist = b
                    this.expanded = expandedBlacklist == b.blacklistId
                    this.onBlacklistClicked = {
                        expandedBlacklist = if (expanded) {
                            null
                        } else {
                            b.blacklistId
                        }
                    }
                    this.updateBlacklist = props.updateBlacklist
                }
            }
        }
        props.children()
    }
}

fun RBuilder.blacklistTable(handler: RElementBuilder<BlacklistTableProps>.() -> Unit) = BlacklistTableComponent {
    handler.invoke(this)
}