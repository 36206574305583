package com.perpheads.bans.components.logs

import com.perpheads.bans.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.BlacklistLogResponse
import com.perpheads.bans.responses.ActionLogsSearchResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.playerLink
import com.perpheads.bans.util.pushMouseEventHandler
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*
import react.router.useNavigate
import react.router.useLocation
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt

external interface BlacklistLogPageProps : Props {

}

val BlacklistLogPageComponent = fc<BlacklistLogPageProps>("BlacklistLogPage") { props ->
    val t = useTranslation()
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val navigate = useNavigate()
    val page = parameters["page"]?.toIntOrNull() ?: 1

    var logs by useState(ActionLogsSearchResponse<BlacklistLogResponse>(0, emptyList()))
    val totalPages = max(ceil(logs.count / ServerRoutes.entriesPerPage.toDouble()).roundToInt(), 1)
    val (loading, setLoading) = useState(true)
    val errorState = useState(false)

    fun changeUrl(
        newPage: Int,
    ) {
        val params = Parameters.build {
            if (newPage != 1) {
                set("page", newPage.toString())
            }
        }
        navigate(ClientRoutes.blacklistLogs + "?${params}")
    }

    useEffect(location) {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            logs = ApiClient.getBlacklistLogs(page)
        }
    }

    errorModal(errorState = errorState)
    requiredUserPage(canViewPage = { it.canAssignRanks }) { account ->
        loadingSpinner { isLoading = loading }

        p { +t.withKeys("logs.total_count", mapOf("count" to logs.count)) }
        actionLogTable<BlacklistLogResponse> {
            attrs {
                this.account = account
                this.headerString = t("headers.blacklist")
                this.logs = logs.logs
                this.descriptionBuilder = { builder, props ->
                    builder.td {
                        a {
                            val href = ClientRoutes.blacklistSearch(props.log.entityId)
                            attrs.href = href
                            attrs.onClick = navigate.pushMouseEventHandler(href)
                            +"#${props.log.entityId}"
                        }
                        +" ${props.log.type} for "
                        playerLink(props.log.playerName, props.log.communityId.toString(), navigate)
                        +" starting at ${props.log.startDate.format()}"
                    }
                }
            }

            tfoot {
                tr {
                    th {
                        attrs.colSpan = "4"
                        pagination {
                            this.defaultActivePage = page
                            this.totalPages = totalPages
                            this.onPageChanged = {
                                changeUrl(newPage = it)
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.blacklistLogPage() = child(BlacklistLogPageComponent) {
    attrs {

    }
}