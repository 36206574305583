package com.perpheads.bans.components

import com.perpheads.bans.util.pushMouseEventHandler
import com.perpheads.bans.wrappers.semantic.semanticButton
import com.perpheads.bans.wrappers.semantic.semanticIcon
import react.Props
import react.RBuilder
import react.dom.a
import react.dom.events.MouseEventHandler
import react.dom.i
import react.dom.onClick
import react.fc
import react.router.NavigateFunction
import react.useState

external interface ActionButtonProps : Props {
    var icon: String
    var color: String
    var text: String
    var href: String?
    var onClick: MouseEventHandler<*>
}

val ActionButtonComponent = fc<ActionButtonProps>("ActionButton") { props ->
    a(classes = "ui labeled icon button ${props.color}") {
        attrs.onClick = props.onClick
        props.href?.let { attrs.href = it }
        i("${props.icon} icon") {}
        +props.text
    }
}

fun RBuilder.actionButton(
    navigate: NavigateFunction,
    text: String,
    icon: String,
    color: String,
    href: String? = null,
    onClick: MouseEventHandler<*> = { }
) = child(ActionButtonComponent) {
    attrs {
        this.icon = icon
        this.text = text
        this.color = color
        this.href = href
        if (href != null) {
            this.onClick = navigate.pushMouseEventHandler(href)
        } else {
            this.onClick = onClick
        }
    }
}

external interface ActionButtonWithConfirmationProps : Props {
    var icon: String
    var color: String
    var text: String
    var modalIcon: String
    var modalMessage: String
    var modalDescription: String
    var onConfirmed: () -> Unit
}

val ActionButtonWithConfirmationComponent =
    fc<ActionButtonWithConfirmationProps>("ActionButtonWithConfirmation") { props ->
        var modalShown by useState(false)

        confirmationModal {
            this.open = modalShown
            this.title = props.modalMessage
            this.description = props.modalDescription
            this.icon = props.modalIcon
            this.onDecision = { confirmed ->
                modalShown = false
                if (confirmed) {
                    props.onConfirmed()
                }
            }
        }

        semanticButton {
            attrs {
                color = props.color
                labelPosition = "left"
                icon = true
                onClick = {
                    modalShown = true
                }
            }
            semanticIcon(props.icon)
            +props.text
        }
    }

fun RBuilder.actionButtonWithConfirmation(handler: ActionButtonWithConfirmationProps.() -> Unit) =
    child(ActionButtonWithConfirmationComponent) {
        attrs(handler)
    }


external interface ActionButtonWithInputComponentProps : Props {
    var icon: String
    var color: String
    var text: String
    var modalMessage: String
    var modalDescription: String
    var inputRows: Int?
    var onInput: (String) -> Unit
}

val ActionButtonWithInputComponent =
    fc<ActionButtonWithInputComponentProps>("ActionButtonWithInput") { props ->
        var modalShown by useState(false)

        inputModal {
            this.open = modalShown
            this.title = props.modalMessage
            this.description = props.modalDescription
            this.inputRows = props.inputRows
            this.onInput = { text ->
                modalShown = false
                if (text != null) {
                    props.onInput(text)
                }
            }
        }

        semanticButton {
            attrs {
                color = props.color
                labelPosition = "left"
                icon = true
                onClick = {
                    modalShown = true
                }
            }
            semanticIcon(props.icon)
            +props.text
        }
    }

fun RBuilder.actionButtonWithInput(handler: ActionButtonWithInputComponentProps.() -> Unit) =
    child(ActionButtonWithInputComponent) {
        attrs(handler)
    }