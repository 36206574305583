package com.perpheads.bans.responses

import kotlinx.serialization.Serializable

@Serializable
data class ProfileResponse(
    val name: String,
    val rank: String,
    val communityId: String,
    val forumName: String?,
    val forumLink: String?,
    val discordId: String?
)