package com.perpheads.bans.components.page

import com.perpheads.bans.*
import com.perpheads.bans.ScamPage.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.PlayerSearchResponse
import com.perpheads.bans.util.pushMouseEventHandler
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import com.perpheads.bans.wrappers.useMediaQuery
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.html.InputType
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.*
import react.router.NavigateFunction
import react.router.useNavigate
import styled.css
import styled.inlineStyles
import styled.styledDiv
import styled.styledI
import web.cssom.Length
import web.cssom.atrule.minWidth

external interface NavBarProps : Props {
    var account: AccountInfoResponse?
    var onSearched: (String) -> Unit
    var page: ScamPage?
}

private fun RElementBuilder<*>.navBarMenuItem(content: String, route: String, active: Boolean, navigate: NavigateFunction) {
    semanticMenuItem {
        attrs.key = route
        attrs.content = content
        attrs.active = active
        attrs.href = route
        attrs.onClick = navigate.pushMouseEventHandler(route)
    }
}

private sealed interface SearchAction {
    data class StartSearch(val text: String) : SearchAction
    data class SearchLoaded(val results: List<PlayerSearchResponse>) : SearchAction
    object CleanSearch : SearchAction
}

data class SearchReducerState(
    val loading: Boolean = false,
    val results: List<PlayerSearchResponse> = emptyList(),
    val value: String = ""
)

private fun searchReducer(state: SearchReducerState, action: SearchAction): SearchReducerState {
    return when (action) {
        is SearchAction.StartSearch -> {
            state.copy(loading = true, value = action.text)
        }
        is SearchAction.SearchLoaded -> {
            state.copy(loading = false, results = action.results)
        }
        is SearchAction.CleanSearch -> {
            state.copy(loading = false, results = emptyList(), value = "")
        }
    }
}

data class NavBarEntry(
    val content: String,
    val route: String,
    val page: ScamPage,
)

val NavBarComponent = fc<NavBarProps>("NavBar") { props ->
    val navigate = useNavigate()
    val t = useTranslation()
    val (searchState, dispatch) = useReducer(::searchReducer, SearchReducerState())
    var themeChooserOpen by useState(false)
    val errorState = useState(false)
    var dropDownState by useState(false)

    val isLarge = useMediaQuery(minWidth("1920px".unsafeCast<Length>()))
    val isMediumLarge = useMediaQuery(minWidth("1280px".unsafeCast<Length>()))
    val maxShownItems = when {
        isLarge -> 6
        isMediumLarge -> 4
        else -> 0
    }

    val timeoutRef = useRef<Job>()
    val handleSearch = useCallback { text: String ->
        timeoutRef.current?.cancel()
        dispatch(SearchAction.StartSearch(text))
        timeoutRef.current = ApiClient.launchWithErrorHandler(navigate, errorState) {
            delay(100)
            if (text.isBlank()) {
                dispatch(SearchAction.CleanSearch)
                return@launchWithErrorHandler
            }
            val newSuggestions = if (text.isNotBlank()) {
                ApiClient.getPlayerSuggestions(text.trim())
            } else {
                emptyList()
            }
            if (isActive) {
                dispatch(SearchAction.SearchLoaded(newSuggestions))
            }
        }
    }
    var searchActive by useState(false)

    useEffectOnce {
        cleanup {
            timeoutRef.current?.cancel()
        }
    }

    val navBarEntries = buildList {
        add(NavBarEntry(t("navigation.bans"), ClientRoutes.bans, BANS))
        add(NavBarEntry(t("navigation.warnings"), ClientRoutes.warnings, WARNINGS))
        add(NavBarEntry(t("navigation.blacklists"), ClientRoutes.blacklists, BLACKLIST))
        if (props.account?.canViewLogs == true) {
            add(NavBarEntry(t("navigation.logs"), ClientRoutes.logs, LOGS))
        }
        if (props.account?.canBan == true) {
            add(NavBarEntry(t("navigation.reports"), ClientRoutes.reports, REPORTS))
            add(NavBarEntry(t("navigation.demos"), ClientRoutes.demos, DEMOS))
        }
        if (props.account?.canViewScamVerboseLogs == true) {
            add(NavBarEntry(t("navigation.report_logs"), ClientRoutes.reportLogs, REPORT_LOGS))
            add(NavBarEntry(t("navigation.screenshots"), ClientRoutes.screenshots, SCREENSHOTS))
        }
        if (props.account?.hasPermission(SCAMPermission.RestrictedDebug) == true) {
            add(NavBarEntry(t("navigation.statistics"), ClientRoutes.statistics, STATISTICS))
        }
    }
    val shownNavItems = navBarEntries.take(maxShownItems)
    val hiddenNavItems = navBarEntries.drop(maxShownItems)

    errorModal(errorState = errorState)
    semanticMenu(pointing = true) {
        for (item in shownNavItems) {
            navBarMenuItem(item.content, item.route, props.page == item.page, navigate)
        }
        hiddenNavItems.firstOrNull { it.page == props.page }?.let {
            navBarMenuItem(it.content, it.route, true, navigate)
        }

        if (hiddenNavItems.any { it.page != props.page }) {
            semanticMenuItem {
                attrs.onClick = { _ ->
                    dropDownState = !dropDownState
                    println("DropDownStateA: $dropDownState")
                }
                semanticDropdown {
                    attrs.open = dropDownState
                    attrs.onClick = { _, _ ->
                        dropDownState = !dropDownState
                        println("DropDownStateB: $dropDownState")
                    }
                    attrs.onClose = { e, _ ->
                        dropDownState = false
                    }
                    attrs.icon = "bars"
                    attrs.className = "icon large"
                    semanticDropdownMenu {
                        for (item in hiddenNavItems) {
                            navBarMenuItem(item.content, item.route, props.page == item.page, navigate)
                        }
                    }
                }
            }
        }

        semanticMenuMenu(position = "right") {
            if (props.account == null) {
                semanticMenuItem {
                    attrs.content = t("navigation.login")
                    attrs.href = ServerRoutes.authRoute
                }
            } else {
                navBarMenuItem(t("navigation.account"), ClientRoutes.account, props.page == ACCOUNT, navigate)
            }
            semanticMenuItem {
                semanticIcon("settings")
                attrs.onClick = {
                    themeChooserOpen = true
                }
                themeChooser {
                    this.open = themeChooserOpen
                    this.closeModal = { themeChooserOpen = false }
                }
            }

            styledDiv {
                inlineStyles {
                    margin = Margin(8.px)
                }
                div("ui search") {
                    styledDiv {
                        css {
                            classes += "ui icon input search"
                            if (searchState.loading) {
                                classes += "loading"
                            }
                        }
                        input(InputType.text, classes = "prompt search") {
                            attrs.placeholder = t("navigation.search_placeholder")
                            attrs.onFocus = {
                                searchActive = true
                            }
                            attrs.onBlur = {
                                searchActive = false
                            }
                            attrs.value = searchState.value
                            attrs.onChange = {
                                val text = (it.target as HTMLInputElement).value.trimStart()
                                handleSearch(text)
                            }
                            attrs.onKeyPress = {
                                if (it.key == "Enter") {
                                    (it.target as HTMLInputElement).blur()
                                    props.onSearched(searchState.value.trim())
                                }
                            }
                        }
                        i("search link icon") {
                            attrs.onClick = {
                                props.onSearched(searchState.value.trim())
                            }
                        }
                    }

                    val showSearch =
                        if (searchActive && searchState.value.isNotBlank() && (!searchState.loading || searchState.results.isNotEmpty())) "visible" else ""
                    div("results transition $showSearch") {
                        if (searchState.results.isEmpty() && !searchState.loading) {
                            div("message empty") {
                                div("header") { +t("navigation.search_no_results_header") }
                                div("description") { +t("navigation.search_no_results_description") }
                            }
                        }
                        for (r in searchState.results) {
                            styledDiv {
                                attrs.onMouseDown = {
                                    handleSearch(r.name)
                                    props.onSearched(r.name.trim())
                                }
                                css {
                                    classes += "result"
                                }
                                inlineStyles {
                                    display = Display.flex
                                    alignItems = Align.center
                                    width = 100.pct
                                }
                                styledDiv {
                                    css {
                                        classes += "title"
                                    }
                                    +r.name
                                }
                                styledI {
                                    css {
                                        classes += "id card icon link"
                                    }
                                    inlineStyles {
                                        marginLeft = LinearDimension.auto
                                    }
                                    attrs.onMouseDown = {
                                        it.stopPropagation()
                                        navigate(ClientRoutes.playerProfile(r.communityId))
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


fun RBuilder.navBar(handler: NavBarProps.() -> Unit) = child(NavBarComponent) {
    attrs { handler() }
}