package com.perpheads.bans.components.statistics

import com.perpheads.bans.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.util.loadJsFile
import com.perpheads.bans.wrappers.PlotlyPlotSettings
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.PlotlyMultiPlotSettings
import com.perpheads.bans.wrappers.semantic.semanticButton
import js.objects.jso
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import react.*
import react.dom.div
import react.dom.p
import react.router.useNavigate

external interface StatisticsPageProps : Props {

}

private enum class StatisticsInterval(val queryStr: String) {
    DAILY("d"), WEEKLY("w"), MONTHLY("M"), HOURLY("h")
}

private fun convertToBarChart(data: List<Pair<LocalDateTime, List<Pair<String, Int>>>>): PlotlyMultiPlotSettings {
    class BarChartEntry(val date: LocalDateTime, val country: String, val count: Int)
    val separateCharts = data.flatMap { (date, countryCounts) -> countryCounts.map { BarChartEntry(date, it.first, it.second) } }
        .groupBy { it.country }

    return separateCharts.map { (country, countryData) ->
        jso<PlotlyPlotSettings> {
            x = countryData.map { it.date.toString() }.toTypedArray()
            y = countryData.map { it.count }.toTypedArray()
            mode = "lines+markers"
            type = "bar"
            name = country
            hovertemplate = undefined
        }
    }.toTypedArray()
}

private fun convertToLineChart(data: List<Pair<LocalDateTime, Int>>): PlotlyPlotSettings {
    return jso {
        x = data.map { it.first.toString() }.toTypedArray()
        y = data.map { it.second }.toTypedArray()
        mode = "lines+markers"
        type = "scatter"
        hovertemplate = undefined
    }
}

val StatisticsPageComponent = fc<StatisticsPageProps>("StatisticsPage") {
    val t = useTranslation()
    val navigate = useNavigate()

    var interval by useState(StatisticsInterval.DAILY)

    var plotlyLoaded by useState(false)
    val (loading, setLoading) = useState(false)
    val errorState = useState(false)

    var totalConnectionsStatistics by useState(convertToBarChart(emptyList()))
    var uniqueConnectionsStatistics by useState(convertToBarChart(emptyList()))
    var newPlayersStatistics by useState(convertToBarChart(emptyList()))
    var playerCountStatistics by useState(convertToLineChart(emptyList()))

    fun loadPlotData() {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            val statistics = ApiClient.getStatistics(interval.queryStr)
            totalConnectionsStatistics = convertToBarChart(statistics.totalConnections)
            uniqueConnectionsStatistics = convertToBarChart(statistics.uniqueConnections)
            newPlayersStatistics = convertToBarChart(statistics.newPlayers)
            playerCountStatistics = convertToLineChart(statistics.playerCount)
        }
    }

    useEffectOnce {
        loadJsFile("https://cdn.plot.ly/plotly-2.34.0.min.js", "plotly") {
            plotlyLoaded = true
            loadPlotData()
        }
    }

    useEffect(interval) {
        if (!plotlyLoaded) return@useEffect
        loadPlotData()
    }

    errorModal(errorState = errorState)
    requiredUserPage(
        ScamPage.STATISTICS,
        canViewPage = { it.hasPermission(SCAMPermission.RestrictedDebug) }) { account ->
        loadingSpinner { isLoading = loading || !plotlyLoaded }
        div("ui buttons") {
            semanticButton {
                attrs.active = interval == StatisticsInterval.HOURLY
                +t("statistics.hourly")
                attrs.onClick = {
                    interval = StatisticsInterval.HOURLY
                }
            }
            semanticButton {
                attrs.active = interval == StatisticsInterval.DAILY
                +t("statistics.daily")
                attrs.onClick = {
                    interval = StatisticsInterval.DAILY
                }
            }
            semanticButton {
                attrs.active = interval == StatisticsInterval.WEEKLY
                +t("statistics.weekly")
                attrs.onClick = {
                    interval = StatisticsInterval.WEEKLY
                }
            }
            semanticButton {
                attrs.active = interval == StatisticsInterval.MONTHLY
                +t("statistics.monthly")
                attrs.onClick = {
                    interval = StatisticsInterval.MONTHLY
                }
            }
        }
        if (plotlyLoaded) {

            p { +t("statistics.player_count") }
            plot {
                this.id = "playerCount"
                this.data = arrayOf(playerCountStatistics)
            }
            p { +t("statistics.daily_connections") }
            plot {
                this.id = "dailyConnections"
                this.data = totalConnectionsStatistics
            }
            p { +t("statistics.unique_connections") }
            plot {
                this.id = "uniqueConnections"
                this.data = uniqueConnectionsStatistics
            }
            p { +t("statistics.new_players") }
            plot {
                this.id = "newPlayers"
                this.data = newPlayersStatistics
            }
        }
    }
}

fun RBuilder.statisticsPage(handler: StatisticsPageProps.() -> Unit) = child(StatisticsPageComponent) { attrs(handler) }