package com.perpheads.bans.components.blacklists

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.BlacklistResponse
import react.Props
import react.RBuilder
import react.dom.events.MouseEventHandler
import react.fc

external interface BlacklistProps : Props {
    var account: AccountInfoResponse?
    var blacklist: BlacklistResponse
    var expanded: Boolean
    var onBlacklistClicked: MouseEventHandler<*>
    var updateBlacklist: (BlacklistResponse, BlacklistResponse) -> Unit
}


val BlacklistComponent = fc<BlacklistProps>("Blacklist") { props ->
    blacklistHeader {
        this.blacklist = props.blacklist
        this.onClick = props.onBlacklistClicked
    }
    blacklistDetail {
        this.blacklist = props.blacklist
        this.expanded = props.expanded
        this.account = props.account
        this.updateBlacklist = props.updateBlacklist
    }
}



fun RBuilder.blacklist(handler: BlacklistProps.() -> Unit) = child(BlacklistComponent) { attrs(handler) }