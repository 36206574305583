package com.perpheads.bans.components.warnings

import com.perpheads.bans.*
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.optionalUserPage
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.responses.WarningSearchResponse
import com.perpheads.bans.util.updateElement
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*
import react.router.useNavigate
import react.router.useLocation
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt


external interface WarningPageProps : Props {
}

val WarningPageComponent = fc<WarningPageProps>("WarningPage") { props ->
    val t = useTranslation()
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val navigate = useNavigate()
    val page = parameters["page"]?.toIntOrNull() ?: 1
    val search = parameters["search"]
    val admin = parameters["admin"]
    val warningId = parameters["warningId"]?.toIntOrNull()
    val reason = parameters["reason"]

    var warnings by useState(WarningSearchResponse(0, emptyList()))
    val totalPages = max(ceil(warnings.count / ServerRoutes.entriesPerPage.toDouble()).roundToInt(), 1)
    val (loading, setLoading) = useState(true)
    val errorState = useState(false)

    fun changeUrl(
        newPage: Int,
        newSearch: String? = null,
        newAdmin: String? = null,
        newReason: String? = null,
        newWarningId: Int? = null
    ) {
        val params = Parameters.build {
            if (newPage != 1) {
                set("page", newPage.toString())
            }
            if (newSearch?.isNotBlank() == true) {
                set("search", newSearch)
            }
            if (newAdmin?.isNotBlank() == true) {
                set("admin", newAdmin)
            }
            if (newReason?.isNotBlank() == true) {
                set("reason", newReason)
            }
            if (newWarningId != null) {
                set("warningId", newWarningId.toString())
            }
        }
        navigate(ClientRoutes.warnings + "?${params}")
    }

    useEffect(location) {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            warnings = ApiClient.loadWarnings(page, search, admin, reason, warningId)
        }
    }

    optionalUserPage(ScamPage.WARNINGS, { changeUrl(1, it) }) { account ->
        loadingSpinner { isLoading = loading }

        p { +t.withKeys("warnings.total_count", mapOf("count" to warnings.count)) }
        warningTable {
            attrs {
                this.account = account
                this.warnings = warnings.warnings
                this.updateWarning = { oldWarning, newWarning ->
                    val newWarnings = warnings.warnings.updateElement(oldWarning, newWarning)
                    warnings = warnings.copy(warnings = newWarnings)
                }
            }
            tfoot {
                tr {
                    th {
                        attrs.colSpan = "3"
                        pagination {
                            this.defaultActivePage = page
                            this.totalPages = totalPages
                            this.onPageChanged = {
                                changeUrl(
                                    newPage = it,
                                    newSearch = search,
                                    newAdmin = admin,
                                    newWarningId = warningId,
                                    newReason = reason
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.warningPage() = child(WarningPageComponent) {
    attrs {

    }
}