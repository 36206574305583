package com.perpheads.bans.components.bans

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.BanResponse
import react.Props
import react.RBuilder
import react.dom.events.MouseEventHandler
import react.fc

external interface BanProps : Props {
    var account: AccountInfoResponse?
    var ban: BanResponse
    var expanded: Boolean
    var onBanClicked: MouseEventHandler<*>
    var updateBan: (BanResponse, BanResponse) -> Unit
}


val BanComponent = fc<BanProps>("Ban") { props ->
    banHeader {
        this.ban = props.ban
        this.onClick = props.onBanClicked
    }
    banDetails {
        this.ban = props.ban
        this.expanded = props.expanded
        this.account = props.account
        this.updateBan = props.updateBan
    }
}



fun RBuilder.ban(handler: BanProps.() -> Unit) = child(BanComponent) { attrs(handler) }