package com.perpheads.bans.responses

import com.perpheads.bans.profileIdToSteamId
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ServerLogResponse(
    val nickname: String?,
    val rpName: String?,
    val communityId: String?,
    val message: String,
    val date: Instant,
    val playerLocationX: Double? = null,
    val playerLocationY: Double? = null,
    val playerLocationZ: Double? = null,
    val playerCountryCode: String? = null,
) {
    val steamId: String?
        get() = communityId?.profileIdToSteamId()
}

@Serializable
data class ServerLogSearchResponse(val logs: List<ServerLogResponse>)