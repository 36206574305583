package com.perpheads.bans

enum class LogCategory(val translationKey: String, val categoryNum: Int) {
    Verbose("logs.category.verbose", 1),
    Info("logs.category.info", 2),
    Error("logs.category.error", 3),
    Severe("logs.category.severe", 4),
    Drugs("logs.category.drugs", 5),
    Connection("logs.category.connection", 6),
    DeathSpawn("logs.category.death_spawn", 7),
    Kill("logs.category.kill", 8),
    BrokenRule("logs.category.broken_rule", 9),
    Actions("logs.category.actions", 10),
    TookItem("logs.category.took_item", 11),
    Chat("logs.category.chat", 12),
    Inventory("logs.category.inventory", 13),
    Money("logs.category.money", 14),
    Vehicle("logs.category.vehicle", 15),
    Crime("logs.category.crime", 16),
    Scam("logs.category.scam", 17),
    ScamVerbose("logs.category.scam_verbose", 18),
    Organisation("logs.category.organisation", 19),
    Phone("logs.category.phone", 20),
    Demo("logs.category.demo", 21),
    Combat("logs.category.combat", 22),
    Casino("logs.category.casino", 23),
    Training("logs.category.training", 24),
    AntiCheat("logs.category.anti_cheat", 25),
    AntiCheatVerbose("logs.category.anti_cheat_verbose", 26),
    City("logs.category.city", 27),
    Mission("logs.category.mission", 28),
    Purchase("logs.category.purchase", 29),
    LuaErrorServer("logs.category.lua_error_server", 30),
    LuaErrorClient("logs.category.lua_error_client", 31),
}