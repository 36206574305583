package com.perpheads.bans.responses

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class CommentResponse(
    val commentId: Int,
    val commentText: String,
    val date: Instant,
    val admin: PlayerResponse
)