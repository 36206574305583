package com.perpheads.bans.responses

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class ReportOverviewResponse(

    val reportId: Int,
    val openDate: LocalDateTime,
    val closeDate: LocalDateTime?,
    val reporterCommunityId: String,
    val reporterNick: String,
    val adminNick: String?,
    val adminCommunityId: String?,
)

@Serializable
data class ReportMessage(
    val messageId: Int,
    val communityId: String,
    val nick: String,
    val message: String,
    val date: LocalDateTime
)

@Serializable
data class ReportOverviewPageResponse(
    val count: Int,
    val data: List<ReportOverviewResponse>
)