package com.perpheads.bans.components.blacklists

import com.perpheads.bans.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.optionalUserPage
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.responses.BlacklistSearchResponse
import com.perpheads.bans.util.updateElement
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*
import react.router.useNavigate
import react.router.useLocation
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt


external interface BlacklistPageProps : Props {
}

val BlacklistPageComponent = fc<BlacklistPageProps>("BlacklistPage") { props ->
    val t = useTranslation()
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val navigate = useNavigate()
    val page = parameters["page"]?.toIntOrNull() ?: 1
    val search = parameters["search"]
    val admin = parameters["admin"]
    val blacklistId = parameters["blacklistId"]?.toIntOrNull()
    val reason = parameters["reason"]

    var blacklists by useState(BlacklistSearchResponse(0, emptyList()))
    val totalPages = max(ceil(blacklists.count / ServerRoutes.entriesPerPage.toDouble()).roundToInt(), 1)
    val (loading, setLoading) = useState(true)
    val errorState = useState(false)

    fun changeUrl(
        newPage: Int,
        newSearch: String? = null,
        newAdmin: String? = null,
        newReason: String? = null,
        newBlacklistId: Int? = null
    ) {
        val params = Parameters.build {
            if (newPage != 1) {
                set("page", newPage.toString())
            }
            if (newSearch?.isNotBlank() == true) {
                set("search", newSearch)
            }
            if (newAdmin?.isNotBlank() == true) {
                set("admin", newAdmin)
            }
            if (newReason?.isNotBlank() == true) {
                set("reason", newReason)
            }
            if (newBlacklistId != null) {
                set("blacklistId", newBlacklistId.toString())
            }
        }
        navigate(ClientRoutes.blacklists + "?" + params)
    }

    useEffect(location) {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            blacklists = ApiClient.loadBlacklists(page, search, admin, reason, blacklistId)
        }
    }
    errorModal(errorState = errorState)

    optionalUserPage(ScamPage.BLACKLIST, { changeUrl(1, it) }) { account ->
        loadingSpinner { isLoading = loading }

        p { +t.withKeys("blacklists.total_count", mapOf("count" to blacklists.count)) }
        blacklistTable {
            attrs {
                this.account = account
                this.blacklists = blacklists.blacklists
                this.updateBlacklist = { oldBlacklist, newBlacklist ->
                    val newBlacklists = blacklists.blacklists.updateElement(oldBlacklist, newBlacklist)
                    blacklists = blacklists.copy(blacklists = newBlacklists)
                }
            }
            tfoot {
                tr {
                    th {
                        attrs.colSpan = "5"
                        pagination {
                            this.defaultActivePage = page
                            this.totalPages = totalPages
                            this.onPageChanged = {
                                changeUrl(
                                    newPage = it,
                                    newSearch = search,
                                    newAdmin = admin,
                                    newBlacklistId = blacklistId,
                                    newReason = reason
                                )
                            }
                        }
                    }
                }
            }

        }
    }
}

fun RBuilder.blacklistPage() = child(BlacklistPageComponent) {
    attrs {

    }
}