package com.perpheads.bans.components.reports

import com.perpheads.bans.*
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.ReportMessage
import com.perpheads.bans.responses.ReportOverviewPageResponse
import com.perpheads.bans.responses.ReportOverviewResponse
import com.perpheads.bans.util.format
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.*
import kotlinx.datetime.*
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.*
import react.dom.events.MouseEventHandler
import react.router.useLocation
import react.router.useNavigate
import styled.*
import web.html.InputType
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt

external interface ReportLogsPageProps : Props {

}

external interface ReportTableProps : PropsWithChildren {
    var reports: List<ReportOverviewResponse>
    var onReportClicked: (ReportOverviewResponse) -> Unit
    var reportCount: Int
    var onPageChange: (Int) -> Unit
    var page: Int
}

external interface ReportRowProps : Props {
    var report: ReportOverviewResponse
    var expanded: Boolean
    var onReportClicked: MouseEventHandler<*>
}

val ReportRow = fc<ReportRowProps> { props ->
    var hovered by useState(false)
    val report = props.report

    styledTr {
        attrs.onClick = props.onReportClicked
        css {
            cursor = Cursor.pointer
            if (hovered) {
                classes += "active"
            }
        }

        attrs.onMouseEnter = {
            hovered = true
        }
        attrs.onMouseLeave = {
            hovered = false
        }

        td {
            +report.reportId.toString()
        }
        td {
            +report.openDate.toInstant(TimeZone.UTC).format()
        }
        td {
            +report.reporterNick
        }
        styledTd {
            +(report.adminNick ?: "N/A")
            css {
                if (report.adminNick == null) {
                    fontStyle = FontStyle.italic
                }
            }
        }
        td {
            report.closeDate?.let {
                +it.toInstant(TimeZone.UTC).format()
            }
        }
    }
}

val ReportTable = fc<ReportTableProps>("ReportTable") { props ->
    val t = useTranslation()
    val totalPages = max(ceil(props.reportCount / ServerRoutes.entriesPerPage.toDouble()).roundToInt(), 1)

    p { +t.withKeys("reports.total_count", mapOf("count" to props.reportCount)) }

    table("ui celled table unstackable") {
        thead {

            tr {
                th { +t("headers.report_id") }
                th { +t("headers.date") }
                th { +t("headers.player") }
                th { +t("headers.admin") }
                th { +t("headers.close_date") }
            }
        }
        tbody {
            for (report in props.reports) {
                ReportRow {
                    attrs {
                        key = report.reportId.toString()
                        this.report = report
                        onReportClicked = { props.onReportClicked(report) }
                    }
                }
            }
        }
        tfoot {
            tr {
                th {
                    attrs.colSpan = "5"
                    pagination {
                        this.activePage = props.page
                        this.totalPages = totalPages
                        this.onPageChanged = {
                            props.onPageChange(it)
                        }
                    }
                }
            }
        }
        props.children()
    }
}

external interface ReportFeedProps : Props {
    var report: ReportOverviewResponse
}

val ReportFeed = fc<ReportFeedProps>("ReportFeed") { props ->
    val t = useTranslation()
    val navigate = useNavigate()
    val errorState = useState(false)
    var loadedMessages by useState<List<ReportMessage>>(emptyList())
    useEffect(props.report.reportId) {
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            loadedMessages = ApiClient.getReportMessages(props.report.reportId)
        }
    }

    semanticFeed {
        for (message in loadedMessages) {
            semanticFeedEvent {
                key = message.messageId.toString()
                semanticFeedLabel {
                    styledImg(src = ServerRoutes.avatarRoute(message.communityId)) {
                        inlineStyles {
                            width = 32.px
                            height = 32.px
                        }
                    }
                }
                semanticFeedContent {
                    semanticFeedDate {
                        +message.date.toInstant(TimeZone.UTC).format()
                    }
                    semanticFeedSummary {
                        a(ClientRoutes.playerProfile(message.communityId), classes = "author", target = "_blank") {
                            +message.nick
                        }
                        +" "
                        +t("report.replied")
                    }
                    semanticFeedExtra {
                        attrs.text = true
                        +message.message
                    }
                }
            }
        }
    }
}

val ReportLogsPageComponent = fc<ReportLogsPageProps>("ReportLogsPage") {
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val dateFrom = runCatching {
        LocalDate.parse(parameters["date_from"]!!)
    }.getOrNull() ?: Clock.System.now().toLocalDateTime(TimeZone.UTC).date.minus(1, DateTimeUnit.DAY)
    val dateTo = runCatching {
        LocalDate.parse(parameters["date_to"]!!)
    }.getOrNull() ?: Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    val navigate = useNavigate()
    val t = useTranslation()
    var loadedReports by useState(ReportOverviewPageResponse(0, emptyList()))
    val errorState = useState(false)
    var shownReport by useState<ReportOverviewResponse>()
    val admin = parameters["admin"]
    val page = parameters["page"]?.toIntOrNull() ?: 1

    useEffect(dateFrom.toString(), dateTo.toString(), admin, page) {
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            loadedReports = ApiClient.getReports(dateFrom, dateTo, admin, page, ServerRoutes.entriesPerPage)
        }
    }

    fun setQueryParams(
        newDateFrom: String = dateFrom.toString(),
        newDateTo: String = dateTo.toString(),
        newAdmin: String? = admin,
        newPage: Int = page
    ) {
        val params = Parameters.build {
            set("date_from", newDateFrom)
            set("date_to", newDateTo)
            if (newAdmin?.isNotBlank() == true) {
                set("admin", newAdmin)
            }
            set("page", newPage.toString())
        }
        navigate(ClientRoutes.reportLogs + "?${params}")
    }

    errorModal(errorState = errorState)

    semanticModal {
        attrs.open = shownReport != null
        attrs.onClose = { shownReport = null }

        semanticModalHeader {
            +t("headers.report")
        }
        semanticModalContent {
            shownReport?.let {
                ReportFeed {
                    attrs.report = it
                }
            }
        }
    }
    requiredUserPage(
        ScamPage.REPORT_LOGS,
        canViewPage = { it.canViewScamVerboseLogs },
        onSearched = { setQueryParams(newAdmin = it, newPage = 1) }
    ) {
        semanticForm {
            semanticFormGroup {
                attrs.widths = "equal"
                semanticFormField {
                    label { +t("headers.date_from") }
                    semanticInput {
                        defaultValue = dateFrom.toString()
                        type = InputType.date
                        onBlur = {
                            setQueryParams(newDateFrom = (it.target as HTMLInputElement).value, newPage = 1)
                        }
                    }
                }
                semanticFormField {
                    label { +t("headers.date_to") }
                    semanticInput {
                        defaultValue = dateTo.toString()
                        type = InputType.date
                        onBlur = {
                            setQueryParams(newDateTo = (it.target as HTMLInputElement).value, newPage = 1)
                        }
                    }
                }
            }
        }
        ReportTable {
            attrs.reports = loadedReports.data
            attrs.onReportClicked = { shownReport = it }
            attrs.reportCount = loadedReports.count
            attrs.onPageChange = { setQueryParams(newPage = it) }
            attrs.page = page
        }
    }
}

fun RBuilder.reportLogsPage(handler: ReportLogsPageProps.() -> Unit) =
    child(ReportLogsPageComponent) { attrs(handler) }