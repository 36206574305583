package com.perpheads.bans.requests

import kotlinx.serialization.Serializable

@Serializable
data class BanRequest(
    val steamId: String,
    val nickname: String,
    val ipAddress: String?,
    val reason: String,
    val duration: Long?
)