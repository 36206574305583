package com.perpheads.bans.components.ranks

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.components.actionButton
import com.perpheads.bans.components.collapsibleTableRow
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.PlayerResponse
import com.perpheads.bans.responses.RankOverviewResponse
import com.perpheads.bans.util.playerLink
import com.perpheads.bans.util.useTranslation
import kotlinx.coroutines.launch
import react.*
import react.dom.*
import react.router.useNavigate

external interface RankDetailProps : Props {
    var account: AccountInfoResponse
    var rank: RankOverviewResponse
    var expanded: Boolean
}

val RankDetailComponent = fc<RankDetailProps>("RankDetails") { props ->
    val t = useTranslation()
    val navigate = useNavigate()

    val rank = props.rank
    var wasShown by useState(false)
    var players by useState<List<PlayerResponse>>()
    val errorState = useState(false)
    useEffect(wasShown) {
        if (!wasShown) return@useEffect
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            players = ApiClient.getRankPlayers(rank.rankId)
        }
    }
    if (!props.expanded && !wasShown) return@fc
    if (props.expanded && !wasShown) {
        wasShown = true
    }

    errorModal(errorState = errorState)
    collapsibleTableRow(expanded = props.expanded, colSpan = "4") {
        players.let { ps ->
            if (ps == null) {
                //loadingSpinner { isLoading = true }
            }
            else if (ps.isEmpty()) {
                i { +t("ranks.none_with_rank") }
            } else {
                val showChangeRank = rank.immunity <= props.account.immunity
                table("ui celled compact table") {
                    thead {
                        tr {
                            th { +t("headers.name") }
                            th { +t("headers.community_id") }
                            th { +t("headers.steamid") }
                            if (showChangeRank) {
                                th { +t("headers.action") }
                            }
                        }
                    }
                    tbody {
                        for (p in ps) {
                            tr {
                                td { playerLink(p.name, p.communityId, navigate) }
                                td {
                                    a(href = "https://steamcommunity.com/profiles/${p.communityId}", target = "_blank") {
                                        +p.communityId
                                    }
                                }
                                td { +p.steamId() }
                                if (showChangeRank) {
                                    td { actionButton(
                                        navigate,
                                        t("actions.change_rank"),
                                        "user times",
                                        "orange",
                                        ClientRoutes.editRank(p.steamId())
                                    ) }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.rankDetails(handler: RankDetailProps.() -> Unit) = child(RankDetailComponent) { attrs(handler) }
