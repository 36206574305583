package com.perpheads.bans.components.demos

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ScamPage
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.DemosResponse
import react.*
import react.router.useNavigate

external interface DemoPageProps : Props {
}

data class DemoSearchSettings(
    val page: Int = 1,
    val filter: DemoFilter = DemoFilter.NONE,
    val query: String? = null
)

val DemoPageComponent = fc<DemoPageProps>("DemoPage") { props ->
    val navigate = useNavigate()
    var searchSettings by useState(DemoSearchSettings())
    val (loading, setLoading) = useState(false)
    val errorState = useState(false)
    var loadedDemos by useState(DemosResponse(0, emptyList()))

    useEffect(searchSettings) {
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            loadedDemos = ApiClient.getDemos(
                searchSettings.page,
                searchSettings.query,
                searchSettings.filter.identifier
            )
        }
    }


    errorModal(errorState = errorState)
    requiredUserPage(ScamPage.DEMOS, {
        searchSettings = searchSettings.copy(page = 1, query = it)
    }, { it.canBan }) { pageAccount ->
        loadingSpinner { isLoading = loading }


        demoSearchForm {
            account = pageAccount
            demoCount = loadedDemos.count
            selectedFilter = searchSettings.filter
            onFilterChanged = {
                searchSettings = searchSettings.copy(page = 1, filter = it)
            }
            onDemoRequested = { steamId, demoName, covert ->
                ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
                    ApiClient.requestDemo(steamId, demoName, covert)
                    //Forces a refresh
                    searchSettings = searchSettings.copy()
                }
            }
        }
        demoTable(searchSettings.page, loadedDemos) {
            searchSettings = searchSettings.copy(page = it)
        }
    }
}


fun RBuilder.demoPage() = child(DemoPageComponent) {
    attrs {

    }
}