@file:OptIn(ExperimentalTime::class)

package com.perpheads.bans.components

import com.perpheads.bans.util.TimeUnit
import com.perpheads.bans.util.coarsestUnit
import com.perpheads.bans.util.toCapitalized
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.semanticDropdown
import com.perpheads.bans.wrappers.semantic.semanticDropdownItem
import com.perpheads.bans.wrappers.semantic.semanticDropdownMenu
import kotlinx.css.marginTop
import kotlinx.css.px
import kotlinx.html.InputType
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.input
import react.dom.onChange
import styled.inlineStyles
import styled.styledDiv
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime

external interface DurationSelectorComponentProps : Props {
    var durationSeconds: Long?
    var selected: Boolean
    var onSelect: (Long?) -> Unit
}

private data class DurationSetting(
    val unit: TimeUnit,
    val min: Int,
    val max: Int,
    val step: Int = 1
)

val DurationSelector = fc<DurationSelectorComponentProps>("DurationDropdown") { props ->
    val t = useTranslation()
    val isSelected = props.selected
    val selectedSeconds = props.durationSeconds

    val selectedDuration = selectedSeconds?.let { it.seconds }

    val coarsestUnit = selectedDuration?.coarsestUnit()

    val durationSettings = listOf(
        DurationSetting(TimeUnit.SECONDS, 10, 50, 10),
        DurationSetting(TimeUnit.MINUTES, 5, 55, 5),
        DurationSetting(TimeUnit.HOURS, 1, 23, 1),
        DurationSetting(TimeUnit.DAYS, 1, 6, 1),
        DurationSetting(TimeUnit.WEEKS, 1, 3, 1),
        DurationSetting(TimeUnit.MONTHS, 1, 11, 1),
        DurationSetting(TimeUnit.YEARS, 1, 2, 1),
        null
    )

    val selectedDurationSettings = coarsestUnit?.unit?.let { unit -> durationSettings.find { it?.unit == unit } }
    val durationCount = coarsestUnit?.count ?: 1

    fun selectNewDurationSetting(durationSetting: DurationSetting?) {
        if (durationSetting != null) {
            val newDuration = durationSetting.unit.duration * durationSetting.min
            props.onSelect(newDuration.inWholeSeconds)
        } else {
            props.onSelect(null)
        }
    }

    fun changedDurationCount(newCount: Int) {
        selectedDurationSettings?.let {
            props.onSelect((it.unit.duration * newCount).inWholeSeconds)
        }
    }

    semanticDropdown {
        attrs {
            this.className = "icon"
            this.labeled = true
            this.icon = "hourglass half"
            this.text = if (isSelected) {
                selectedDurationSettings?.unit?.let {
                    "$durationCount " + t.pluralize(it.translationKey, durationCount.toInt()).toCapitalized()
                } ?: t("duration.permanent").toCapitalized()
            } else t("headers.duration")
            this.button = true
            this.floating = true
        }
        semanticDropdownMenu {
            for (setting in durationSettings) {
                semanticDropdownItem {
                    if (setting == null) {
                        +t("duration.permanent").toCapitalized()
                    } else {
                        +t.pluralize(setting.unit.translationKey).toCapitalized()
                    }
                    attrs.selected = isSelected && setting == selectedDurationSettings
                    attrs.onClick = {
                        selectNewDurationSetting(setting)
                    }
                }
            }
        }
    }
    styledDiv {
        inlineStyles {
            marginTop = 6.px
        }
        input {
            attrs.type = InputType.range
            attrs.min = selectedDurationSettings?.min?.toString() ?: "1"
            attrs.max = selectedDurationSettings?.max?.toString() ?: "1"
            attrs.step = selectedDurationSettings?.step?.toString() ?: "1"
            attrs.disabled = selectedDurationSettings == null || !isSelected
            attrs.value = durationCount.toString()
            attrs.onChange = {
                val text = (it.target as HTMLInputElement).value.toIntOrNull()
                if (text != null) {
                    changedDurationCount(text)
                }
            }
        }
    }
}

fun RBuilder.durationDropdown(
    durationSeconds: Long?,
    selected: Boolean,
    onSelect: (Long?) -> Unit
) = child(DurationSelector) {
    attrs {
        this.durationSeconds = durationSeconds
        this.selected = selected
        this.onSelect = onSelect
    }
}