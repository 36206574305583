package com.perpheads.bans.components.reports

import com.perpheads.bans.ApiClient
import com.perpheads.bans.ScamPage
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import kotlinx.css.*
import react.*
import react.router.useNavigate
import styled.inlineStyles
import styled.styledIframe

external interface ReportPageProps : Props {

}

val ReportPageComponent = fc<ReportPageProps>("ReportPage") {
    val navigate = useNavigate()
    var url by useState<String>()
    val errorState = useState(false)
    useEffectOnce {
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            url = ApiClient.getReportEmbedUrl()
        }
    }

    errorModal(errorState = errorState)
    requiredUserPage(ScamPage.REPORTS, canViewPage = { it.canBan }) {
        val currentUrl = url
        loadingSpinner { isLoading = currentUrl == null }
        if (currentUrl == null) return@requiredUserPage
        styledIframe {
            inlineStyles {
                position = Position.relative
                height = 69.vh
                width = 100.pct
            }
            attrs["scrolling"] = "no"
            attrs["frameBorder"] = "0"
            attrs.src = currentUrl
        }
    }
}

fun RBuilder.reportPage(handler: ReportPageProps.() -> Unit) = child(ReportPageComponent) { attrs(handler) }
