package com.perpheads.bans.components.warnings

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.WarningResponse
import react.Props
import react.RBuilder
import react.dom.events.MouseEventHandler
import react.fc


external interface WarningProps : Props {
    var account: AccountInfoResponse?
    var warning: WarningResponse
    var expanded: Boolean
    var onWarningClicked: MouseEventHandler<*>
    var updateWarning: (WarningResponse, WarningResponse) -> Unit
}


val WarningComponent = fc<WarningProps>("Warning") { props ->
    warningHeader {
        this.warning = props.warning
        this.onClick = props.onWarningClicked
    }
    warningDetails {
        this.warning = props.warning
        this.expanded = props.expanded
        this.account = props.account
        this.updateWarning = props.updateWarning
    }
}



fun RBuilder.warning(handler: WarningProps.() -> Unit) = child(WarningComponent) { attrs(handler) }