package com.perpheads.bans

object ServerRoutes {
    const val entriesPerPage = 30
    const val screenshotEntriesPerPage = 5


    const val indexRoute: String = "/"

    // Bans
    const val bansRoute: String = "/api/bans"

    const val banRoute: String = "/api/bans/{banId}"
    fun banRoute(banId: Int) = "/api/bans/$banId"

    const val undeleteBanRoute: String = "/api/bans/{banId}/undelete"
    fun undeleteBanRoute(banId: Int) = "/api/bans/$banId/undelete"

    const val unbanRoute: String = "/api/bans/{banId}/unban"
    fun unbanRoute(banId: Int) = "/api/bans/$banId/unban"

    const val rebanRoute: String = "/api/bans/{banId}/reban"
    fun rebanRoute(banId: Int) = "/api/bans/$banId/reban"

    const val banCommentsRoute = "/api/bans/{banId}/comments"
    fun banCommentsRoute(banId: Int): String = "/api/bans/$banId/comments"


    //Warnings
    const val warningsRoute: String = "/api/warnings"

    const val warningRoute: String = "/api/warnings/{warningId}"
    fun warningRoute(warningId: Int) = "/api/warnings/$warningId"

    const val undeleteWarningRoute: String = "/api/warnings/{warningId}/undelete"
    fun undeleteWarningRoute(warningId: Int) = "/api/warnings/$warningId/undelete"

    const val warningCommentsRoute = "/api/warnings/{warningId}/comments"
    fun warningCommentsRoute(warningId: Int): String = "/api/warnings/$warningId/comments"

    //Blacklists
    const val blacklistsRoute: String = "/api/blacklists"

    const val blacklistRoute: String = "/api/blacklists/{blacklistId}"
    fun blacklistRoute(blacklistId: Int) = "/api/blacklists/$blacklistId"

    const val undeleteBlacklistRoute: String = "/api/blacklists/{blacklistId}/undelete"
    fun undeleteBlacklistRoute(blacklistId: Int) = "/api/blacklists/$blacklistId/undelete"

    const val unblacklistRoute: String = "/api/blacklists/{blacklistId}/unblacklist"
    fun unblacklistRoute(blacklistId: Int) = "/api/blacklists/$blacklistId/unblacklist"

    const val reblacklistRoute: String = "/api/blacklists/{blacklistId}/reblacklist"
    fun reblacklistRoute(blacklistId: Int) = "/api/blacklists/$blacklistId/reblacklist"

    const val blacklistCommentsRoute = "/api/blacklists/{blacklistId}/comments"
    fun blacklistCommentsRoute(blacklistId: Int): String = "/api/blacklists/$blacklistId/comments"

    //Logs
    const val serverLogsRoute: String = "/api/servers/logs/search"

    const val banLogsRoute = "/api/bans/logs"

    const val warningLogsRoute = "/api/warnings/logs"

    const val blacklistLogsRoute = "/api/blacklists/logs"

    const val playerLogsRoute: String = "/api/players/logs"

    const val commentLogsRoute: String = "/api/comments/logs"

    // Player
    const val playerRoute: String = "/api/players/{communityId}"
    fun playerRoute(communityId: String) = "/api/players/$communityId"
    const val playerBansRoute: String = "/api/players/{communityId}/bans"
    fun playerBansRoute(communityId: String) = "/api/players/$communityId/bans"
    const val playerRelatedBansRoute: String = "/api/players/{communityId}/related-bans"
    fun playerRelatedBansRoute(communityId: String) = "/api/players/$communityId/related-bans"
    const val playerWarningsRoute: String = "/api/players/{communityId}/warnings"
    fun playerWarningsRoute(communityId: String) = "/api/players/$communityId/warnings"
    const val playerBlacklistsRoute: String = "/api/players/{communityId}/blacklists"
    fun playerBlacklistsRoute(communityId: String) = "/api/players/$communityId/blacklists"
    const val playerSearchRoute = "/api/players/search"

    const val avatarRoute: String = "/api/players/{communityId}/avatar"
    fun avatarRoute(communityId: String): String = "/api/players/$communityId/avatar"

    const val steamNameLookupRoute: String = "/api/players/name-by-steamid"

    fun altsRoute(communityId: String) = "/api/players/$communityId/alts"
    const val altsRoute: String = "/api/players/{communityId}/alts"

    fun specificAltsRoute(communityId: String, altCommunityId: String) = "/api/players/$communityId/alts/$altCommunityId"
    const val specificAltsRoute: String = "/api/players/{communityId}/alts/{altCommunityId}"

    //Screenshots
    const val screenshotRoute: String = "/screenshots/{screenshotId}"
    fun screenshotRoute(screenshotId: Int): String = "/screenshots/$screenshotId"
    const val screenshotsRoute: String = "/api/screenshots"
    const val requestScreenshotRoute: String = "/api/screenshots/request"

    //Demos
    const val demoRoute: String = "/demos/{demoId}"
    fun demoRoute(demoId: Int): String = "/demos/$demoId"
    const val demosRoute: String = "/api/demos"
    const val requestDemoRoute: String = "/api/demos/request"

    //Account
    const val accountRoute: String = "/api/account"
    const val authRoute: String = "/api/auth"
    const val logoutRoute: String = "/api/logout"
    const val steamCallbackRoute: String = "/api/steam-callback"
    const val reportsEmbedRoute: String = "/api/reports/embed-url"
    const val reportsRoute: String = "/api/reports"
    const val reportMessagesRoute = "/api/reports/{reportId}/messages"
    fun reportMessagesRoute(reportId: Int): String = "/api/reports/${reportId}/messages"

    //Statistics
    const val statisticsRoute: String = "/api/statistics"

    //Ranks
    const val ranks = "/api/ranks"
    const val rankOverviewRoute = "/api/ranks/overview"
    const val rankPlayersRoute = "/api/ranks/{rankId}/players"
    fun rankPlayersRoute(rankId: Int) = "/api/ranks/$rankId/players"
    const val editPlayerRankRoute = "/api/players/{communityId}/rank"
    fun editPlayerRankRoute(communityId: String) = "/api/players/$communityId/rank"

    // Comments
    const val commentRoute: String = "/api/comments/{commentId}"
    fun commentRoute(commentId: Int): String = "/api/comments/$commentId"

    // Log Processing
    const val altFingerprintRoute: String = "/api/log-processing/alt-fingerprint"
}