package com.perpheads.bans.components

import com.perpheads.bans.util.slideDown
import kotlinx.css.Padding
import kotlinx.css.padding
import kotlinx.css.px
import react.*
import react.dom.tr
import styled.css
import styled.inlineStyles
import styled.styledDiv
import styled.styledTd

external interface CollapsibleTableRowProps : PropsWithChildren {
    var colSpan: String
    var expanded: Boolean
}

val CollapsibleTableRowComponent = fc<CollapsibleTableRowProps>("Collapsible Row") { props ->
    tr {
        styledTd {
            inlineStyles {
                padding = Padding(0.px)
            }
            attrs.colSpan = props.colSpan
            slideDown(closed = !props.expanded) {
                styledDiv {
                    css {
                        classes += "ui"
                        padding = Padding(10.px)
                    }
                    props.children()
                }
            }
        }
    }
}

fun RBuilder.collapsibleTableRow(
    expanded: Boolean = false,
    colSpan: String = "4",
    handler: RHandler<PropsWithChildren>
) = CollapsibleTableRowComponent {
    attrs {
        this.colSpan = colSpan
        this.expanded = expanded
    }
    handler.invoke(this)
}