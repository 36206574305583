package com.perpheads.bans.components.bans

import com.perpheads.bans.*
import com.perpheads.bans.components.*
import com.perpheads.bans.components.page.optionalUserPage
import com.perpheads.bans.components.page.pagination
import com.perpheads.bans.responses.BanSearchResponse
import com.perpheads.bans.util.updateElement
import com.perpheads.bans.util.useTranslation
import react.*
import react.dom.*
import react.router.useNavigate
import react.router.useLocation
import kotlin.math.ceil
import kotlin.math.max
import kotlin.math.roundToInt

external interface BanPageProps : Props {
}

val BanPageComponent = fc<BanPageProps>("BanPage") { props ->
    val t = useTranslation()
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val navigate = useNavigate()
    val page = parameters["page"]?.toIntOrNull() ?: 1
    val search = parameters["search"]
    val admin = parameters["admin"]
    val banId = parameters["banId"]?.toIntOrNull()
    val reason = parameters["reason"]

    var bans by useState(BanSearchResponse(0, emptyList()))
    val totalPages = max(ceil(bans.count / ServerRoutes.entriesPerPage.toDouble()).roundToInt(), 1)
    var (loading, setLoading) = useState(true)
    val errorState = useState(false)

    fun changeUrl(
        newPage: Int,
        newSearch: String? = null,
        newAdmin: String? = null,
        newReason: String? = null,
        newBanId: Int? = null
    ) {
        val params = Parameters.build {
            if (newPage != 1) {
                set("page", newPage.toString())
            }
            if (newSearch?.isNotBlank() == true) {
                set("search", newSearch)
            }
            if (newAdmin?.isNotBlank() == true) {
                set("admin", newAdmin)
            }
            if (newReason?.isNotBlank() == true) {
                set("reason", newReason)
            }
            if (newBanId != null) {
                set("banId", newBanId.toString())
            }
        }
        navigate("/?${params}")
    }

    useEffect(location) {
        loading = true
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            bans = ApiClient.loadBans(page, search, admin, reason, banId)
            loading = false
        }
    }
    errorModal(errorState = errorState)

    optionalUserPage(ScamPage.BANS, { changeUrl(1, it) }) { account ->
        loadingSpinner { isLoading = loading }

        p { +t.withKeys("bans.total_count", mapOf("count" to bans.count)) }
        banTable {
            attrs {
                this.account = account
                this.bans = bans.bans
                this.updateBan = { oldBan, newBan ->
                    val newBans = bans.bans.updateElement(oldBan, newBan)
                    bans = bans.copy(bans = newBans)
                }
            }

            tfoot {
                tr {
                    th {
                        attrs.colSpan = "4"
                        pagination {
                            this.defaultActivePage = page
                            this.totalPages = totalPages
                            this.onPageChanged = {
                                changeUrl(
                                    newPage = it,
                                    newSearch = search,
                                    newAdmin = admin,
                                    newBanId = banId,
                                    newReason = reason
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.banPage() = child(BanPageComponent) {
    attrs {

    }
}