package com.perpheads.bans

data class Vector2(val x: Double, val y: Double) {
    operator fun plus(other: Vector2): Vector2 {
        return Vector2(x + other.x, y + other.y)
    }

    operator fun unaryMinus(): Vector2 {
        return Vector2(-x, -y)
    }
}

object MapCoordinates {
    private const val topLeftX = -15390.0
    private const val topLeftY = 14253.0
    private const val bottomRightX = 14980.0
    private const val bottomRightY = -16150.0

    fun convertToRatios(pos: Vector2): Vector2 {
        return Vector2(
            (pos.x - topLeftX) / (bottomRightX - topLeftX),
            (pos.y - topLeftY) / (bottomRightY - topLeftY)
        )
    }

    fun convertToDeltaRatios(pos: Vector2): Vector2 {
        return Vector2(
            pos.x / (bottomRightX - topLeftX),
            pos.y / (bottomRightY - topLeftY)
        )
    }

    fun convertToCoordinates(ratio: Vector2): Vector2 {
        return convertToDeltaCoordinates(ratio) + Vector2(topLeftX, topLeftY)
    }

    fun convertToDeltaCoordinates(ratio: Vector2): Vector2 {
        return Vector2(
            ratio.x * (bottomRightX - topLeftX),
            ratio.y * (bottomRightY - topLeftY)
        )
    }
}