package com.perpheads.bans.components

import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.TextAlign
import kotlinx.css.textAlign
import react.Props
import react.RBuilder
import react.StateInstance
import react.dom.p
import react.fc
import styled.inlineStyles
import styled.styledP

external interface ErrorModalProps : Props {
    var icon: String?
    var title: String?
    var description: String?
    var onClosed: () -> Unit
}


val ErrorModalComponent = fc<ErrorModalProps>("ErrorModal") { props ->
    val t = useTranslation()

    semanticModal {
        attrs {
            basic = true
            size = "small"
            open = true
            centered = true
            onClose = { props.onClosed() }
        }
        semanticHeader {
            attrs.icon = true
            semanticIcon("warning")
            p {
                +(props.title ?: t("headers.error"))
            }
        }
        semanticModalContent {
            styledP {
                inlineStyles {
                    textAlign = TextAlign.center
                }
                +(props.description ?: t("errors.unexpected"))
            }
        }
        semanticModalActions {
            attrs.style = kotlinext.js.js {
                textAlign = "center"
            }
            semanticButton {
                attrs {
                    inverted = true
                    basic = true
                    negative = false
                    onClick = { props.onClosed() }
                }
                +t("button.ok")
            }
        }
    }
}

fun RBuilder.errorModal(title: String? = null, description: String? = null, errorState: StateInstance<Boolean>) {
    if (!errorState.component1()) return
    ErrorModalComponent {
        attrs {
            this.title = title
            this.description = description
            this.onClosed = {
                errorState.component2().invoke(false)
            }
        }
    }
}