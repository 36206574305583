package com.perpheads.bans

import com.perpheads.bans.requests.*
import com.perpheads.bans.responses.*
import com.perpheads.bans.wrappers.*
import kotlinx.coroutines.*
import kotlinx.datetime.LocalDate
import kotlinx.serialization.json.Json
import react.StateInstance
import react.StateSetter
import react.router.NavigateFunction

object ApiClient {
    private val mainScope = MainScope()

    fun launchWithErrorHandler(
        navigate: NavigateFunction,
        errorState: StateInstance<Boolean>,
        setLoading: StateSetter<Boolean>? = null,
        errorHandler: (Throwable) -> Unit = {},
        body: suspend CoroutineScope.() -> Unit
    ): Job {
        setLoading?.invoke(true)
        return mainScope.launch {
            try {
                body()
            } catch (e: CancellationException) {
                throw e //Always rethrow this
            } catch (e: UnauthorizedException) {
                logout(navigate)
            } catch (e: Throwable) {
                errorHandler(e)
                errorState.component2().invoke(true)
            } finally {
                setLoading?.invoke(false)
            }
        }
    }

    val jsonSerializer = Json {
        ignoreUnknownKeys = true
    }

    object UnauthorizedException : Exception()
    object NotFoundException : Exception()
    data class UnexpectedHttpStatusException(val status: Int) : Exception()


    //Bans
    suspend fun loadBans(
        page: Int = 1,
        search: String?,
        admin: String?,
        reason: String?,
        banId: Int?
    ): BanSearchResponse = axiosGet(ServerRoutes.bansRoute) {
        parameter("page", page)
        search?.let {
            parameter("search", search)
        }
        admin?.let {
            parameter("admin", admin)
        }
        reason?.let {
            parameter("reason", reason)
        }
        banId?.let {
            parameter("banId", banId)
        }
    }

    suspend fun addBan(request: BanRequest): BanResponse {
        return axiosPost(ServerRoutes.bansRoute, request)
    }

    suspend fun editBan(banId: Int, request: BanRequest) {
        return axiosPatch(ServerRoutes.banRoute(banId), request)
    }

    suspend fun getBan(banId: Int): BanResponse = axiosGet(ServerRoutes.banRoute(banId))

    suspend fun addAlt(communityId: String, altCommunityId: String, allowed: Boolean) {
        return axiosPut(ServerRoutes.specificAltsRoute(communityId, altCommunityId) + "?allowed=$allowed")
    }

    suspend fun deleteAlt(communityId: String, altCommunityId: String) {
        return axiosDelete(ServerRoutes.specificAltsRoute(communityId, altCommunityId))
    }

    suspend fun deleteBan(banId: Int): Unit = axiosDelete(ServerRoutes.banRoute(banId))

    suspend fun undeleteBan(banId: Int): Unit = axiosPost(ServerRoutes.undeleteBanRoute(banId))

    suspend fun unban(banId: Int, reason: String): BanResponse =
        axiosPost(ServerRoutes.unbanRoute(banId), UnbanRequest(reason))

    suspend fun reban(banId: Int): BanResponse =
        axiosPost(ServerRoutes.rebanRoute(banId))

    suspend fun getBanComments(banId: Int): List<CommentResponse> =
        axiosGet(ServerRoutes.banCommentsRoute(banId))

    //Warnings
    suspend fun loadWarnings(
        page: Int = 1,
        search: String?,
        admin: String?,
        reason: String?,
        warningId: Int?
    ): WarningSearchResponse = axiosGet(ServerRoutes.warningsRoute) {
        parameter("page", page)
        search?.let {
            parameter("search", search)
        }
        admin?.let {
            parameter("admin", admin)
        }
        reason?.let {
            parameter("reason", reason)
        }
        warningId?.let {
            parameter("warningId", warningId)
        }
    }

    suspend fun getWarning(warningId: Int): WarningResponse = axiosGet(ServerRoutes.warningRoute(warningId))

    suspend fun getWarningComments(warningId: Int): List<CommentResponse> =
        axiosGet(ServerRoutes.warningCommentsRoute(warningId))

    suspend fun deleteWarning(warningId: Int): Unit = axiosDelete(ServerRoutes.warningRoute(warningId))

    suspend fun undeleteWarning(warningId: Int): Unit = axiosPost(ServerRoutes.undeleteWarningRoute(warningId))

    suspend fun editWarning(warningId: Int, request: WarningRequest) {
        return axiosPatch(ServerRoutes.warningRoute(warningId), request)
    }

    suspend fun addWarning(request: WarningRequest): WarningResponse {
        return axiosPost(ServerRoutes.warningsRoute, request)
    }

    //Blacklists
    suspend fun loadBlacklists(
        page: Int = 1,
        search: String?,
        admin: String?,
        reason: String?,
        blacklistId: Int?
    ): BlacklistSearchResponse = axiosGet(ServerRoutes.blacklistsRoute) {
        parameter("page", page)
        search?.let {
            parameter("search", search)
        }
        admin?.let {
            parameter("admin", admin)
        }
        reason?.let {
            parameter("reason", reason)
        }
        blacklistId?.let {
            parameter("blacklistId", blacklistId)
        }
    }

    suspend fun addBlacklist(request: BlacklistRequest): BlacklistResponse {
        return axiosPost(ServerRoutes.blacklistsRoute, request)
    }

    suspend fun editBlacklist(blacklistId: Int, request: BlacklistRequest) {
        return axiosPatch(ServerRoutes.blacklistRoute(blacklistId), request)
    }

    suspend fun getBlacklist(blacklistId: Int): BlacklistResponse =
        axiosGet(ServerRoutes.blacklistRoute(blacklistId))

    suspend fun deleteBlacklist(blacklistId: Int): Unit = axiosDelete(ServerRoutes.blacklistRoute(blacklistId))

    suspend fun undeleteBlacklist(blacklistId: Int): Unit =
        axiosPost(ServerRoutes.undeleteBlacklistRoute(blacklistId))

    suspend fun unblacklist(blacklistId: Int, reason: String): BlacklistResponse =
        axiosPost(ServerRoutes.unblacklistRoute(blacklistId), UnblacklistRequest(reason))

    suspend fun reblacklist(blacklistId: Int): BlacklistResponse =
        axiosPost(ServerRoutes.reblacklistRoute(blacklistId))

    suspend fun getBlacklistComments(blacklistId: Int): List<CommentResponse> =
        axiosGet(ServerRoutes.blacklistCommentsRoute(blacklistId))

    //Comments
    suspend fun postBanComment(banId: Int, text: String): CommentResponse =
        axiosPost(ServerRoutes.banCommentsRoute(banId), CommentTextRequest(text))

    suspend fun postWarningComment(warningId: Int, text: String): CommentResponse =
        axiosPost(ServerRoutes.warningCommentsRoute(warningId), CommentTextRequest(text))

    suspend fun postBlacklistComment(blacklistId: Int, text: String): CommentResponse =
        axiosPost(ServerRoutes.blacklistCommentsRoute(blacklistId), CommentTextRequest(text))

    suspend fun deleteComment(commentId: Int): Unit = axiosDelete(ServerRoutes.commentRoute(commentId))

    suspend fun editComment(commentId: Int, text: String): Unit =
        axiosPatch(ServerRoutes.commentRoute(commentId), CommentTextRequest(text))

    //Logs
    suspend fun getLogs(request: ServerLogSearchRequest): ServerLogSearchResponse =
        axiosPost(ServerRoutes.serverLogsRoute, request)

    suspend fun getBanLogs(page: Int): ActionLogsSearchResponse<BanLogResponse> =
        axiosGet(ServerRoutes.banLogsRoute) {
            parameter("page", page)
        }

    suspend fun getWarningLogs(page: Int): ActionLogsSearchResponse<WarningLogResponse> =
        axiosGet(ServerRoutes.warningLogsRoute) {
            parameter("page", page)
        }

    suspend fun getBlacklistLogs(page: Int): ActionLogsSearchResponse<BlacklistLogResponse> =
        axiosGet(ServerRoutes.blacklistLogsRoute) {
            parameter("page", page)
        }

    suspend fun getPlayerLogs(page: Int): ActionLogsSearchResponse<PlayerLogResponse> =
        axiosGet(ServerRoutes.playerLogsRoute) {
            parameter("page", page)
        }

    suspend fun getCommentLogs(page: Int): ActionLogsSearchResponse<CommentLogResponse> =
        axiosGet(ServerRoutes.commentLogsRoute) {
            parameter("page", page)
        }

    // Screenshots
    suspend fun getScreenshots(
        page: Int? = null,
        query: String? = null,
        filter: String? = null
    ): ScreenshotsResponse {
        return axiosGet(ServerRoutes.screenshotsRoute) {
            parameter("page", page)
            parameter("query", query)
            parameter("filter", filter)
        }
    }

    suspend fun requestScreenshot(steamId: String) {
        return axiosPost(ServerRoutes.requestScreenshotRoute) {
            parameter("steamId", steamId)
        }
    }

    // Demos
    suspend fun getDemos(
        page: Int? = null,
        query: String? = null,
        filter: String? = null
    ): DemosResponse {
        return axiosGet(ServerRoutes.demosRoute) {
            parameter("page", page)
            parameter("query", query)
            parameter("filter", filter)
        }
    }

    suspend fun requestDemo(steamId: String, demoName: String, covert: Boolean) {
        return axiosPost(ServerRoutes.requestDemoRoute) {
            parameter("steamId", steamId)
            parameter("covert", covert)
            parameter("name", demoName)
        }
    }

    //Profile

    suspend fun getProfile(communityId: Long): ProfileResponse =
        axiosGet(ServerRoutes.playerRoute(communityId.toString()))

    suspend fun getProfileBans(communityId: Long): BanSearchResponse =
        axiosGet(ServerRoutes.playerBansRoute(communityId.toString()))

    suspend fun getProfileRelatedBans(communityId: Long): BanSearchResponse =
        axiosGet(ServerRoutes.playerRelatedBansRoute(communityId.toString()))

    suspend fun getProfileWarnings(communityId: Long): WarningSearchResponse =
        axiosGet(ServerRoutes.playerWarningsRoute(communityId.toString()))

    suspend fun getProfileBlacklists(communityId: Long): BlacklistSearchResponse =
        axiosGet(ServerRoutes.playerBlacklistsRoute(communityId.toString()))

    suspend fun getAltAccounts(communityId: Long): List<AltAccountResponse> =
        axiosGet(ServerRoutes.altsRoute(communityId.toString()))


    suspend fun getPlayerSuggestions(name: String): List<PlayerSearchResponse> =
        axiosPost(ServerRoutes.playerSearchRoute, PlayerSearchRequest(name))


    //Ranks
    suspend fun getAllRanks(): List<RankResponse> =
        axiosGet(ServerRoutes.ranks)

    suspend fun getRankOverview(): List<RankOverviewResponse> =
        axiosGet(ServerRoutes.rankOverviewRoute)

    suspend fun getRankPlayers(rankId: Int): List<PlayerResponse> =
        axiosGet(ServerRoutes.rankPlayersRoute(rankId))

    suspend fun changePlayerRank(communityId: Long, rankId: Int): Unit =
        axiosPatch(ServerRoutes.editPlayerRankRoute(communityId.toString())) {
            parameter("rankId", rankId.toString())
        }

    //Statistics
    suspend fun getStatistics(interval: String): StatisticsResponse =
        axiosGet(ServerRoutes.statisticsRoute) {
            parameter("interval", interval)
        }

    //Account
    suspend fun logout(): Unit =
        axiosPost(ServerRoutes.logoutRoute)

    suspend fun steamAuth(steamAuthQueryString: String): AccountInfoResponse =
        axiosPost(ServerRoutes.steamCallbackRoute + "?" + steamAuthQueryString)

    suspend fun getAccount(): AccountInfoResponse =
        axiosGet(ServerRoutes.accountRoute)

    suspend fun getReportEmbedUrl(): String =
        axiosGet(ServerRoutes.reportsEmbedRoute)

    suspend fun getReports(dateFrom: LocalDate, dateTo: LocalDate, admin: String?, page: Int, entriesPerPage: Int): ReportOverviewPageResponse =
        axiosGet(ServerRoutes.reportsRoute) {
            parameter("dateFrom", dateFrom)
            parameter("dateTo", dateTo)
            parameter("admin", admin)
            parameter("page", page)
            parameter("entriesPerPage", entriesPerPage)
        }

    suspend fun getReportMessages(reportId: Int): List<ReportMessage> =
        axiosGet(ServerRoutes.reportMessagesRoute(reportId)) {
            parameter("report_id", reportId)
        }

    suspend fun getNameBySteamId(steamId: String): String? {
        return try {
            axiosGet(ServerRoutes.steamNameLookupRoute) {
                parameter("steamId", steamId)
            }
        } catch (e: NotFoundException) {
            null
        }
    }
}