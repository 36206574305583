package com.perpheads.bans.responses

import com.perpheads.bans.LogCategory
import com.perpheads.bans.SCAMPermission
import kotlinx.serialization.Serializable

@Serializable
data class AccountInfoResponse(
    val player: PlayerResponse,
    val immunity: Int,
    val permissions: List<String>
) {
    val canEditComments
        get() = hasPermission(SCAMPermission.Rank)
    val canBan
        get() = hasPermission(SCAMPermission.Ban)
    val canBlacklist
        get() = hasPermission(SCAMPermission.Blacklist)
    val canChatBlacklist
        get() = hasPermission(SCAMPermission.ChatAdmin)
    val canEditRanks
        get() = hasPermission(SCAMPermission.EditRank)
    val canAssignRanks
        get() = hasPermission(SCAMPermission.Rank)
    val canUndelete
        get() = hasPermission(SCAMPermission.Rank)
    val canWarn
        get() = hasPermission(SCAMPermission.Warn)
    val canViewLogs
        get() = hasPermission(SCAMPermission.Logs)
    val canViewScamVerboseLogs
        get() = hasPermission(SCAMPermission.ScamLogs)
    val canViewIdentityLogs
        get() = hasPermission(SCAMPermission.IdentityLogs)

    fun hasPermission(permission: SCAMPermission): Boolean = hasPermission(permission.permission)
    private fun hasPermission(permission: String) = permissions.contains(permission)

    fun availableLogCategories(): List<LogCategory> {
        val logCategories = mutableListOf<LogCategory>()
        if (canViewLogs) {
            logCategories.add(LogCategory.Chat)
            logCategories.add(LogCategory.Drugs)
            logCategories.add(LogCategory.DeathSpawn)
            logCategories.add(LogCategory.Kill)
            logCategories.add(LogCategory.Actions)
            logCategories.add(LogCategory.TookItem)
            logCategories.add(LogCategory.Inventory)
            logCategories.add(LogCategory.Money)
            logCategories.add(LogCategory.Vehicle)
            logCategories.add(LogCategory.Crime)
            logCategories.add(LogCategory.Organisation)
            logCategories.add(LogCategory.Phone)
            logCategories.add(LogCategory.Demo)
            logCategories.add(LogCategory.Combat)
            logCategories.add(LogCategory.City)
            logCategories.add(LogCategory.Mission)
        }
        if (canBan) {
            logCategories.add(LogCategory.Verbose)
            logCategories.add(LogCategory.BrokenRule)
            logCategories.add(LogCategory.Scam)
            logCategories.add(LogCategory.Casino)
        }
        if (canViewIdentityLogs) {
            logCategories.add(LogCategory.Connection)
        }
        if (canViewScamVerboseLogs) {
            logCategories.add(LogCategory.Info)
            logCategories.add(LogCategory.Error)
            logCategories.add(LogCategory.Severe)
            logCategories.add(LogCategory.Training)
            logCategories.add(LogCategory.AntiCheat)
            logCategories.add(LogCategory.AntiCheatVerbose)
            logCategories.add(LogCategory.ScamVerbose)
            logCategories.add(LogCategory.Purchase)
            logCategories.add(LogCategory.LuaErrorServer)
            logCategories.add(LogCategory.LuaErrorClient)
        }
        return logCategories
    }
}