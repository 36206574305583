package com.perpheads.bans.components.page

import com.perpheads.bans.ApiClient
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.parseQueryString
import com.perpheads.bans.replace
import com.perpheads.bans.storeAccount
import kotlinx.browser.window
import react.*
import react.router.useNavigate

external interface SteamCallbackComponentProps : Props {
}

val SteamCallbackComponent = fc<SteamCallbackComponentProps>("SteamCallback") {
    val parameters = parseQueryString(window.location.search)
    val navigate = useNavigate()
    val errorState = useState(false)

    useEffectOnce {
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            val response = ApiClient.steamAuth(window.location.search.drop(1))
            storeAccount(response)
            navigate.replace("/")
        }
    }
    loadingSpinner { isLoading = true }
}


fun RBuilder.steamCallback() = child(SteamCallbackComponent) {
    attrs {

    }
}