package com.perpheads.bans

import react.dom.RDOMBuilder
import react.dom.a

private val steamIdOrUrlRegex = Regex("(https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*))|(STEAM_[0-5]:[01]:\\d+)")

private fun RDOMBuilder<*>.formatUrl(url: String) {
    a(href = url, target = "_blank") {
        +url
    }
}

private fun RDOMBuilder<*>.formatSteamId(steamId: String) {
    a(href = "/player/$steamId") {
        +steamId
    }
}

private fun RDOMBuilder<*>.formatMatch(match: MatchResult) {
    val matchStr = match.value
    if (matchStr.startsWith("STEAM")) {
        formatSteamId(matchStr)
    } else {
        formatUrl(matchStr)
    }
}

/**
 * Creates links out of all steamIds and URLs
 */
fun RDOMBuilder<*>.linkify(str: String) {
    val allMatches = steamIdOrUrlRegex.findAll(str).iterator()
    val nonMatchParts = steamIdOrUrlRegex.split(str)
    nonMatchParts.forEach { nonMatch ->
        +nonMatch
        if (!allMatches.hasNext()) return@forEach
        formatMatch(allMatches.next())
    }
    if (!allMatches.hasNext()) return
    formatMatch(allMatches.next())
}