package com.perpheads.bans

import kotlinx.datetime.Instant
import kotlin.math.absoluteValue
import kotlin.math.roundToLong
import kotlin.time.Duration

object CommonRegexes {
    val steamIdRegex = Regex("STEAM_[0-5]:[01]:\\d+")
    val ipAddressRegex = Regex("^\$|^(?:[0-9]{1,3}\\.){3}[0-9]{1,3}\$")
    val demoRegex = Regex("""^perpheads_demo_\d+-\d+-\d+ \d+-\d+-\d+(?:\.dem)?$""")

    fun verifyIP(str: String): Boolean {
        if (!str.matches(ipAddressRegex)) return false
        val components = str.split("\\.").mapNotNull { it.toIntOrNull() }
        if (components.size != 4) return false
        return components.all { it in 0..255 }
    }
}

fun String.pluralize(count: Long): String {
    return if (count != 1L && count != -1L) "$count ${this}s"
    else "$count $this"
}