package com.perpheads.bans.requests

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ServerLogSearchRequest(
    val query: String,
    val steamId: String = "",
    val start: Instant? = null,
    val end: Instant? = null,
    val page: Int = 1,
    val categories: List<Int> = emptyList(),
    val orderByRelevance: Boolean = false,
    val orderAscending: Boolean = false,
    val startX: Int? = null,
    val startY: Int? = null,
    val endX: Int? = null,
    val endY: Int? = null,
)