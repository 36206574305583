package com.perpheads.bans

import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.BlacklistResponse

enum class BlacklistTypes(val type: String, val chatBlacklist: Boolean = false) {
    Police("Police"),
    Mayor("Mayor"),
    Medic("Medic"),
    Firefighter("Firefighter"),
    SecretService("Secret Service"),
    Roadcrew("Roadcrew"),
    TaxiDriver("Taxi Driver"),
    Courier("Courier"),
    OOC("OOC", true),
    LOOC("LOOC", true),
    Help("Help", true),
    Advert("Advert", true),
    Images("Images", true),
    NameChange("Name Change")
}

fun AccountInfoResponse.canEditBlacklist(blacklist: BlacklistResponse): Boolean {
    if (canBlacklist) return true
    if (!canChatBlacklist) return false
    if (blacklist.admin?.id != player.id) return false

    return BlacklistTypes.values().any { it.type.lowercase() == blacklist.type.lowercase() && it.chatBlacklist }
}