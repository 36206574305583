package com.perpheads.bans.responses

import kotlinx.serialization.Serializable

@Serializable
enum class DemoRequestStatus {
    OPEN,
    REJECTED,
    NOT_FOUND,
    UPLOADING,
    UPLOADED,
    FAILED
}