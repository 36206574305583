package com.perpheads.bans.responses

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class AltAccountResponse(
    val communityId: String,
    val name: String?,
    val allowed: Boolean,
    val date: Instant
)