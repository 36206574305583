package com.perpheads.bans.util

import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.wrappers.SlideDown
import com.perpheads.bans.wrappers.moment
import kotlinx.browser.document
import kotlinx.css.WhiteSpace
import kotlinx.css.whiteSpace
import kotlinx.datetime.Instant
import kotlinx.html.A
import org.w3c.dom.HTMLScriptElement
import react.PropsWithChildren
import react.RBuilder
import react.RHandler
import react.dom.*
import react.dom.events.MouseEventHandler
import react.router.NavigateFunction
import styled.css
import styled.styledTd
import web.cssom.PropertyName.Companion.whiteSpace


fun Instant.format(): String {
    return moment(this.toString()).format("lll")
}

fun Instant.format(formatStr: String): String {
    return moment(this.toString()).format(formatStr)
}

fun NavigateFunction.pushMouseEventHandler(url: String): MouseEventHandler<*> = {
    if (!it.ctrlKey) {
        //Otherwise, this should open the link in a new tab
        it.preventDefault()
        this(url)
    }
}


fun RBuilder.playerLink(text: String, communityId: String, navigate: NavigateFunction) {
    a {
        val href = ClientRoutes.playerProfile(communityId)
        attrs.href = href
        attrs.onClick = navigate.pushMouseEventHandler(href)
        +text
    }
}

fun RDOMBuilder<*>.collapsingTableRow(header: String, content: (RDOMBuilder<*>).() -> Unit) {
    tr {
        td("collapsing") { +header }
        styledTd {
            css {
                whiteSpace = WhiteSpace.preLine
            }
            content()
        }
    }
}


fun RDOMBuilder<*>.linkedCollapsingTableRow(
    navigate: NavigateFunction,
    header: String,
    link: String,
    preventDefault: Boolean = true,
    content: (RDOMBuilder<A>).() -> Unit
) {
    tr {
        td("collapsing") { +header }
        td {
            a(link) {
                if (preventDefault) {
                    attrs.onClick = navigate.pushMouseEventHandler(link)
                }
                content()
            }
        }
    }
}

fun searchForBans(search: String, navigate: NavigateFunction) {
    val params = mutableListOf<Pair<String, String?>>().apply {
        add("search" to search)
    }
    navigate("/?${params}")
}

fun RBuilder.slideDown(
    closed: Boolean = false,
    className: String? = null,
    transitionOnAppear: Boolean = true,
    `as`: String? = "div",
    handler: RHandler<PropsWithChildren>
) {
    SlideDown {
        attrs {
            if (className != null) {
                this.className = className
            }
            this.closed = closed
            this.transitionOnAppear = transitionOnAppear
            this.`as` = `as`
        }

        handler.invoke(this)
    }
}

fun <T> List<T>.updateElement(elem: T, newValue: T): List<T> {
    return this.updateElement(newValue) { it == elem }
}

inline fun <T> List<T>.updateElement(newValue: T, match: (T) -> Boolean): List<T> {
    return this.map {
        if (match(it)) newValue else it
    }
}

fun loadJsFile(url: String, id: String, callback: () -> Unit) {
    val existing = document.getElementById(id)
    if (existing != null) {
        callback()
        return
    }
    val element = document.createElement("script") as HTMLScriptElement
    element.id = id
    element.src = url
    element.onload = {
        callback()
    }
    document.body?.appendChild(element)
}