package com.perpheads.bans.responses

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

interface ActionLogResponse {
    var logId: Int
    val admin: PlayerResponse
    val logDate: Instant
    val message: String
}

@Serializable
data class BanLogResponse(
    override var logId: Int,
    val entityId: Int,
    val communityId: Long,
    val playerName: String,
    override val admin: PlayerResponse,
    val startDate: Instant,
    override val logDate: Instant,
    override val message: String,
) : ActionLogResponse

@Serializable
data class WarningLogResponse(
    override var logId: Int,
    val entityId: Int,
    val communityId: Long,
    val playerName: String,
    override val admin: PlayerResponse,
    val startDate: Instant,
    override val logDate: Instant,
    override val message: String
) : ActionLogResponse


@Serializable
data class BlacklistLogResponse(
    override var logId: Int,
    val entityId: Int,
    val communityId: Long,
    val playerName: String,
    override val admin: PlayerResponse,
    val startDate: Instant,
    override val logDate: Instant,
    override val message: String,
    val type: String,
) : ActionLogResponse

@Serializable
data class PlayerLogResponse(
    override var logId: Int,
    val player: PlayerResponse,
    override val admin: PlayerResponse,
    override val logDate: Instant,
    override val message: String
) : ActionLogResponse

@Serializable
enum class CommentType {
    BAN, WARNING, BLACKLIST
}

@Serializable
data class CommentLogResponse(
    override var logId: Int,
    val entityId: Int,
    override val admin: PlayerResponse,
    override val logDate: Instant,
    override val message: String,
    val commentType: CommentType
): ActionLogResponse


@Serializable
data class ActionLogsSearchResponse<T : ActionLogResponse>(
    val count: Int,
    val logs: List<T>
)