package com.perpheads.bans.components.comments

import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.ServerRoutes
import com.perpheads.bans.util.format
import com.perpheads.bans.linkify
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.responses.CommentResponse
import com.perpheads.bans.util.useTranslation
import kotlinx.css.height
import kotlinx.css.px
import kotlinx.css.width
import react.Props
import react.RBuilder
import react.dom.*
import react.dom.events.MouseEventHandler
import react.fc
import styled.inlineStyles
import styled.styledImg

external interface CommentProps : Props {
    var account: AccountInfoResponse?
    var comment: CommentResponse
    var onEditPressed : MouseEventHandler<*>
    var onDeletePressed: MouseEventHandler<*>
}

val CommentComponent = fc<CommentProps>("Comment") { props ->
    val t = useTranslation()
    val comment = props.comment
    div("comment") {
        a(classes = "avatar") {
            styledImg(src = ServerRoutes.avatarRoute(comment.admin.communityId)) {
                inlineStyles {
                    width = 32.px
                    height = 32.px
                }
            }
        }
        div("content") {
            a(ClientRoutes.banAdmin(comment.admin.steamId()), classes = "author") {
                +comment.admin.name
            }
            div("metadata") {
                span("date") { +comment.date.format() }
            }
            div("text") { linkify(comment.commentText) }
            if (comment.admin.id == props.account?.player?.id || props.account?.canEditComments == true) {
                div("actions") {
                    a(classes = "reply") {
                        attrs.onClick = props.onEditPressed
                        +t("comments.edit")
                    }
                    a(classes = "reply") {
                        attrs.onClick = props.onDeletePressed
                        +t("comments.delete")
                    }
                }
            }
        }
    }
}

fun RBuilder.comment(handler: CommentProps.() -> Unit) = child(CommentComponent) {
    attrs { handler() }
}