package com.perpheads.bans.components

import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import kotlinx.css.TextAlign
import kotlinx.css.textAlign
import react.Props
import react.RBuilder
import react.dom.p
import react.fc
import styled.inlineStyles
import styled.styledP


external interface ConfirmationModalComponentProps : Props {
    var icon: String
    var title: String
    var description: String
    var open: Boolean
    var onDecision: (Boolean) -> Unit
}

val ConfirmationModalComponent = fc<ConfirmationModalComponentProps>("ConfirmationModal") { props ->
    val t = useTranslation()

    semanticModal {
        attrs {
            basic = true
            size = "small"
            open = props.open
            centered = true
            onClose = { props.onDecision(false) }
        }
        semanticHeader {
            attrs.icon = true
            semanticIcon(props.icon)
            p {
                +props.title
            }
        }
        semanticModalContent {
            styledP {
                inlineStyles {
                    textAlign = TextAlign.center
                }
                +props.description
            }
        }
        semanticModalActions {
            attrs.style = kotlinext.js.js {
                textAlign = "center"
            }
            semanticButton {
                attrs {
                    inverted = true
                    color = "red"
                    basic = true
                    negative = false
                    onClick = { props.onDecision(false) }
                }
                +t("button.no")
            }
            semanticButton {
                attrs {
                    inverted = true
                    color = "green"
                    positive = true
                    basic = true
                    onClick = { props.onDecision(true) }
                }
                +t("button.yes")
            }
        }
    }
}


fun RBuilder.confirmationModal(handler: ConfirmationModalComponentProps.() -> Unit) =
    child(ConfirmationModalComponent) {
        attrs(handler)
    }