package com.perpheads.bans.components.bans

import com.perpheads.bans.ClientRoutes
import com.perpheads.bans.CommonRegexes
import com.perpheads.bans.ApiClient
import com.perpheads.bans.components.durationDropdown
import com.perpheads.bans.components.errorModal
import com.perpheads.bans.components.loadingSpinner
import com.perpheads.bans.components.page.requiredUserPage
import com.perpheads.bans.parseQueryString
import com.perpheads.bans.requests.BanRequest
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.label
import react.router.useNavigate
import react.router.useLocation

external interface EditBanPageComponentProps : Props {
    var banId: Int?
}

val EditBanPageComponent = fc<EditBanPageComponentProps>("EditBanPage") { props ->
    val location = useLocation()
    val parameters = parseQueryString(location.search.drop(1))
    val t = useTranslation()
    val navigate = useNavigate()
    val (loading, setLoading) = useState(props.banId != null)
    val errorState = useState(false)
    var steamIdError by useState(false)
    var steamIdText by useState(parameters["steamid"] ?: "")
    var nicknameText by useState("")
    var nicknameError by useState(false)
    var ipAddressText by useState("")
    var ipAddressError by useState(false)
    var reasonText by useState("")
    var reasonError by useState(false)
    var durationSeconds by useState<Long>()
    var durationSelected by useState(false)
    var durationError by useState(false)

    useEffect(steamIdText) {
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) return@useEffect
        ApiClient.launchWithErrorHandler(navigate, errorState) {
            nicknameText = ApiClient.getNameBySteamId(steamIdText) ?: return@launchWithErrorHandler
        }
    }

    useEffectOnce {
        val banId = props.banId ?: return@useEffectOnce
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            val loadedBan = ApiClient.getBan(banId)
            steamIdText = loadedBan.steamId()
            nicknameText = loadedBan.playerName
            reasonText = loadedBan.reason
            durationSeconds = loadedBan.duration?.inWholeSeconds
            durationSelected = true
        }
    }

    fun verifyInput(): Boolean {
        var success = true
        if (reasonText.length > 1500) {
            reasonError = true
            success = false
        } else {
            reasonError = false
        }
        if (!steamIdText.matches(CommonRegexes.steamIdRegex)) {
            steamIdError = true
            success = false
        } else {
            steamIdError = false
        }
        if (!durationSelected) {
            durationError = true
            success = false
        } else {
            durationError = false
        }
        if (nicknameText.length !in 1..50) {
            nicknameError = true
            success = false
        } else {
            nicknameError = false
        }
        if (ipAddressText.isNotBlank() && !ipAddressText.matches(CommonRegexes.ipAddressRegex)) {
            ipAddressError = true
            success = false
        } else {
            ipAddressError = false
        }

        return success
    }

    fun submit() {
        if (!verifyInput()) return
        val request = BanRequest(
            steamIdText,
            nicknameText,
            ipAddressText,
            reasonText,
            durationSeconds
        )
        ApiClient.launchWithErrorHandler(navigate, errorState, setLoading) {
            val banId = props.banId
            val newBanId = if (banId != null) {
                ApiClient.editBan(banId, request)
                banId
            } else {
                ApiClient.addBan(request).banId
            }
            navigate(ClientRoutes.banSearch(newBanId))
        }
    }
    errorModal(errorState = errorState)

    requiredUserPage {
        loadingSpinner { isLoading = loading }
        semanticForm {
            semanticFormField {
                attrs.width = 5
                attrs.error = steamIdError
                semanticInput {
                    label = t("headers.steamid")
                    placeholder = t("headers.steamid")
                    value = steamIdText
                    onChange = {
                        steamIdText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.error = nicknameError
                semanticInput {
                    label = t("headers.nickname")
                    value = nicknameText
                    placeholder = t("headers.nickname")
                    onChange = {
                        nicknameText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.width = 5
                attrs.error = ipAddressError
                semanticInput {
                    label = t("headers.ipaddress")
                    placeholder = t("headers.ipaddress")
                    value = ipAddressText
                    onChange = {
                        ipAddressText = (it.target as HTMLInputElement).value.trim()
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.error = reasonError
                label { +t("headers.reason") }
                semanticTextArea {
                    this.rows = 6
                    this.value = reasonText
                    this.onChange = { event ->
                        reasonText = (event.target as HTMLTextAreaElement).value
                    }
                }
            }
            semanticDivider()
            semanticFormField {
                attrs.error = durationError
                durationDropdown(durationSeconds, durationSelected) {
                    durationSeconds = it
                    durationSelected = true
                }
            }
            semanticDivider()
            semanticButton {
                attrs {
                    this.onClick = {
                        submit()
                    }
                }
                +t("button.submit")
            }
        }
    }
}

fun RBuilder.editBanPage(banId: Int?) = child(EditBanPageComponent) {
    attrs {
        this.banId = banId
    }
}