package com.perpheads.bans.components.page

import com.perpheads.bans.SemanticTheme
import com.perpheads.bans.changeTheme
import com.perpheads.bans.util.useTranslation
import com.perpheads.bans.wrappers.semantic.*
import react.Props
import react.RBuilder
import react.fc

external interface ThemeChooserProps : Props {
    var open: Boolean
    var closeModal: () -> Unit
    var onThemeSelected: () -> Unit
}

val ThemeChooser = fc<ThemeChooserProps>("ThemeChooser") { props ->
    val t = useTranslation()

    semanticModal {
        attrs.onClose = { props.closeModal() }
        attrs.open = props.open
        attrs.centered = true
        semanticHeader {
            attrs.content = t("headers.choose_theme")
        }
        semanticModalContent {
            semanticMenu(false) {
                attrs.className = "item"
                attrs.fluid = true
                attrs.vertical = true
                for (theme in SemanticTheme.values()) {
                    semanticMenuItem {
                        attrs.link = true
                        attrs.onClick = {
                            props.closeModal()
                            changeTheme(theme)
                        }
                        +theme.name
                    }
                }
            }
        }
    }
}

fun RBuilder.themeChooser(handler: ThemeChooserProps.() -> Unit) = child(ThemeChooser) {
    attrs { handler() }
}