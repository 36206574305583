package com.perpheads.bans.wrappers

import kotlinx.html.classes
import react.*
import react.dom.div
import react.dom.events.TransitionEvent
import react.dom.onTransitionEnd
import web.dom.getComputedStyle
import web.html.HTMLDivElement
import kotlin.math.absoluteValue

external interface SlideDownProps : PropsWithChildren {
    var `as`: String?
    var className: String
    var closed: Boolean
    var transitionOnAppear: Boolean
}
val SlideDown = fc<SlideDownProps>("SlideDown") { props ->
    val outerRef = useRef<HTMLDivElement>()
    var childrenLeaving by useState(false)
    var children by useState(props.children)

    fun startTransition(prevHeight: String) {
        val current = outerRef.current ?: return
        var endHeight = "0px"

        if (!props.closed && !childrenLeaving && children != null) {
            current.classList.remove("closed")
            current.style.height = "auto"
            endHeight = getComputedStyle(current).height
        }

        @Suppress("DEPRECATION_ERROR")
        if ((parseFloat(endHeight) - parseFloat(prevHeight)).absoluteValue > 0.01) {
            current.classList.add("transitioning")
            current.style.height = prevHeight
            current.offsetHeight
            current.style.transitionProperty = "height"
            current.style.height = endHeight
        }
    }

    fun endTransition() {
        val current = outerRef.current ?: return

        current.classList.remove("transitioning")
        current.style.transitionProperty = "none"
        current.style.height = if (props.closed) "0px" else "auto"

        if (props.closed || children == null) {
            current.classList.add("closed")
        }
    }

    fun handleTransitionEnd(ev: TransitionEvent<*>) {
        if (ev.target != outerRef.current || ev.propertyName != "height") return
        if (childrenLeaving) {
            childrenLeaving = false
            children = null
            endTransition()
        } else {
            endTransition()
        }
    }

    useEffectOnce {
        outerRef.current?.let { element ->
            if (props.closed || props.children == null) {
                element.classList.add("closed")
                element.style.height = "0px"
            } else if(props.transitionOnAppear) {
                startTransition("0px")
            } else {
                element.style.height = "auto"
            }
        }
    }

    useEffect {
        if (props.children != null) {
            children = props.children
            childrenLeaving = false
        } else if (children != null) {
            childrenLeaving = true
        }
    }

    useEffect {
        outerRef.current?.let { startTransition(it.getBoundingClientRect().height.toString() + "px") }
    }

    div {
        ref = outerRef
        attrs.classes = setOf("react-slidedown")
        attrs.onTransitionEnd = ::handleTransitionEnd
        props.children()
    }
}