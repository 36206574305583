package com.perpheads.bans

import com.perpheads.bans.components.accountPage
import com.perpheads.bans.components.bans.banPage
import com.perpheads.bans.components.bans.editBanPage
import com.perpheads.bans.components.blacklists.blacklistPage
import com.perpheads.bans.components.blacklists.editBlacklistPage
import com.perpheads.bans.components.demos.demoPage
import com.perpheads.bans.components.logs.*
import com.perpheads.bans.components.page.steamCallback
import com.perpheads.bans.components.profile.addAltAccountPage
import com.perpheads.bans.components.profile.profilePage
import com.perpheads.bans.components.ranks.editRankPage
import com.perpheads.bans.components.ranks.rankPage
import com.perpheads.bans.components.reports.reportLogsPage
import com.perpheads.bans.components.reports.reportPage
import com.perpheads.bans.components.screenshots.screenshotPage
import com.perpheads.bans.components.statistics.statisticsPage
import com.perpheads.bans.components.warnings.editWarningPage
import com.perpheads.bans.components.warnings.warningPage
import com.perpheads.bans.responses.AccountInfoResponse
import com.perpheads.bans.util.TranslationSuspense
import com.perpheads.bans.wrappers.Cookies
import com.perpheads.bans.wrappers.moment
import js.objects.jso
import kotlinx.serialization.encodeToString
import react.*
import react.dom.client.createRoot
import react.dom.div
import react.router.*
import react.router.dom.createBrowserRouter
import web.dom.document
import web.events.EventHandler
import web.html.HTMLInputElement
import web.location.location
import web.navigator.navigator
import web.storage.localStorage
import web.window.window

fun NavigateFunction.replace(route: String) {
    this(route, jso { replace = true })
}

fun logout(navigate: NavigateFunction) {
    removeStoredAccount()
    navigate.replace(ClientRoutes.bans)
}

fun removeStoredAccount() {
    localStorage.removeItem("account")
}

fun storeAccount(account: AccountInfoResponse) {
    localStorage.setItem("account", ApiClient.jsonSerializer.encodeToString(account))
}

fun getHiddenInput(id: String): String? {
    return (document.getElementById(id) as? HTMLInputElement)?.value
}

fun getLogo(): String {
    val logo = getHiddenInput("logo") ?: "logo.png"
    return "/assets/${logo}"
}

fun getLogoDark(): String {
    val logo = getHiddenInput("logoDark") ?: "logodark.png"
    return "/assets/${logo}"
}

fun getLanguage(): String {
    return getHiddenInput("language") ?: "en"
}

fun getLanguageJsonPath(): String {
    return getHiddenInput("languagePath") ?: "/assets/locales/${getLanguage()}/translation.json"
}

fun getFallbackLanguage(): String? = null

fun getCurrentTheme(): SemanticTheme {
    return SemanticTheme.getByName(Cookies.get("theme") ?: "")
}

fun changeTheme(newTheme: SemanticTheme) {
    Cookies.set("theme", newTheme.name, jso { expires = 365 })
    location.reload()
}

private fun routeComponent(param: String?, render: RBuilder.(String) -> Unit) = fc<Props> {
    val params = useParams()
    if (param == null) {
        render("")
    } else {
        render(params[param] ?: "")
    }
}

private fun MutableList<RouteObject>.routeWithParam(
    path: String,
    param: String? = null,
    render: RBuilder.(String) -> Unit
) {
    add(jso {
        this.path = path
        this.element = createElement(routeComponent(param, render))
    })
}

fun MutableList<RouteObject>.createRoutes() {
    //Bans
    routeWithParam(ClientRoutes.addBan) {
        editBanPage(null)
    }
    routeWithParam(ClientRoutes.editBan, param = "banId") { banId ->
        editBanPage(banId.toIntOrNull())
    }
    routeWithParam(ClientRoutes.bans) {
        banPage()
    }
    //Warnings
    routeWithParam(ClientRoutes.addWarning) {
        editWarningPage(null)
    }
    routeWithParam(ClientRoutes.editWarning, param = "warningId") { warningId ->
        editWarningPage(warningId.toIntOrNull())
    }
    routeWithParam(ClientRoutes.warnings) {
        warningPage()
    }
    //Blacklists
    routeWithParam(ClientRoutes.addBlacklist) {
        editBlacklistPage(null)
    }
    routeWithParam(ClientRoutes.editBlacklist, param = "blacklistId") { blacklistId ->
        editBlacklistPage(blacklistId.toIntOrNull())
    }
    routeWithParam(ClientRoutes.blacklists) {
        blacklistPage()
    }
    //Screenshots
    routeWithParam(ClientRoutes.screenshots) {
        screenshotPage()
    }
    //Demos
    routeWithParam(ClientRoutes.demos) {
        demoPage()
    }
    //Ranks
    routeWithParam(ClientRoutes.editRank) {
        editRankPage { }
    }
    routeWithParam(ClientRoutes.ranks) {
        rankPage()
    }
    //Logs
    routeWithParam(ClientRoutes.logs) {
        logPage()
    }
    routeWithParam(ClientRoutes.banLogs) {
        banLogPage()
    }
    routeWithParam(ClientRoutes.warningLogs) {
        warningLogPage()
    }
    routeWithParam(ClientRoutes.blacklistLogs) {
        blacklistLogPage()
    }
    routeWithParam(ClientRoutes.playerLogs) {
        playerLogPage()
    }
    routeWithParam(ClientRoutes.commentLogs) {
        commentLogPage()
    }

    //Player profile
    routeWithParam(ClientRoutes.playerProfile, param = "communityId") { str ->
        val communityId = str.toLongOrNull() ?: str.steamIdToProfileId()
        if (communityId == null) {
            Navigate {
                attrs.to = ClientRoutes.bans
            }
            return@routeWithParam
        }
        profilePage {
            this.communityId = communityId
        }
    }
    //Alt accounts
    routeWithParam(ClientRoutes.addAltAccount, param = "communityId") { communityId ->
        addAltAccountPage(communityId)
    }
    //Reports
    routeWithParam(ClientRoutes.reports) {
        reportPage { }
    }
    routeWithParam(ClientRoutes.reportLogs) {
        reportLogsPage { }
    }
    //Statistics
    routeWithParam(ClientRoutes.statistics) {
        statisticsPage { }
    }

    //Account
    routeWithParam(ClientRoutes.account) {
        accountPage()
    }
    routeWithParam(ClientRoutes.steamCallback) {
        steamCallback()
    }
    routeWithParam("/") {
        Navigate {
            attrs.to = ClientRoutes.bans
        }
    }
}

private val router = createBrowserRouter(mutableListOf<RouteObject>().apply { createRoutes() }.toTypedArray())

val Page = fc<Props>("Page") {
    TranslationSuspense {
        RouterProvider {
            attrs.router = router
        }
    }
}

fun main() {
    window.onload = EventHandler {
        moment.locale(navigator.language)
        document.getElementById("root")?.let { rootElem ->
            createRoot(rootElem).render(createElement(Page))
        }
    }
}