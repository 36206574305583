package com.perpheads.bans.responses

import com.perpheads.bans.profileIdToSteamId
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

@Serializable
data class BlacklistResponse(
    val blacklistId: Int,
    val playerName: String,
    val communityId: String,
    val startDate: Instant,
    val endDate: Instant?,
    val reason: String,
    val type: String,
    val unblacklistReason: String?,
    val unblacklistDate: Instant?,
    val deleted: Boolean,
    val admin: PlayerResponse? = null,
    val unblacklistAdmin: PlayerResponse? = null
) {
    fun steamId(): String = communityId.profileIdToSteamId() ?: "STEAM_ID_ERROR"

    @Transient
    val duration =
        if (endDate == null) null else (endDate.epochSeconds - startDate.epochSeconds).seconds


    fun isPermanent(): Boolean {
        return duration == null
    }

    fun isUnbanned(): Boolean {
        return unblacklistAdmin != null
    }

    fun isExpired(): Boolean {
        return if (endDate == null) false
        else endDate < Clock.System.now()
    }
}

@Serializable
data class BlacklistSearchResponse(
    val count: Int,
    val blacklists: List<BlacklistResponse>
)